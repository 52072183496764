import { useLayoutEffect, useRef } from "react";
import useFetch from "../../hooks/useFetch";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import MaskBottom from "../mask-bottom/mask-bottom.component";
import Statistic from "../statistic/statistic.component";

import { ReactComponent as CrossVector } from "../../img/background-decor/repeating-vector.svg";

import "./statistic-list.styles.scss";

const StatisticList = (props) => {
  const ref = useRef();
  gsap.registerPlugin(ScrollTrigger);
  const url = `${props.apiUrl}?populate[StatisticList][populate][0]=statistics`;
  const { loading, error, data } = useFetch(url);

  useLayoutEffect(() => {
    let ctx = null;
    if (
      data !== null &&
      props.useNumberEffect &&
      data.data.attributes.StatisticList.statistics.data.length > 0
    ) {
      ctx = gsap.context(() => {
        // Counter effect for counters and middle character
        gsap.registerEffect({
          name: "counter",
          extendTimeline: true,
          defaults: {
            end: 0,
            mid: null,
            duration: 0.75,
            ease: "power1",
            increment: 1,
          },
          effect: (targets, config) => {
            if (targets.length > 0) {
              const tl = gsap.timeline();
              targets[0].innerText = config.mid ? config.mid : "";

              tl.to(
                targets,
                {
                  duration: config.duration,
                  innerText: config.mid ? config.mid : config.end,
                  modifiers: config.mid
                    ? {}
                    : {
                        innerText: function (innerText) {
                          return gsap.utils
                            .snap(config.increment, innerText)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        },
                      },
                  ease: config.ease,
                },
                0
              );

              return tl;
            }
          },
        });

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: ref.current,
            start: "top 60%",
            markers: true,
          },
        });
        const statisticList =
          data.data.attributes.StatisticList.statistics.data;
        let i = 0,
          length = statisticList.length;

        // For each statistic
        for (i; i < length; i++) {
          const element = statisticList[i];
          let value = element.attributes.Value;
          let midCharacters = [];
          // Split statistic value into number and non number values.
          const splitValue = value.match(/(\d+|[^\d]+)/g);
          const splitLength = splitValue.length - 1;

          // If has more than one entry, must have other values beside numbers
          // IMPORTANT expected format is:
          // [Optional: prefix][Number][Optional: separating character][Optional: number][Optional: suffix]
          if (splitLength > 1) {
            const hasPrefix = isNaN(splitValue[0]);
            const hasSuffix = isNaN(splitValue[splitLength]);

            let j = hasPrefix ? 1 : 0;
            let end = hasSuffix ? splitLength - 1 : splitLength;

            // If has values between prefix / suffix, continue.
            if (end - j > 1) {
              value = [];
              for (j; j <= end; j++) {
                // Separate numbers from middle characters
                const currentValue = splitValue[j];
                if (isNaN(currentValue)) {
                  midCharacters.push(currentValue);
                } else {
                  value.push(currentValue);
                }
              }
            }
          }

          tl.from(`#stat${i}`, { autoAlpha: 0, overwrite: "auto" }, ">");
          // If multiple values found, split by middle value
          if (Array.isArray(value)) {
            // Slow it down if low values
            const duration = value[0] < 5 || value[1] < 5 ? 1 : 0.75;
            tl.counter(
              `#count${i}`,
              { end: value[0], duration: duration },
              "-=0.5"
            );
            tl.counter(
              `#statistic-mid${i}`,
              { mid: midCharacters[0] },
              "-=0.5"
            );
            tl.counter(
              `#count-secondary${i}`,
              { end: value[1], duration: duration },
              "-=0.5"
            );
          } else {
            tl.counter(`#count${i}`, { end: value }, "-=0.5");
          }
        }
      }, ref);
    }
    return () => (ctx !== null ? ctx.revert() : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) return <p>Loading ... </p>;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data.data.attributes) {
    const statisticList = data.data.attributes.StatisticList.statistics.data;

    return (
      <section ref={ref} className="statistic-list">
        <CrossVector className="statistic-list__cross-vector" />
        <div className="statistic-list__container">
          {statisticList.map((statistic, index) => {
            // Manually split statistic from CMS
            const value = statistic.attributes.Value;
            const splitValue = props.useNumberEffect
              ? value.match(/(\d+|[^\d]+)/g)
              : undefined;
            const length = props.useNumberEffect ? splitValue.length - 1 : 0;
            const prefix = props.useNumberEffect
              ? isNaN(splitValue[0])
                ? splitValue[0]
                : undefined
              : undefined;
            const suffix = props.useNumberEffect
              ? isNaN(splitValue[length])
                ? splitValue[length]
                : undefined
              : undefined;

            return (
              <Statistic
                key={statistic.id}
                index={index}
                useNumberEffect={props.useNumberEffect}
                prefix={prefix}
                value={value}
                suffix={suffix}
                subtitle={statistic.attributes.Subtitle}
                desc={statistic.attributes.Description}
              />
            );
          })}
        </div>
        <MaskBottom />
      </section>
    );
  } else {
    return <div ref={ref}>Data not returned</div>;
  }
};

export default StatisticList;
