import "./statistic.styles.scss";

const Statistic = (props) => {
  return (
    <section id={`stat${props.index}`} className="statistic">
      <div className="statistic__container">
        {props.useNumberEffect ? (
          <div className="statistic__value-container">
            {/* This is to correctly set the size before animation reveals */}
            <p className="statistic__dummy">{props.value}</p>
            <div className="statistic__value">
              <span className="statistic__prefix">{props.prefix}</span>
              <p id={`count${props.index}`} className="statistic__number">
                {/* This is populated by gsap affect in statistic list */}
              </p>
              <span
                id={`statistic-mid${props.index}`}
                className="statistic__mid"
              ></span>
              <p
                id={`count-secondary${props.index}`}
                className="statistic__number"
              >
                {/* This is populated by gsap affect in statistic list, if secondary part of value exists */}
              </p>
              <span className="statistic__suffix">{props.suffix}</span>
            </div>
          </div>
        ) : (
          <p className="statistic__number">{props.value}</p>
        )}
        <p className="statistic__subtitle">{props.subtitle}</p>
        <p className="statistic__desc">{props.desc}</p>
      </div>
    </section>
  );
};

export default Statistic;
