import useFetch from "../../hooks/useFetch";
import { useLayoutEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { isAbsoluteUrl } from "../../utils/isAbsoluteUrl";

import "./media-hero.styles.scss";

const MediaHero = (props) => {
  const ref = useRef();
  gsap.registerPlugin(ScrollTrigger);
  const [selectedCategory, setSelectedCategory] = useState("0");
  const [selectedIndustry, setSelectedIndustry] = useState("0");

  const categoryUrl = `${process.env.REACT_APP_API_BASE}/api/blog-categories`;
  const industryUrl = `${process.env.REACT_APP_API_BASE}/api/blog-industries`;

  const {
    loading: catLoading,
    error: catError,
    data: catData,
  } = useFetch(categoryUrl);
  const {
    loading: indLoading,
    error: indError,
    data: indData,
  } = useFetch(industryUrl);

  useLayoutEffect(() => {
    let ctx = null;
    if (catData !== null && indData !== null) {
      ctx = gsap.context(() => {
        gsap.fromTo(
          ".media-hero__title, .media-hero__filter-container",
          { y: 100, autoAlpha: 0 },
          {
            delay: 1.25,
            duration: 1,
            y: 0,
            autoAlpha: 1,
            ease: "none",
            overwrite: "auto",
            scrollTrigger: {
              trigger: ".media-hero__title, .media-hero__filter-container",
              start: "top 80%",
              end: "bottom 20%",
              onLeave: function () {
                gsap.fromTo(
                  ".media-hero__title, .media-hero__filter-container",
                  { autoAlpha: 1 },
                  { autoAlpha: 0, overwrite: "auto" }
                );
              },
              onEnterBack: function () {
                gsap.fromTo(
                  ".media-hero__title, .media-hero__filter-container",
                  { autoAlpha: 0 },
                  {
                    duration: 1.25,
                    autoAlpha: 1,
                    ease: "back",
                    overwrite: "auto",
                  }
                );
              },
              onLeaveBack: function () {
                gsap.fromTo(
                  ".media-hero__title, .media-hero__filter-container",
                  { autoAlpha: 1 },
                  { autoAlpha: 0, overwrite: "auto" }
                );
              },
            },
          }
        );
      }, ref);
    }
    return () => (ctx !== null ? ctx.revert() : null);
  }, [catData, indData]);

  if (catLoading || indLoading) return <p>Loading ... </p>;
  if (catError || indError) {
    if (catError) console.log(catError);
    if (indError) console.log(indError);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  const activeClass = "media-hero__button--active";

  const categoryClick = (event) => {
    props.handleCategoryUpdate(event.target.value);
    setSelectedCategory(event.target.dataset.id);
  };

  const industryClick = (event) => {
    props.handleIndustryUpdate(event.target.value);
    setSelectedIndustry(event.target.dataset.id);
  };

  if (catData && indData) {
    const categories = catData.data;
    const industries = indData.data;

    const imgSrc = props.coverImg.url;
    const imgUrl = isAbsoluteUrl(imgSrc)
      ? imgSrc
      : `${process.env.REACT_APP_API_BASE}${imgSrc}`;

    return (
      <section ref={ref} className="media-hero">
        <div
          className="media-hero__image-container"
          style={{
            backgroundImage: `url(${imgUrl})`,
          }}
        ></div>
        <div className="media-hero__container">
          <h1 className="media-hero__title">{props.title}</h1>
          <div className="media-hero__filter-container">
            <div className="media-hero__filter-group">
              <p className="media-hero__filter-title">{props.categoryTitle}</p>
              <div className="media-hero__filter-list">
                <button
                  className={`media-hero__button ${
                    selectedCategory === `${0}` ? activeClass : ""
                  }`}
                  value="All"
                  data-id={0}
                  onClick={categoryClick}
                >
                  {props.categoryText}
                </button>
                {categories.map((category) => (
                  <button
                    key={category.id}
                    className={`media-hero__button ${
                      selectedCategory === `${category.id}` ? activeClass : ""
                    }`}
                    value={category.attributes.Name}
                    data-id={category.id}
                    onClick={categoryClick}
                  >
                    {category.attributes.Name}
                  </button>
                ))}
              </div>
            </div>
            <div className="media-hero__filter-group">
              <p className="media-hero__filter-title">{props.industryTitle}</p>
              <div className="media-hero__filter-list">
                <button
                  className={`media-hero__button ${
                    selectedIndustry === `${0}` ? activeClass : ""
                  }`}
                  value="All"
                  data-id={0}
                  onClick={industryClick}
                >
                  {props.industryText}
                </button>
                {industries.map((industry) => (
                  <button
                    key={industry.id}
                    className={`media-hero__button ${
                      selectedIndustry === `${industry.id}` ? activeClass : ""
                    }`}
                    value={industry.attributes.Name}
                    data-id={industry.id}
                    onClick={industryClick}
                  >
                    {industry.attributes.Name}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return <div ref={ref}>Data not returned</div>;
  }
};

export default MediaHero;
