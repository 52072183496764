import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import "./policy-hero.styles.scss";

const PolicyHero = (props) => {
  const ref = useRef();
  gsap.registerPlugin(ScrollTrigger);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        ".policy-hero__container",
        { y: 100, autoAlpha: 0 },
        {
          delay: 1.5,
          duration: 1.25,
          y: 0,
          autoAlpha: 1,
          ease: "back",
          overwrite: "auto",
          scrollTrigger: {
            trigger: ".policy-hero__container",
            start: "top 80%",
            end: "bottom 20%",
            onLeave: function () {
              gsap.fromTo(
                ".policy-hero__container",
                { autoAlpha: 1 },
                { autoAlpha: 0, overwrite: "auto" }
              );
            },
            onEnterBack: function () {
              gsap.fromTo(
                ".policy-hero__container",
                { autoAlpha: 0 },
                {
                  duration: 1.25,
                  autoAlpha: 1,
                  ease: "back",
                  overwrite: "auto",
                }
              );
            },
            onLeaveBack: function () {
              gsap.fromTo(
                ".policy-hero__container",
                { autoAlpha: 1 },
                { autoAlpha: 0, overwrite: "auto" }
              );
            },
          },
        }
      );
    }, ref);
    return () => ctx.revert();
  }, []);

  return (
    <section ref={ref} className="policy-hero">
      <div className="policy-hero__container">
        {props.prefix && <p className="policy-hero__prefix">{props.prefix}</p>}
        <h1 className="policy-hero__title">{props.title}</h1>
      </div>
    </section>
  );
};

export default PolicyHero;
