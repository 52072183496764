import useFetch from "../../hooks/useFetch";
import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import MediaQuery from "react-responsive";
import { Breakpoints } from "../../utils/breakpoints";
import AxisParallax from "../axis-parallax/axis-parallax.component";
import InfoPanel from "../info-panel/info-panel.component";
import TopographyBackground from "../topography-background/topography-background.component";
import TitleAccentLine from "../title-accent-line/title-accent-line.component";

import { ReactComponent as TopographySquare } from "../../img/background-decor/key-features/topographic-square.svg";
import { ReactComponent as TopographyBottomTablet } from "../../img/background-decor/key-features/topographic-bottom-tablet.svg";
import { ReactComponent as TopographyBottomDesktop } from "../../img/background-decor/key-features/topographic-bottom-desktop.svg";

import "./key-features.styles.scss";

const KeyFeatures = (props) => {
  const ref = useRef();
  gsap.registerPlugin(ScrollTrigger);
  let componentContent = <div></div>;

  const url = `${props.apiUrl}?populate[KeyFeatures][populate][0]=features.Image`;
  const { loading, error, data } = useFetch(url);

  useLayoutEffect(() => {
    let ctx = null;
    if (data !== null) {
      ctx = gsap.context(() => {
        const tl = gsap.timeline({
          defaults: {
            duration: 0.5,
            stagger: 0.2,
            ease: "sine.inOut",
            overwrite: "auto",
          },
          scrollTrigger: {
            trigger: ".key-features__list",
            start: "top 60%",
            toggleActions: "play none none reset",
          },
        });
        tl.to(".info-panel__cover-img", {
          height: "196px",
        });
        tl.addLabel("secondary", 0.5);
        tl.to(
          ".info-panel",
          {
            backgroundColor: "#ffffff",
          },
          "secondary"
        );
        tl.to(
          ".info-panel__container",
          {
            autoAlpha: 1,
          },
          "secondary"
        );
        tl.to(
          ".info-panel__title",
          {
            autoAlpha: 1,
          },
          "secondary"
        );
      }, ref);
    }
    return () => (ctx !== null ? ctx.revert() : null);
  }, [data]);

  if (loading) componentContent = <p>Loading ... </p>;
  if (error) {
    console.log(error);
    componentContent = (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data !== null && data.data.attributes) {
    const keyFeatures = data.data.attributes.KeyFeatures.features.data;

    componentContent = (
      <>
        <div
          className={`key-features__background ${
            props.largeTopSpacing ? "key-features__background--large-top" : ""
          }`}
        >
          <TopographyBackground useHeight={true} />
          <AxisParallax
            onlyDesktop={true}
            topDesktop="0px"
            rightDesktop="25px"
            isNotHero={true}
          />
          <div className="key-features__container">
            {props.title && (
              <TitleAccentLine title={props.title} theme="light" />
            )}
            <div className="key-features__list">
              {keyFeatures.map((feature, index) => (
                <InfoPanel
                  key={feature.id}
                  useAnimation={true}
                  index={index}
                  title={feature.attributes.Name}
                  desc={feature.attributes.Description}
                  coverImg={feature.attributes.Image}
                  smallTitles={props.smallTitles}
                />
              ))}
            </div>
          </div>
        </div>
        <div
          className={`key-features__bottom-bg ${
            props.smallBottomSpacing ? "key-features__bottom-bg--small" : ""
          }`}
        >
          <MediaQuery maxWidth={Breakpoints("tablet") - 1}>
            <TopographySquare className="key-features__topography-secondary" />
          </MediaQuery>
          <MediaQuery
            minWidth={Breakpoints("tablet")}
            maxWidth={Breakpoints("desktop") - 1}
          >
            <TopographyBottomTablet className="key-features__topography-secondary" />
          </MediaQuery>
          <MediaQuery minWidth={Breakpoints("desktop")}>
            <TopographyBottomDesktop className="key-features__topography-secondary" />
          </MediaQuery>
        </div>
      </>
    );
  } else {
    componentContent = <div>Data not returned</div>;
  }

  return (
    <section
      ref={ref}
      className={`key-features ${
        props.smallBottomSpacing ? "key-features--small-bottom" : ""
      }`}
    >
      {componentContent}
    </section>
  );
};

export default KeyFeatures;
