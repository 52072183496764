import { format } from "date-fns";
import RteContent from "../rte-content/rte-content.component";
import SocialList from "../social-list/social-list.component";
import JobApply from "../job-apply/job-apply.component";

import "./blog-content.styles.scss";

const BlogContent = (props) => {
  const date = new Date(props.date);
  const formattedDate = format(date, "d MMM yyyy 'at' HH:mm");

  return (
    <section className="blog-content">
      <div className="blog-content__container">
        <div
          className={`blog-content__header ${
            props.isAlternate ? "blog-content__header--alternate" : ""
          }`}
        >
          <div className="blog-content__social">
            <p className="blog-content__social-title">{props.socialTitle}</p>
            <SocialList
              apiUrl={props.apiUrl}
              cmsSection="Social"
              cmsField="socials"
              hideTitle={true}
              useDark={true}
              fields="fields[0]=Slug"
              noSpacing={true}
            />
          </div>
          <p className="blog-content__date">{formattedDate}</p>
        </div>
        <div className="blog-content__content">
          {props.title && (
            <h1 className="blog-content__title">{props.title}</h1>
          )}
          <RteContent content={props.content} noSpacing={true} />
          {props.jobName && (
            <JobApply jobName={props.jobName} application={props.application} />
          )}
        </div>
        <div className="blog-content__footer">
          <div className="blog-content__social blog-content__social--footer">
            <p className="blog-content__social-title">{props.socialTitle}</p>
            <SocialList
              apiUrl={props.apiUrl}
              cmsSection="Social"
              cmsField="socials"
              hideTitle={true}
              useDark={true}
              fields="fields[0]=Slug"
              noSpacing={true}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogContent;
