import ReactMarkdown from "react-markdown";

import "./rte-content.styles.scss";

const RteContent = (props) => {
  return (
    <section className="rte-content">
      <ReactMarkdown
        className={`rte-content__container ${
          props.noSpacing ? "rte-content__container--no-spacing" : ""
        } ${props.className}`}
      >
        {props.content}
      </ReactMarkdown>
    </section>
  );
};

export default RteContent;
