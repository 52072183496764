import { Link } from "react-router-dom";
import useFetch from "../../hooks/useFetch";

import { ReactComponent as XpointLogo } from "../../img/logo/xpoint-logo-horizontal.svg";

import "./footer-legal.styles.scss";

const FooterLegal = (props) => {
  const url = `${props.apiUrl}?populate[Legal][populate][0]=Logo`;
  const { loading, error, data } = useFetch(url);

  if (loading) return <p>Loading ... </p>;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data.data.attributes) {
    const legal = data.data.attributes.Legal;

    return (
      <section className="footer-legal">
        <Link className="footer-legal__link" to="/">
          <XpointLogo className="footer-legal__logo" />
        </Link>
        <p className="footer-legal__disclaimer">{legal.Disclaimer}</p>
        <p className="footer-legal__rights">{legal.RightsReserved}</p>
      </section>
    );
  } else {
    return <div>Data not returned</div>;
  }
};

export default FooterLegal;
