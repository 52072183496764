import { useHeadsObserver } from "../../hooks/useHeadsObserver";
import "./table-of-contents.styles.scss";
import { useEffect, useState } from "react";
import MediaQuery from "react-responsive";
import { Breakpoints } from "../../utils/breakpoints";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

import { ReactComponent as Chevron } from "../../img/icon/icon-chevron.svg";

const getClassName = (level) => {
  switch (level) {
    case 2:
      return "head2";
    case 3:
      return "head3";
    case 4:
      return "head4";
    default:
      return null;
  }
};

const slugify = (str) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");

const TableOfContents = (props) => {
  const [headings, setHeadings] = useState([]);
  const [showToc, setToc] = useState(false);
  const { activeId } = useHeadsObserver();

  useEffect(() => {
    const rteContent = document.querySelector(".rte-content");
    const titles = rteContent
      ? rteContent.querySelectorAll("h2, h3, h4")
      : null;
    if (titles) {
      for (let i = 0; i < titles.length; i++) {
        titles[i].setAttribute("id", slugify(titles[i].innerHTML));
      }
      const elements = Array.from(titles).map((elem) => ({
        id: elem.id,
        text: elem.innerText,
        level: Number(elem.nodeName.charAt(1)),
      }));
      setHeadings(elements);
      setToc(elements.length > 1);
    }
  }, []);

  const tocNav = (
    <nav className="table-of-contents__nav" aria-label="Table of contents">
      <ul className="table-of-contents__list">
        {headings.map((heading) => (
          <li
            key={heading.id}
            className={`table-of-contents__item ${getClassName(heading.level)}`}
          >
            <a
              href={`#${heading.id}`}
              onClick={(e) => {
                e.preventDefault();
                document.querySelector(`#${heading.id}`).scrollIntoView({
                  behavior: "smooth",
                });
              }}
              className={`table-of-contents__link ${
                activeId === heading.id ? "table-of-contents__link--active" : ""
              }`}
            >
              {heading.text}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );

  return (
    <>
      {showToc ? (
        <section className="table-of-contents">
          <div className="table-of-contents__container">
            <MediaQuery maxWidth={Breakpoints("desktop") - 1}>
              <Accordion allowZeroExpanded>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="table-of-contents__heading">
                        <div className="table-of-contents__title">Contents</div>
                        <AccordionItemState>
                          {({ expanded }) =>
                            expanded ? (
                              <Chevron className="table-of-contents__chevron table-of-contents__chevron--active" />
                            ) : (
                              <Chevron className="table-of-contents__chevron" />
                            )
                          }
                        </AccordionItemState>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>{tocNav}</AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </MediaQuery>
            <MediaQuery minWidth={Breakpoints("desktop")}>
              <div className="table-of-contents__heading">
                <div className="table-of-contents__title">Contents</div>
              </div>
              {tocNav}
            </MediaQuery>
          </div>
        </section>
      ) : (
        <section className="table-of-contents table-of-contents--no-content"></section>
      )}
    </>
  );
};

export default TableOfContents;
