import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { gql, useQuery, useMutation } from "@apollo/client";

import RteContent from "../rte-content/rte-content.component";

import "./contact-form.styles.scss";

const GETDROPDOWNDATA = gql`
  query GetContactFormDropdown {
    contactOptions(sort: "onDisplayOrder") {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

const SUBMITFORM = gql`
  mutation ($email: String, $text: String, $subject: String, $toggle: Boolean) {
    createContactFormSubmission(
      data: {
        email: $email
        text: $text
        subject: $subject
        subscribed: $toggle
      }
    ) {
      data {
        id
        attributes {
          email
          text
          subject
          subscribed
        }
      }
    }
  }
`;

const ContactForm = (props) => {
  // const token = "recaptcha token";
  const [wasSubmit, setWasSubmit] = useState(false);
  const [error, setError] = useState(false);
  const {
    loading,
    error: getDropDownError,
    data: formDropdownOptions,
  } = useQuery(GETDROPDOWNDATA);
  const [submitFormToServer] = useMutation(SUBMITFORM);

  if (loading) return <></>;
  if (getDropDownError) return <p>Couldn't load form data properly</p>;

  return (
    <section className="contact-form">
      <div className="contact-form__container">
        <h2 className="contact-form__title">
          {!wasSubmit ? props.title : props.sentTitle}
        </h2>
        <Formik
          initialValues={{ email: "", subject: "", message: "", toggle: false }}
          validate={(values) => {
            const errors = {};

            if (!values.email) {
              errors.email = "An email address is required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }

            if (!values.subject) {
              errors.subject = "A subject is required";
            }

            if (!values.message) {
              errors.message = "A message is required";
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            submitFormToServer({
              variables: {
                email: values.email,
                text: values.message,
                subject: values.subject,
                toggle: values.toggle,
              },
            })
              .catch(() => {
                console.error("Error:", error);
                setError(error);
              })
              .finally(() => {
                setSubmitting(false);
                setWasSubmit(true);
              });
          }}
        >
          {({ isSubmitting }) =>
            !wasSubmit ? (
              <Form className="contact-form__form">
                <Field type="email" name="email" placeholder="Email" />
                <ErrorMessage name="email" component="div" />
                <Field as="select" name="subject" required>
                  <option value="" disabled>
                    Subject
                  </option>
                  {formDropdownOptions.contactOptions.data.map(
                    ({ id, attributes }) => (
                      <option key={id} value={attributes.name}>
                        {attributes.name}
                      </option>
                    )
                  )}
                </Field>
                <ErrorMessage name="subject" component="div" />
                <Field
                  type="message"
                  name="message"
                  placeholder="Message"
                  as="textarea"
                />
                <ErrorMessage name="message" component="div" />
                <label className="contact-form__checkbox">
                  <Field type="checkbox" name="toggle" />
                  {props.checkboxText
                    ? props.checkboxText
                    : "Please email with the latest industry insights and Xpoint news"}
                </label>
                <RteContent
                  className="contact-form__disclaimer"
                  noSpacing={true}
                  content={
                    props.disclaimerText
                      ? props.disclaimerText
                      : "By completing this form you are permitting Xpoint to process your data in accordance with our Privacy Policy"
                  }
                />

                <button
                  className="contact-form__button"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {props.buttonText}
                </button>
              </Form>
            ) : (
              <div className="contact-form__message">
                {error ? (
                  <p className="contact-form__error">{props.errorMessage}</p>
                ) : (
                  <p className="contact-form__success">{props.sentMessage}</p>
                )}
              </div>
            )
          }
        </Formik>
      </div>
    </section>
  );
};

export default ContactForm;
