import { isAbsoluteUrl } from "../../utils/isAbsoluteUrl";
import { ReactComponent as LinkedIn } from "../../img/team/linkedin.svg";

import "./team-profile.styles.scss";

const TeamProfile = (props) => {
  const img =
    props.profile.data !== null ? props.profile.data.attributes : null;
  const imgUrl = img
    ? isAbsoluteUrl(img.url)
      ? img.url
      : `${process.env.REACT_APP_API_BASE}${img.url}`
    : null;
  const linkedIn = props.link !== null ? props.link : null;

  return (
    <section className="team-profile">
      <div className="team-profile__img-container">
        {img !== null && (
          <img
            className="team-profile__img"
            alt={img.alternativeText}
            src={imgUrl}
          />
        )}
      </div>
      <div className="team-profile__container">
        <h3 className="team-profile__name">{props.name}</h3>
        <p className="team-profile__text">{props.text}</p>
        {linkedIn !== null && (
          <a
            className="team-profile__link"
            href={props.link}
            target="_blank"
            rel="noreferrer noopener"
          >
            <LinkedIn className="team-profile__icon" />
          </a>
        )}
      </div>
    </section>
  );
};

export default TeamProfile;
