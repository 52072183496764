// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.featured-title-diamond__vector {
  position: absolute;
  opacity: 0.2;
  z-index: -1;
}
@media screen and (min-width: 1024px) {
  .featured-title-diamond__vector--left, .featured-title-diamond__vector--right {
    height: 200%;
    top: -100px;
  }
  .featured-title-diamond__vector--left {
    left: -240px;
  }
  .featured-title-diamond__vector--right {
    right: -240px;
    transform: scaleX(-1);
  }
}
@media screen and (min-width: 1024px) {
  .featured-title-diamond--using-parallax .featured-title-diamond__vector {
    top: 100px;
  }
}
@media screen and (min-width: 1440px) {
  .featured-title-diamond--using-parallax .featured-title-diamond__vector {
    top: 150px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/featured-title-diamond/featured-title-diamond.styles.scss","webpack://./src/styles/tools/media-queries/_above.scss"],"names":[],"mappings":"AAKE;EACE,kBAAA;EACA,YAAA;EACA,WAAA;AAJJ;ACCE;EDMI;IAEE,YAAA;IACA,WAAA;EALN;EAQI;IACE,YAAA;EANN;EASI;IACE,aAAA;IACA,qBAAA;EAPN;AACF;ACZE;EDsBE;IAEI,UAAA;EARN;AACF;ACjBE;EDsBE;IAKI,UAAA;EANN;AACF","sourcesContent":["@import \"../../styles/tools/media-queries/above\";\n\n.featured-title-diamond {\n  $block: &;\n\n  &__vector {\n    position: absolute;\n    opacity: 0.2;\n    z-index: -1;\n\n    @include above(laptop) {\n      &--left,\n      &--right {\n        height: 200%;\n        top: -100px;\n      }\n\n      &--left {\n        left: -240px;\n      }\n\n      &--right {\n        right: -240px;\n        transform: scaleX(-1);\n      }\n    }\n\n    #{$block}--using-parallax & {\n      @include above(laptop) {\n        top: 100px;\n      }\n      @include above(desktop) {\n        top: 150px;\n      }\n    }\n  }\n}\n","@import \"../functions/breakpoint-value\";\n\n@mixin above($key) {\n  $min-width: breakpoint-value($key);\n\n  @media screen and (min-width: #{$min-width}) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
