import { ReactComponent as Mask } from "../../img/background-decor/contact-us/right-polygon.svg";

import "./mask-right.styles.scss";

const MaskRight = (props) => {
  return (
    <section
      className={`mask-right ${
        props.theme ? `mask-right--${props.theme}` : ""
      }`}
    >
      <Mask className="mask-right__mask" />
    </section>
  );
};

export default MaskRight;
