import { ReactComponent as IOS } from "../../img/logo/logo-ios.svg";
import { ReactComponent as Android } from "../../img/logo/logo-android.svg";
import { ReactComponent as Mac } from "../../img/logo/logo-mac.svg";
import { ReactComponent as Windows } from "../../img/logo/logo-windows.svg";

import "./platforms.styles.scss";

const Platforms = (props) => {
  return (
    <section className="platforms">
      <div className="platforms__container">
        <h2 className="platforms__title">{props.title}</h2>
        <div className="platforms__list">
          <IOS className="platforms__icon" />
          <Android className="platforms__icon" />
          <Mac className="platforms__icon" />
          <Windows className="platforms__icon" />
        </div>
      </div>
    </section>
  );
};

export default Platforms;
