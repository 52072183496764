import useFetch from "../../hooks/useFetch";
import TitleLink from "../title-link/title-link.component";

import "./title-links.styles.scss";

const TitleLinks = (props) => {
  const url = `${props.apiUrl}?populate[TitleLinks][populate][0]=CoverImage`;
  const { loading, error, data } = useFetch(url);

  if (loading) return <p>Loading ... </p>;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }
  if (data.data.attributes) {
    const titles = data.data.attributes.TitleLinks;
    const numOfChildren = titles.length;

    return (
      <section
        className={`title-links ${
          numOfChildren > 1 ? "title-links--multiple" : ""
        }`}
      >
        {titles.map((section) => (
          <TitleLink
            key={section.id}
            title={section.Title}
            subtitle={section.Subtitle}
            coverImg={section.CoverImage}
            link={section.LinkDestination}
          />
        ))}
      </section>
    );
  } else {
    return <div>Data not returned</div>;
  }
};

export default TitleLinks;
