import { isAbsoluteUrl } from "../../utils/isAbsoluteUrl";
import MediaQuery from "react-responsive";
import { Breakpoints } from "../../utils/breakpoints";

import { ReactComponent as Watermark } from "../../img/background-decor/info-panel/right-arrow.svg";
import { ReactComponent as WatermarkLaptop } from "../../img/background-decor/info-panel/right-arrow-laptop.svg";

import "./info-panel.styles.scss";

const InfoPanel = (props) => {
  const img = props.coverImg.data.attributes;
  const imgUrl = isAbsoluteUrl(img.url)
    ? img.url
    : `${process.env.REACT_APP_API_BASE}${img.url}`;

  const title = (
    <h3
      className={`info-panel__title ${
        props.smallTitles ? "info-panel__title--small" : ""
      }`}
    >
      {props.title}
    </h3>
  );

  return (
    <section
      className={`info-panel ${
        props.theme === "alternate"
          ? `info-panel--alternate ${
              props.index !== undefined
                ? (props.index + 1) % 2 === 0
                  ? "info-panel--even"
                  : "info-panel--odd"
                : ""
            }`
          : ""
      } ${props.useAnimation ? "info-panel--use-animation" : ""}`}
    >
      <div className="info-panel__cover">
        <div
          className="info-panel__cover-img"
          style={{
            backgroundImage: `url(${imgUrl})`,
          }}
        />
        {props.theme !== "alternate" && <>{title}</>}
      </div>
      <div className="info-panel__container">
        {props.theme === "alternate" && (
          <>
            <MediaQuery maxWidth={Breakpoints("laptop") - 1}>
              <Watermark className="info-panel__watermark" />
            </MediaQuery>
            <MediaQuery minWidth={Breakpoints("laptop")}>
              <WatermarkLaptop className="info-panel__watermark" />
            </MediaQuery>
          </>
        )}
        {props.theme === "alternate" && <>{title}</>}
        <p
          className={`info-panel__desc ${
            props.smallTitles ? "info-panel__desc--small" : ""
          }`}
        >
          {props.desc}
        </p>
      </div>
    </section>
  );
};

export default InfoPanel;
