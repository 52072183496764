import { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';

const useFetch = (url) => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (url !== null) {
      const fetchData = async () => {
        setLoading(true)

        try {
          const res = await fetch(url)
          const json = await res.json()

          setData(json)
          setLoading(false)
        } catch (error) {
          setError(error)
          setLoading(false)
        }
      }

      trackPromise(fetchData());
    }
  }, [url])

  return { loading, error, data }
}

export default useFetch