import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import MediaQuery from "react-responsive";
import { Breakpoints } from "../../utils/breakpoints";
import HeroMask from "../hero-mask/hero-mask.component";
import MaskRight from "../mask-right/mask-right.component";
import SocialList from "../social-list/social-list.component";
import RteContent from "../rte-content/rte-content.component";

import { ReactComponent as Email } from "../../img/icon/icon-email.svg";
import { ReactComponent as Phone } from "../../img/icon/icon-phone.svg";
import { ReactComponent as CrossVector } from "../../img/background-decor/cross-vector-diamond.svg";

import "./contact-hero.styles.scss";

const ContactHero = (props) => {
  const ref = useRef();
  gsap.registerPlugin(ScrollTrigger);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        ".contact-hero__headline",
        { y: 100, autoAlpha: 0 },
        {
          delay: 1.5,
          duration: 1.25,
          y: 0,
          autoAlpha: 1,
          ease: "back",
          overwrite: "auto",
          scrollTrigger: {
            trigger: ".contact-hero__headline",
            start: "top 80%",
            end: "bottom 20%",
            onLeave: function () {
              gsap.fromTo(
                ".contact-hero__headline",
                { autoAlpha: 1 },
                { autoAlpha: 0, overwrite: "auto" }
              );
            },
            onEnterBack: function () {
              gsap.fromTo(
                ".contact-hero__headline",
                { autoAlpha: 0 },
                {
                  duration: 1.25,
                  autoAlpha: 1,
                  ease: "back",
                  overwrite: "auto",
                }
              );
            },
            onLeaveBack: function () {
              gsap.fromTo(
                ".contact-hero__headline",
                { autoAlpha: 1 },
                { autoAlpha: 0, overwrite: "auto" }
              );
            },
          },
        }
      );
    }, ref);
    return () => ctx.revert();
  }, []);

  return (
    <section ref={ref} className="contact-hero">
      <CrossVector className="contact-hero__cross-vector" />
      <div className="contact-hero__container">
        <div className="contact-hero__headline">
          <h1 className="contact-hero__title">{props.title}</h1>
          <RteContent content={props.desc} noSpacing={true} />

          {props.phone && (
            <div className="contact-hero__row contact-hero__row--spacing-top">
              <Phone className="contact-hero__icon contact-hero__icon--phone" />
              <a className="contact-hero__info" href={`tel:${props.phone}`}>
                {props.phone}
              </a>
            </div>
          )}
          {props.email && (
            <div className="contact-hero__row">
              <Email className="contact-hero__icon contact-hero__icon--email" />
              <a className="contact-hero__info" href={`mailto:${props.email}`}>
                {props.email}
              </a>
            </div>
          )}
          <div className="contact-hero__social">
            <SocialList
              apiUrl={props.apiUrl}
              cmsSection="Hero"
              cmsField="Social.socials"
              hideTitle={true}
              useDark={true}
            />
          </div>
        </div>
        <MediaQuery maxWidth={Breakpoints("laptop") - 1}>
          <HeroMask theme="black" />
        </MediaQuery>
        <MediaQuery minWidth={Breakpoints("laptop")}>
          <MaskRight />
        </MediaQuery>
      </div>
    </section>
  );
};

export default ContactHero;
