import useFetch from "../../hooks/useFetch";
import InitialLoad from "../../components/initial-load/initial-load.component";
import LoadingIndicator from "../../components/loading-indicator/loading-indicator.component";
import CultureFeatures from "../../components/culture-features/culture-features.component";
import CategoryList from "../../components/category-list/category-list.component";
import CultureTitle from "../../components/culture-title/culture-title.component";
import Hero from "../../components/hero/hero.component";
import SiteHeader from "../../components/site-header/site-header.component";
import ValueList from "../../components/value-list/value-list.component";

import "./culture.styles.scss";

const Culture = () => {
  const url = `${process.env.REACT_APP_API_BASE}/api/culture-page`;
  const { loading, error, data } = useFetch(`${url}?populate=*`);

  if (loading) return <LoadingIndicator loading={true} />;

  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data.data.attributes) {
    const hero = data.data.attributes.Hero;
    const featuredTitle = data.data.attributes.FeaturedTitle;
    const values = data.data.attributes.culture_values.data;

    return (
      <section className="culture">
        <InitialLoad />
        <SiteHeader />
        <Hero title={hero.PageTitle} useVideo={hero.UseVideo} apiUrl={url} />
        <div className="culture__background">
          <CultureTitle title={featuredTitle.Title} />
          <CategoryList apiUrl={url} />
        </div>
        <ValueList list={values} />
        <CultureFeatures apiUrl={url} />
      </section>
    );
  } else {
    return <div>Data not returned</div>;
  }
};

export default Culture;
