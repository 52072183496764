import useFetch from "../../hooks/useFetch";
import InitialLoad from "../../components/initial-load/initial-load.component";
import LoadingIndicator from "../../components/loading-indicator/loading-indicator.component";
import PolicyHero from "../../components/policy-hero/policy-hero.component";
import RteContent from "../../components/rte-content/rte-content.component";
import SiteHeader from "../../components/site-header/site-header.component";

import "./privacy-policy.styles.scss";

const PrivacyPolicy = () => {
  const url = `${process.env.REACT_APP_API_BASE}/api/privacy-policy`;
  const { loading, error, data } = useFetch(`${url}?populate=*`);

  if (loading) return <LoadingIndicator loading={true} />;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data.data.attributes) {
    const privacyPolicy = data.data.attributes;

    return (
      <section className="privacy-policy">
        <InitialLoad />
        <SiteHeader theme="dark" />
        <PolicyHero
          title={privacyPolicy.Title}
          prefix={privacyPolicy.LastUpdated}
        />
        <RteContent content={privacyPolicy.PolicyContent} />
      </section>
    );
  } else {
    return <div>Data not returned</div>;
  }
};

export default PrivacyPolicy;
