import React, { Component } from "react";
import { trackPromise } from "react-promise-tracker";
import { gsap } from "gsap";
import MediaQuery from "react-responsive";
import { Breakpoints } from "../../utils/breakpoints";
import InitialLoad from "../../components/initial-load/initial-load.component";
import LoadingIndicator from "../../components/loading-indicator/loading-indicator.component";
import BlogThumbnail from "../../components/blog-thumbnail/blog-thumbnail.component";
import MediaHero from "../../components/media-hero/media-hero.component";

import { ReactComponent as Watermark } from "../../img/background-decor/media/watermark-laptop.svg";
import { ReactComponent as WatermarkDesktop } from "../../img/background-decor/media/watermark-desktop.svg";
import LoadingGifDark from "../../img/icon/icon-loading-dark.gif";

import SiteHeader from "../../components/site-header/site-header.component";

import "./media.styles.scss";
class Media extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.gsapCtx = null;
    this.isInitialCall = true;
    this.isLaptopOrHigher = false;
    this.state = {
      selectedCategory: "All",
      selectedIndustry: "All",
      page: 1,
      blogList: [],
      blogPagination: {
        page: 1,
        pageCount: 0,
        pageSize: 9,
        total: 0,
      },
      loadingPage: true,
      loadingBlog: true,
      loadingMore: false,
      wasLoadingMore: false,
      errorPage: false,
      errorBlog: false,
      pageData: false,
      pageUrl: `${process.env.REACT_APP_API_BASE}/api/media-page?populate=CoverImage`,
      blogUrlStart: `${process.env.REACT_APP_API_BASE}/api/blog-posts?pagination[page]=`,
      blogUrlMiddle:
        "&sort=BlogDate:desc&pagination[pageSize]=9&fields[0]=Title&fields[1]=BlogDate&fields[2]=Slug&populate[blog_categories][populate][3]=Color&populate[blog_industry][populate][4]=Color&populate[Hero][populate][5]=CoverImage",
      categoryFilter: "&filters[blog_categories][Name][$eqi]=",
      industryFilter: "&filters[blog_industry][Name][$eqi]=",
    };
  }

  componentDidMount() {
    this.getPageData();
    this.getBlogData();
    this.isLaptopOrHigher = window.innerWidth >= Breakpoints("laptop");
  }

  componentDidUpdate(prevProps, prevState) {
    // Only run if blog list has changed and has values
    if (
      this.state.blogList !== prevState.blogList &&
      this.state.blogList.length > 0
    ) {
      // If loading more, different animation behaviour
      if (this.state.wasLoadingMore) {
        this.gsapCtx = gsap.context(() => {
          const elements = this.ref.current.querySelectorAll(".blog-thumbnail");
          if (elements && elements.length > 9) {
            // Only apply to new elements
            const newElements = [...elements];
            newElements.splice(0, 9);

            gsap.fromTo(
              newElements,
              {
                scale: 0,
                autoAlpha: 0,
              },
              {
                delay: 0,
                duration: 0.7,
                scale: 1,
                autoAlpha: 1,
                stagger: 0.2,
                ease: "sine.inOut",
                overwrite: "auto",
              }
            );

            if (this.isLaptopOrHigher) {
              const thumbnails = gsap.utils.toArray(newElements);
              thumbnails.forEach((thumbnail) => {
                const animation = gsap.to(thumbnail, {
                  paused: true,
                  top: -5,
                  duration: 0.25,
                });

                thumbnail.addEventListener("mouseenter", () =>
                  animation.play()
                );
                thumbnail.addEventListener("mouseleave", () =>
                  animation.reverse()
                );
              });
            }
          }
        }, this.ref);

        this.setState({ wasLoadingMore: false });
      } else {
        // Cleanup previous context
        if (this.gsapCtx) {
          this.gsapCtx.revert();
        }
        // Regular loading animation
        this.gsapCtx = gsap.context(() => {
          const elements = this.ref.current.querySelectorAll(".blog-thumbnail");
          if (elements) {
            gsap.fromTo(
              elements,
              {
                scale: 0,
                autoAlpha: 0,
              },
              {
                delay: () => (this.isInitialCall ? 2 : 0),
                duration: 0.7,
                scale: 1,
                autoAlpha: 1,
                stagger: 0.2,
                ease: "sine.inOut",
                overwrite: "auto",
              }
            );

            if (this.isLaptopOrHigher) {
              const thumbnails = gsap.utils.toArray(elements);
              thumbnails.forEach((thumbnail) => {
                const animation = gsap.to(thumbnail, {
                  paused: true,
                  top: -5,
                  duration: 0.25,
                });

                thumbnail.addEventListener("mouseenter", () =>
                  animation.play()
                );
                thumbnail.addEventListener("mouseleave", () =>
                  animation.reverse()
                );
              });
            }

            // Ensure only first load has long delay for load animation
            if (this.isInitialCall) {
              this.isInitialCall = false;
            }
          }
        }, this.ref);
      }
    }
  }

  componentWillUnmount() {
    if (this.gsapCtx !== null) {
      this.gsapCtx.revert();
    }
  }

  getPageData = () => {
    this.setState({
      loadingPage: true,
    });
    trackPromise(
      fetch(this.state.pageUrl, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({
            pageData: response.data.attributes,
            loadingPage: false,
          });
        })
        .catch((err) => {
          this.setState({ loadingPage: false, errorPage: err });
          console.log(err);
        })
    );
  };

  getBlogData = ({
    page = 1,
    category = null,
    industry = null,
    delay = 0,
  } = {}) => {
    if (page !== 1) {
      this.setState({
        loadingMore: true,
        wasLoadingMore: true,
      });
    } else {
      this.setState({
        loadingBlog: true,
      });
    }

    const blogUrl = `${this.state.blogUrlStart}${page}${
      this.state.blogUrlMiddle
    }${
      category && category !== "All"
        ? `${this.state.categoryFilter}${category}`
        : ""
    }${
      industry && industry !== "All"
        ? `${this.state.industryFilter}${industry}`
        : ""
    }`;

    trackPromise(
      fetch(blogUrl, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((response) => {
          setTimeout(() => {
            this.setState({
              blogList:
                page !== 1
                  ? [...this.state.blogList, ...response.data]
                  : response.data,
              loadingBlog: false,
              loadingMore: false,
              blogPagination: response.meta.pagination,
            });
          }, delay);
        })
        .catch((err) => {
          this.setState({
            loadingBlog: false,
            errorBlog: err,
            loadingMore: false,
          });
          console.log(err);
        })
    );
  };

  handleCategoryUpdate = (category) => {
    // Cleanup previous context
    if (this.gsapCtx !== null) {
      this.gsapCtx.revert();
    }
    this.getBlogData({
      category: category,
      industry: this.state.selectedIndustry,
      delay: 1000,
    });
    this.setState({
      selectedCategory: category,
      page: 1,
    });
  };

  handleIndustryUpdate = (industry) => {
    // Cleanup previous context
    if (this.gsapCtx !== null) {
      this.gsapCtx.revert();
    }
    this.getBlogData({
      category: this.state.selectedCategory,
      industry: industry,
      delay: 1000,
    });
    this.setState({
      selectedIndustry: industry,
      page: 1,
    });
  };

  handleLoadClick = () => {
    const nextPage = this.state.page + 1;
    this.getBlogData({ page: nextPage, delay: 1000 });
    this.setState({
      page: nextPage,
    });
  };

  render() {
    if (this.state.loadingPage) return <LoadingIndicator loading={true} />;
    if (this.state.errorPage || this.state.errorBlog) {
      if (this.state.errorPage) console.log(this.state.errorPage);
      if (this.state.errorBlog) console.log(this.state.errorBlog);
      return (
        <section className="error">
          <p>Error ⛔.</p>
        </section>
      );
    }

    if (this.state.pageData) {
      return (
        <section ref={this.ref} className="media">
          <InitialLoad />
          <SiteHeader />
          <MediaHero
            title={this.state.pageData.Title}
            categoryTitle={this.state.pageData.CategoryHeading}
            industryTitle={this.state.pageData.IndustryHeading}
            categoryText={this.state.pageData.CategorySeeAllText}
            industryText={this.state.pageData.IndustrySeeAllText}
            coverImg={this.state.pageData.CoverImage.data.attributes}
            handleCategoryUpdate={this.handleCategoryUpdate}
            handleIndustryUpdate={this.handleIndustryUpdate}
          />
          <div className="media__container">
            <MediaQuery
              minWidth={Breakpoints("laptop")}
              maxWidth={Breakpoints("desktop") - 1}
            >
              <Watermark className="media__watermark" />
            </MediaQuery>
            <MediaQuery minWidth={Breakpoints("desktop")}>
              <WatermarkDesktop className="media__watermark" />
            </MediaQuery>
            <div className="media__blog-list">
              {this.state.loadingBlog ? (
                <div className="media__load-container">
                  <img
                    src={LoadingGifDark}
                    className="media__loader"
                    alt="loading animation..."
                  />
                </div>
              ) : this.state.blogList.length > 0 ? (
                this.state.blogList.map((blog) => {
                  const categories = blog.attributes.blog_categories.data
                    ? blog.attributes.blog_categories.data
                    : null;
                  const industry =
                    blog.attributes.blog_industry.data &&
                    blog.attributes.blog_industry.data.attributes
                      ? blog.attributes.blog_industry.data.attributes
                      : null;
                  const thumbnail =
                    blog.attributes.Hero.CoverImage.data &&
                    blog.attributes.Hero.CoverImage.data.attributes
                      ? blog.attributes.Hero.CoverImage.data.attributes
                      : null;
                  return (
                    <BlogThumbnail
                      key={blog.id}
                      title={blog.attributes.Title}
                      date={blog.attributes.BlogDate}
                      categories={categories}
                      industry={industry}
                      thumbnail={thumbnail}
                      slug={blog.attributes.Slug}
                    />
                  );
                })
              ) : (
                <div className="media__error">
                  <p className="media__error-message">
                    {this.state.pageData.NoPostsMessage}
                  </p>
                </div>
              )}
            </div>
            {this.state.page < this.state.blogPagination.pageCount &&
              !this.state.loadingBlog && (
                <button
                  className="media__button button button--dark"
                  onClick={this.handleLoadClick}
                >
                  {this.state.pageData.LoadMoreText}
                </button>
              )}
            {this.state.loadingMore && (
              <div className="media__load-container media__load-container--more">
                <img
                  src={LoadingGifDark}
                  className="media__loader"
                  alt="loading animation..."
                />
              </div>
            )}
          </div>
        </section>
      );
    } else {
      return <div ref={this.ref}>Data not returned</div>;
    }
  }
}

export default Media;
