import useFetch from "../../../hooks/useFetch";
import InitialLoad from "../../../components/initial-load/initial-load.component";
import LoadingIndicator from "../../../components/loading-indicator/loading-indicator.component";
import HighlightedInfo from "../../../components/highlighted-info/highlighted-info.component";
import Insights from "../../../components/insights/insights.component";
import KeyFeatures from "../../../components/key-features/key-features.component";
import IndustryHero from "../../../components/industry-hero/industry-hero.component";
import SiteHeader from "../../../components/site-header/site-header.component";
import StatisticList from "../../../components/statistic-list/statistic-list.component";
import TrustedPartners from "../../../components/trusted-partners/trusted-partners.component";

import "./gaming.styles.scss";

const Gaming = () => {
  const url = `${process.env.REACT_APP_API_BASE}/api/gaming-market`;
  const { loading, error, data } = useFetch(`${url}?populate=*`);

  if (loading) return <LoadingIndicator loading={true} />;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data.data.attributes) {
    const preTitle = data.data.attributes.PreTitle;
    const hero = data.data.attributes.Hero;
    const highlightedInfo = data.data.attributes.HighlightedInfo;
    const keyFeatures = data.data.attributes.KeyFeatures;
    const trustedPartners = data.data.attributes.TrustedPartners;
    const statisticList = data.data.attributes.StatisticList;

    return (
      <section className="gaming">
        <InitialLoad />
        <SiteHeader />
        <IndustryHero
          industryTitle={preTitle}
          title={hero.Title}
          subTitle={hero.SubTitle}
          buttonText={hero.ButtonText}
          buttonLink={hero.ButtonLink}
          useVideo={hero.UseVideo}
          isNextSectionDark={true}
          doesNextSectionHaveOverlay={true}
          apiUrl={url}
        />
        <StatisticList
          useNumberEffect={
            statisticList.UseNumberEffect !== null
              ? statisticList.UseNumberEffect
              : false
          }
          apiUrl={url}
        />
        <HighlightedInfo
          title={highlightedInfo.Title}
          desc={highlightedInfo.Description}
        />
        <KeyFeatures
          title={keyFeatures.Title}
          smallTitles={true}
          largeTopSpacing={true}
          smallBottomSpacing={true}
          apiUrl={url}
        />
        <Insights apiUrl={url} />
        <TrustedPartners title={trustedPartners.Title} apiUrl={url} />
      </section>
    );
  } else {
    return <div>Data not returned</div>;
  }
};

export default Gaming;
