import useFetch from "../../hooks/useFetch";
import { useLayoutEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import AxisParallax from "../axis-parallax/axis-parallax.component";
import HeroMask from "../hero-mask/hero-mask.component";
import HeroOverlay from "../hero-overlay/hero-overlay.component";
import ScrollIndicator from "../scroll-indicator/scroll-indicator.component";

import "./home-hero.styles.scss";

const HomeHero = (props) => {
  const ref = useRef();
  gsap.registerPlugin(ScrollTrigger);
  const url = `${props.apiUrl}?populate[Hero][populate][0]=CarouselContent&populate[Hero][populate][1]=video_links.VideoThumbnail`;
  const { loading, error, data } = useFetch(url);

  useLayoutEffect(() => {
    let ctx = null;
    if (data !== null) {
      ctx = gsap.context(() => {
        gsap.fromTo(
          ".home-hero__headline",
          { y: 100, autoAlpha: 0 },
          {
            delay: 1.5,
            duration: 1.25,
            y: 0,
            autoAlpha: 1,
            ease: "back",
            overwrite: "auto",
            scrollTrigger: {
              trigger: ".home-hero__headline",
              start: "top 80%",
              end: "bottom 20%",
              onLeave: function () {
                gsap.fromTo(
                  ".home-hero__headline",
                  { autoAlpha: 1 },
                  { autoAlpha: 0, overwrite: "auto" }
                );
              },
              onEnterBack: function () {
                gsap.fromTo(
                  ".home-hero__headline",
                  { autoAlpha: 0 },
                  {
                    duration: 1.25,
                    autoAlpha: 1,
                    ease: "back",
                    overwrite: "auto",
                  }
                );
              },
              onLeaveBack: function () {
                gsap.fromTo(
                  ".home-hero__headline",
                  { autoAlpha: 1 },
                  { autoAlpha: 0, overwrite: "auto" }
                );
              },
            },
          }
        );
      }, ref);
    }
    return () => (ctx !== null ? ctx.revert() : null);
  }, [data]);

  if (loading) return <p>Loading ... </p>;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data.data.attributes) {
    const carouselContent = data.data.attributes.Hero.CarouselContent;
    const videoLinks = data.data.attributes.Hero.video_links;

    return (
      <section ref={ref} className="home-hero">
        <div
          className={`home-hero__container ${
            props.useVideo ? "home-hero__container--video" : ""
          }`}
        >
          <HeroOverlay
            className="home-hero__overlay"
            media={carouselContent.data}
            videoLinks={videoLinks.data}
            useVideo={props.useVideo}
          />
          <AxisParallax />
          <div className="home-hero__headline">
            <h1 className="home-hero__title">{props.title}</h1>
            <p className="home-hero__sub-title">{props.subTitle}</p>
            <Link className="button home-hero__link" to={props.buttonLink}>
              {props.buttonText}
            </Link>
          </div>

          <ScrollIndicator />
          <HeroMask />
        </div>
      </section>
    );
  } else {
    return <div ref={ref}>Data not returned</div>;
  }
};

export default HomeHero;
