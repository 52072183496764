// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.culture__background {
  background: linear-gradient(180deg, #ffffff 0%, #d9d9d9 100%);
}`, "",{"version":3,"sources":["webpack://./src/routes/culture/culture.styles.scss"],"names":[],"mappings":"AACE;EACE,6DAAA;AAAJ","sourcesContent":[".culture {\n  &__background {\n    background: linear-gradient(180deg, #ffffff 0%, #d9d9d9 100%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
