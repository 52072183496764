import { isAbsoluteUrl } from "../../utils/isAbsoluteUrl";
import { Link } from "react-router-dom";
import Tag from "../tag/tag.component";

import "./job-thumbnail.styles.scss";

const JobThumbnail = (props) => {
  const img = props.cover ? props.cover : null;
  const imgUrl = img
    ? isAbsoluteUrl(img.url)
      ? img.url
      : `${process.env.REACT_APP_API_BASE}${img.url}`
    : null;

  return (
    <section className="job-thumbnail">
      <Link className="job-thumbnail__link" to={`/careers/${props.slug}`}>
        <div
          className="job-thumbnail__cover-img"
          style={
            imgUrl
              ? {
                  backgroundImage: `url(${imgUrl})`,
                }
              : {}
          }
        >
          <div className="job-thumbnail__tag-list">
            {props.tags &&
              props.tags.map((tag) => (
                <Tag
                  key={tag.id}
                  title={tag.attributes.Name}
                  theme={tag.attributes.Color}
                />
              ))}
          </div>
        </div>
      </Link>
      <div className="job-thumbnail__container">
        <Link className="job-thumbnail__link" to={`/careers/${props.slug}`}>
          <h3 className="job-thumbnail__title">{props.title}</h3>
        </Link>
        <p className="job-thumbnail__desc">{props.desc}</p>
      </div>
    </section>
  );
};

export default JobThumbnail;
