import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import InitialLoad from "../../components/initial-load/initial-load.component";
import BlogContent from "../../components/blog-content/blog-content.component";
import LoadingIndicator from "../../components/loading-indicator/loading-indicator.component";
import JobHero from "../../components/job-hero/job-hero.component";
import RelatedJobs from "../../components/related-jobs/related-jobs.component";
import SiteHeader from "../../components/site-header/site-header.component";
import SubscribeCta from "../../components/subscribe-cta/subscribe-cta.component";

import "./job-post.styles.scss";

const JobPost = () => {
  const { slug } = useParams();
  const initialUrl = `${process.env.REACT_APP_API_BASE}/api/job-posts?filters[Slug][$eqi]=${slug}&populate=*`;
  const { loading, error, data } = useFetch(initialUrl);

  if (loading) return <LoadingIndicator loading={true} />;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data.data[0] && data.data[0].attributes) {
    const jobPost = data.data[0];
    const actualUrl = `${process.env.REACT_APP_API_BASE}/api/job-posts/${jobPost.id}`;

    return (
      <section className="job-post">
        <InitialLoad />
        <SiteHeader />
        <JobHero
          title={jobPost.attributes.Hero.PageTitle}
          tags={jobPost.attributes.job_tags.data}
          apiUrl={actualUrl}
        />
        <div className="job-post__container">
          <div className="job-post__content">
            <BlogContent
              jobName={jobPost.attributes.Name}
              application={jobPost.attributes.ApplicationForm}
              title={jobPost.attributes.ContentTitle}
              content={jobPost.attributes.Content}
              date={jobPost.attributes.JobDate}
              socialTitle={jobPost.attributes.Social.Title}
              isAlternate={true}
              apiUrl={actualUrl}
            />
          </div>
          <div className="job-post__cta">
            <SubscribeCta
              title={jobPost.attributes.SubscribeCta.Title}
              sentTitle={jobPost.attributes.SubscribeCta.SentTitle}
              desc={jobPost.attributes.SubscribeCta.Description}
              placeholder={jobPost.attributes.SubscribeCta.EmailPlaceholder}
              buttonText={jobPost.attributes.SubscribeCta.ButtonText}
              errorMessage={jobPost.attributes.SubscribeCta.ErrorMessage}
              sentMessage={jobPost.attributes.SubscribeCta.SentMessage}
            />
          </div>
        </div>
        <div className="job-post__related">
          <RelatedJobs
            title={jobPost.attributes.RelatedJobs.Title}
            apiUrl={actualUrl}
          />
        </div>
      </section>
    );
  } else {
    return <div>Data not returned</div>;
  }
};

export default JobPost;
