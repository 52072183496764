import { ReactComponent as CrossVector } from "../../img/background-decor/repeating-vector.svg";
import { ReactComponent as Mask } from "../../img/background-decor/widebar.svg";

import "./mask-bottom.styles.scss";

const MaskBottom = (props) => {
  return (
    <section
      className={`mask-bottom ${
        props.theme ? `mask-bottom--${props.theme}` : ""
      } ${props.invert ? "mask-bottom--invert" : ""}`}
    >
      <Mask className="mask-bottom__mask" />
      {props.useOverlay && (
        <CrossVector className="mask-bottom__cross-vector" />
      )}
    </section>
  );
};

export default MaskBottom;
