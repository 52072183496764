import "./tag.styles.scss";

const Tag = (props) => {
  return (
    <section className={`tag tag--${props.theme}`}>
      <h4 className="tag__title">{props.title}</h4>
    </section>
  );
};

export default Tag;
