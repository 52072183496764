import useFetch from "../../hooks/useFetch";
import MediaQuery from "react-responsive";
import { Breakpoints } from "../../utils/breakpoints";
import Carousel from "../carousel/carousel.component";
import Partner from "../partner/partner.component";
import TitleAccentLine from "../title-accent-line/title-accent-line.component";

import "./trusted-partners.styles.scss";

const TrustedPartners = (props) => {
  const url = `${props.apiUrl}?populate[TrustedPartners][populate][0]=trusted_partners.Logo`;
  const { loading, error, data } = useFetch(url);

  if (loading) return <p>Loading ... </p>;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }
  if (data.data.attributes) {
    const trustedPartners =
      data.data.attributes.TrustedPartners.trusted_partners.data;
    const partnersComponents = trustedPartners.map((partner) => (
      <Partner
        key={partner.id}
        logo={partner.attributes.Logo}
        link={partner.attributes.Link}
      />
    ));

    return (
      <section className="trusted-partners">
        <TitleAccentLine title={props.title} theme="dark" />
        <div className="trusted-partners__container">
          <MediaQuery maxWidth={Breakpoints("laptop") - 1}>
            <Carousel
              slides={partnersComponents}
              theme="dark"
              useAnimation={true}
              checkImages={true}
            />
          </MediaQuery>
          <MediaQuery minWidth={Breakpoints("laptop")}>
            <Carousel
              slides={partnersComponents}
              theme="dark"
              slidesPerView={5}
              spaceBetween={30}
              useAnimation={true}
              checkImages={true}
            />
          </MediaQuery>
        </div>
      </section>
    );
  } else {
    return <div>Data not returned</div>;
  }
};

export default TrustedPartners;
