import useFetch from "../../hooks/useFetch";
import InitialLoad from "../../components/initial-load/initial-load.component";
import LoadingIndicator from "../../components/loading-indicator/loading-indicator.component";
import ContactForm from "../../components/contact-form/contact-form.component";
import ContactHero from "../../components/contact-hero/contact-hero.component";
import OurLocations from "../../components/our-locations/our-locations.component";
import SiteHeader from "../../components/site-header/site-header.component";

import "./contact-us.styles.scss";

const ContactUs = () => {
  const url = `${process.env.REACT_APP_API_BASE}/api/contact-page`;
  const { loading, error, data } = useFetch(`${url}?populate=*`);

  if (loading) return <LoadingIndicator loading={true} />;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data.data.attributes) {
    const hero = data.data.attributes.Hero;
    const contactForm = data.data.attributes.ContactForm;
    const locationData = data.data.attributes.OurLocations;

    console.log(contactForm);

    return (
      <section className="contact-us">
        <InitialLoad />
        <SiteHeader theme="dark" />
        <div className="contact-us__header">
          <ContactHero
            title={hero.Title}
            desc={hero.Description}
            email={hero.Email}
            phone={hero.PhoneNumber}
            apiUrl={url}
          />
          <ContactForm
            title={contactForm.Title}
            buttonText={contactForm.ButtonText}
            sentTitle={contactForm.SentTitle}
            sentMessage={contactForm.SentMessage}
            errorMessage={contactForm.ErrorMessage}
            checkboxText={contactForm.checkboxText}
            disclaimerText={contactForm.disclaimerText}
          />
        </div>
        <OurLocations
          title={locationData.Title}
          desc={locationData.Description}
          apiUrl={url}
        />
      </section>
    );
  } else {
    return <div>Data not returned</div>;
  }
};

export default ContactUs;
