import { ReactComponent as Mask } from "../../img/background-decor/widebar.svg";
import { ReactComponent as CrossVector } from "../../img/background-decor/repeating-vector.svg";

import "./hero-mask.styles.scss";

const HeroMask = (props) => {
  return (
    <section
      className={`hero-mask ${props.theme ? `hero-mask--${props.theme}` : ""}`}
    >
      <Mask className="hero-mask__mask" />
      {props.useOverlay && <CrossVector className="hero-mask__cross-vector" />}
    </section>
  );
};

export default HeroMask;
