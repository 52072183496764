import useFetch from "../../hooks/useFetch";
import InitialLoad from "../../components/initial-load/initial-load.component";
import LoadingIndicator from "../../components/loading-indicator/loading-indicator.component";
import AxisParallax from "../../components/axis-parallax/axis-parallax.component";
import DiamondImage from "../../components/diamond-image/diamond-image.component";
import ExploreProducts from "../../components/explore-products/explore-products.component";
import TitleWithCTA from "../../components/title-with-cta/title-with-cta.component";
import SiteHeader from "../../components/site-header/site-header.component";

import "./products.styles.scss";

const Products = () => {
  const url = `${process.env.REACT_APP_API_BASE}/api/product-page`;
  const { loading, error, data } = useFetch(`${url}?populate=*`);

  if (loading) return <LoadingIndicator loading={true} />;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data.data.attributes) {
    const title = data.data.attributes.TitleWithCta;
    const exploreProducts = data.data.attributes.Products;

    return (
      <section className="products">
        <InitialLoad />
        <SiteHeader theme="dark" />
        <TitleWithCTA
          isHero={true}
          title={title.Title}
          desc={title.Description}
          link={title.LinkDestination}
          linkText={title.LinkText}
        />
        <AxisParallax
          theme="dark"
          top="92px"
          topDesktop="134px"
          rightDesktop="25px"
        />
        <DiamondImage apiUrl={url} />
        <ExploreProducts
          title={exploreProducts.Title}
          theme="light"
          useAdditionalInfo={true}
          apiUrl={url}
        />
      </section>
    );
  } else {
    return <div>Data not returned</div>;
  }
};

export default Products;
