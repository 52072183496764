import { isAbsoluteUrl } from "../../utils/isAbsoluteUrl";

import "./category.styles.scss";

const Category = (props) => {
  const img = props.coverImg ? props.coverImg.attributes : null;
  let styles = {};
  if (img) {
    const imgUrl = isAbsoluteUrl(img.url)
      ? img.url
      : `${process.env.REACT_APP_API_BASE}${img.url}`;
    styles.backgroundImage = `url(${imgUrl})`;
  }

  return (
    <section className="category">
      <div className="category__cover">
        <div className="category__cover-img" style={styles}>
          <h3 className="category__title">{props.title}</h3>
        </div>
      </div>
    </section>
  );
};

export default Category;
