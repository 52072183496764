import React, { Component } from "react";

import "./nav-bar-hamburger.styles.scss";

class NavBarHamburger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
      classes: {
        hamburger: `nav-bar-hamburger nav-bar-hamburger--${this.props.theme}`,
        bar: "nav-bar-hamburger__bar",
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.parentDisplayState !== this.props.parentDisplayState &&
      this.props.parentDisplayState === false
    ) {
      this.updateState(false);
    }
  }

  toggleMenu = () => {
    const isOpen = this.state.isMenuOpen;
    this.props.handleToggle(!isOpen);
    this.updateState(!isOpen);
  };

  updateState = (toggle) => {
    this.setState({
      isMenuOpen: toggle,
      classes: {
        hamburger: `nav-bar-hamburger nav-bar-hamburger--${this.props.theme} ${
          toggle ? "nav-bar-hamburger--open" : ""
        }`,
        bar: `nav-bar-hamburger__bar ${toggle ? "animate" : ""}`,
      },
    });
  };

  render() {
    return (
      <button
        className={this.state.classes.hamburger}
        onClick={this.toggleMenu}
      >
        <div className={this.state.classes.bar}></div>
      </button>
    );
  }
}

export default NavBarHamburger;
