import useFetch from "../../../hooks/useFetch";
import InitialLoad from "../../../components/initial-load/initial-load.component";
import LoadingIndicator from "../../../components/loading-indicator/loading-indicator.component";
import ContactCTA from "../../../components/contact-cta/contact-cta.component";
import FeaturesList from "../../../components/features-list/features-list.component";
import FeaturedTitle from "../../../components/featured-title/featured-title.component";
import ProductHero from "../../../components/product-hero/product-hero.component";
import SiteHeader from "../../../components/site-header/site-header.component";

import "./lite.styles.scss";

const Lite = () => {
  const url = `${process.env.REACT_APP_API_BASE}/api/xpoint-lite`;
  const { loading, error, data } = useFetch(`${url}?populate=*`);

  if (loading) return <LoadingIndicator loading={true} />;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data.data.attributes) {
    const hero = data.data.attributes.Hero;
    const featuredTitle = data.data.attributes.FeaturedTitle;
    const contactCTA = data.data.attributes.ContactCta;

    return (
      <section className="lite">
        <InitialLoad />
        <SiteHeader />
        <ProductHero
          product="Lite"
          title={hero.Title}
          subTitle={hero.SubTitle}
          buttonText={hero.ButtonText}
          buttonLink={hero.ButtonLink}
          useVideo={hero.UseVideo}
          apiUrl={url}
        />
        <FeaturedTitle
          title={featuredTitle.Title}
          isProductDesign={true}
          useDecodeEffect={
            featuredTitle.UseDecodeEffect !== null
              ? featuredTitle.UseDecodeEffect
              : false
          }
        />
        <FeaturesList apiUrl={url} />
        <ContactCTA
          title={contactCTA.Title}
          link={contactCTA.LinkDestination}
          linkText={contactCTA.LinkText}
          largerText={true}
          useParallaxEffect={
            contactCTA.UseParallaxEffect !== null
              ? contactCTA.UseParallaxEffect
              : false
          }
          apiUrl={url}
        />
      </section>
    );
  } else {
    return <div>Data not returned</div>;
  }
};

export default Lite;
