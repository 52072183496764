import { useLayoutEffect, useRef } from "react";
import useFetch from "../../hooks/useFetch";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import useScreenSize from "../../hooks/useScreenSize";
import JobThumbnail from "../job-thumbnail/job-thumbnail.component";

import "./job-list.styles.scss";

const JobList = () => {
  const ref = useRef();
  gsap.registerPlugin(ScrollTrigger);
  const screenSize = useScreenSize();
  const url = `${process.env.REACT_APP_API_BASE}/api/job-posts?fields[0]=ShortDescription&fields[1]=Slug&populate[job_tags][populate][0]=Color&populate[Hero][populate][1]=CoverImage&filters[IsActive][$eq]=True`;
  const { loading, error, data } = useFetch(url);

  useLayoutEffect(() => {
    let ctx = null;
    if (data !== null) {
      ctx = gsap.context(() => {
        ScrollTrigger.create({
          trigger: ".job-list__container",
          start: "top 80%",
          onEnter: function () {
            gsap.fromTo(
              ".job-thumbnail",
              { scale: 0, autoAlpha: 0 },
              {
                duration: 0.7,
                scale: 1,
                autoAlpha: 1,
                stagger: 0.2,
                ease: "sine.inOut",
                overwrite: "auto",
              }
            );
          },
          onLeaveBack: function () {
            gsap.fromTo(
              ".job-thumbnail",
              { autoAlpha: 1 },
              { autoAlpha: 0, overwrite: "auto" }
            );
          },
        });

        if (screenSize === "laptop" || screenSize === "desktop") {
          const thumbnails = gsap.utils.toArray(".job-thumbnail");
          thumbnails.forEach((thumbnail) => {
            const animation = gsap.to(thumbnail, {
              paused: true,
              top: -5,
              duration: 0.25,
            });

            thumbnail.addEventListener("mouseenter", () => animation.play());
            thumbnail.addEventListener("mouseleave", () => animation.reverse());
          });
        }
      }, ref);
    }
    return () => {
      if (ctx !== null) {
        ctx.revert();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) return <p>Loading ... </p>;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }
  if (data.data) {
    const jobList = data.data;

    return (
      <section ref={ref} className="job-list">
        <div className="job-list__container">
          {jobList.map((job) => {
            const tags = job.attributes.job_tags.data
              ? job.attributes.job_tags.data
              : null;
            const thumbnail =
              job.attributes.Hero.CoverImage.data &&
              job.attributes.Hero.CoverImage.data.attributes
                ? job.attributes.Hero.CoverImage.data.attributes
                : null;
            return (
              <JobThumbnail
                key={job.id}
                title={job.attributes.Hero.PageTitle}
                desc={job.attributes.ShortDescription}
                tags={tags}
                cover={thumbnail}
                slug={job.attributes.Slug}
              />
            );
          })}
        </div>
      </section>
    );
  } else {
    return <div ref={ref}>Data not returned</div>;
  }
};

export default JobList;
