const checkImagesLoaded = () => {
  const images = document.querySelectorAll("img");
  const numImages = images.length;
  let numLoaded = 0,
  i = 0;

  return new Promise((resolve, reject) => {
      for (i; i < numImages; i++) {
        if (images[i].complete) {
          imgLoaded();
        } else {
          images[i].addEventListener("load", imgLoaded);
        }
      }

      function imgLoaded() {
        if (++numLoaded === numImages) {
          resolve("Images Loaded");
        }
      }

      setTimeout(() => {
        reject("Timed out");
      }, 5000);
  });
}

export default checkImagesLoaded