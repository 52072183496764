// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width: 1024px) {
  .contact-us__header {
    display: flex;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/tools/media-queries/_above.scss","webpack://./src/routes/contact-us/contact-us.styles.scss"],"names":[],"mappings":"AAKE;ECFA;IAEI,aAAA;EAFJ;AACF","sourcesContent":["@import \"../functions/breakpoint-value\";\n\n@mixin above($key) {\n  $min-width: breakpoint-value($key);\n\n  @media screen and (min-width: #{$min-width}) {\n    @content;\n  }\n}\n","@import \"../../styles/tools/media-queries/above\";\n\n.contact-us {\n  &__header {\n    @include above(laptop) {\n      display: flex;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
