import useFetch from "../../hooks/useFetch";
import InitialLoad from "../../components/initial-load/initial-load.component";
import JobList from "../../components/job-list/job-list.component";
import LoadingIndicator from "../../components/loading-indicator/loading-indicator.component";
import Hero from "../../components/hero/hero.component";
import SiteHeader from "../../components/site-header/site-header.component";
import TitleWithRte from "../../components/title-with-rte/title-with-rte";

import "./careers.styles.scss";

const Careers = () => {
  const url = `${process.env.REACT_APP_API_BASE}/api/careers-page`;
  const { loading, error, data } = useFetch(`${url}?populate=*`);

  if (loading) return <LoadingIndicator loading={true} />;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data.data.attributes) {
    const hero = data.data.attributes.Hero;
    const titleRte = data.data.attributes.TitleRte;

    return (
      <section className="careers">
        <InitialLoad />
        <SiteHeader />
        <Hero title={hero.PageTitle} useVideo={hero.UseVideo} apiUrl={url} />
        <TitleWithRte title={titleRte.Title} rte={titleRte.Description} />
        <JobList />
      </section>
    );
  } else {
    return <div>Data not returned</div>;
  }
};

export default Careers;
