import MediaQuery from "react-responsive";
import { Breakpoints } from "../../utils/breakpoints";

import { ReactComponent as Topography } from "../../img/background-decor/topography-background/topographic-mobile.svg";
import { ReactComponent as TopographyTablet } from "../../img/background-decor/topography-background/topographic-tablet.svg";
import { ReactComponent as TopographyLaptop } from "../../img/background-decor/topography-background/topographic-laptop.svg";
import { ReactComponent as TopographyDesktop } from "../../img/background-decor/topography-background/topographic-desktop.svg";

import "./topography-background.styles.scss";

const TopographyBackground = (props) => {
  const className = `topography-background__topography ${
    props.useHeight ? "topography-background__topography--height" : ""
  }`;
  return (
    <section className="topography-background">
      <MediaQuery maxWidth={Breakpoints("tablet") - 1}>
        <Topography className={className} />
      </MediaQuery>
      <MediaQuery
        minWidth={Breakpoints("tablet")}
        maxWidth={Breakpoints("laptop") - 1}
      >
        <TopographyTablet className={className} />
      </MediaQuery>
      <MediaQuery
        minWidth={Breakpoints("laptop")}
        maxWidth={Breakpoints("desktop") - 1}
      >
        <TopographyLaptop className={className} />
      </MediaQuery>
      <MediaQuery minWidth={Breakpoints("desktop")}>
        <TopographyDesktop className={className} />
      </MediaQuery>
    </section>
  );
};

export default TopographyBackground;
