import React from "react";

import { ReactComponent as Triangle } from "../../img/background-decor/featured-title/cross-vector-triangle.svg";

import "./featured-title-triangle.styles.scss";

const FeaturedTitleTriangle = React.forwardRef((props, ref) => {
  return (
    <section
      ref={ref}
      className={`featured-title-triangle ${
        props.isAltDesign ? "featured-title-triangle--alt" : ""
      } ${props.isProductDesign ? "featured-title-triangle--product" : ""} ${
        props.useParallax ? "featured-title-triangle--using-parallax" : ""
      }`}
    >
      <Triangle className="featured-title-triangle__vector" />
      <Triangle className="featured-title-triangle__vector featured-title-triangle__vector--right" />
    </section>
  );
});

export default FeaturedTitleTriangle;
