import { useLayoutEffect, useRef } from "react";
import useFetch from "../../hooks/useFetch";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import useScreenSize from "../../hooks/useScreenSize";
import BlogThumbnail from "../blog-thumbnail/blog-thumbnail.component";
import TitleAccentLine from "../title-accent-line/title-accent-line.component";

import "./related-posts.styles.scss";

const RelatedPosts = (props) => {
  const ref = useRef();
  gsap.registerPlugin(ScrollTrigger);
  const screenSize = useScreenSize();
  const url = `${props.apiUrl}?fields[0]=Title&populate[RelatedPosts][populate][0]=blog_posts.Hero.CoverImage&populate[RelatedPosts][populate][1]=blog_posts.blog_categories&populate[RelatedPosts][populate][2]=blog_posts.blog_industry`;
  const { loading, error, data } = useFetch(url);

  useLayoutEffect(() => {
    let ctx = null;
    if (data !== null) {
      ctx = gsap.context(() => {
        ScrollTrigger.create({
          trigger: ".related-posts__container",
          start: "top 80%",
          onEnter: function () {
            gsap.fromTo(
              ".blog-thumbnail",
              { scale: 0, autoAlpha: 0 },
              {
                duration: 0.7,
                scale: 1,
                autoAlpha: 1,
                stagger: 0.2,
                ease: "sine.inOut",
                overwrite: "auto",
              }
            );
          },
          onLeaveBack: function () {
            gsap.fromTo(
              ".blog-thumbnail",
              { autoAlpha: 1 },
              { autoAlpha: 0, overwrite: "auto" }
            );
          },
        });

        if (screenSize === "laptop" || screenSize === "desktop") {
          const thumbnails = gsap.utils.toArray(".blog-thumbnail");
          thumbnails.forEach((thumbnail) => {
            const animation = gsap.to(thumbnail, {
              paused: true,
              top: -5,
              duration: 0.25,
            });

            thumbnail.addEventListener("mouseenter", () => animation.play());
            thumbnail.addEventListener("mouseleave", () => animation.reverse());
          });
        }
      }, ref);
    }
    return () => {
      if (ctx !== null) {
        ctx.revert();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) return <p>Loading ... </p>;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data.data.attributes) {
    const relatedPosts = data.data.attributes.RelatedPosts.blog_posts.data;

    return relatedPosts.length > 0 ? (
      <section ref={ref} className="related-posts">
        <TitleAccentLine title={props.title} theme="dark" />
        <div className="related-posts__container">
          {relatedPosts.map((blog) => {
            const categories = blog.attributes.blog_categories.data
              ? blog.attributes.blog_categories.data
              : null;
            const industry =
              blog.attributes.blog_industry.data &&
              blog.attributes.blog_industry.data.attributes
                ? blog.attributes.blog_industry.data.attributes
                : null;
            const thumbnail =
              blog.attributes.Hero.CoverImage.data &&
              blog.attributes.Hero.CoverImage.data.attributes
                ? blog.attributes.Hero.CoverImage.data.attributes
                : null;
            return (
              <BlogThumbnail
                key={blog.id}
                title={blog.attributes.Title}
                date={blog.attributes.BlogDate}
                categories={categories}
                industry={industry}
                thumbnail={thumbnail}
                slug={blog.attributes.Slug}
              />
            );
          })}
        </div>
      </section>
    ) : (
      <section
        ref={ref}
        className="related-posts related-posts--no-posts"
      ></section>
    );
  } else {
    return <div ref={ref}>Data not returned</div>;
  }
};

export default RelatedPosts;
