import { useLayoutEffect, useRef } from "react";
import useFetch from "../../hooks/useFetch";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Category from "../category/category.component";

import { ReactComponent as Polygon } from "../../img/background-decor/polygon.svg";

import "./category-list.styles.scss";

const CategoryList = (props) => {
  const ref = useRef();
  gsap.registerPlugin(ScrollTrigger);
  const url = `${props.apiUrl}?[populate][0]=culture_categories.CoverImage`;
  const { loading, error, data } = useFetch(url);

  useLayoutEffect(() => {
    let ctx = null;
    if (data !== null) {
      ctx = gsap.context(() => {
        ScrollTrigger.create({
          trigger: ".category-list__container",
          start: "top 80%",
          onEnter: function () {
            gsap.fromTo(
              ".category",
              { scale: 0, autoAlpha: 0 },
              {
                duration: 0.7,
                scale: 1,
                autoAlpha: 1,
                stagger: {
                  each: 0.1,
                  from: "center",
                },
                ease: "sine.inOut",
                overwrite: "auto",
              }
            );
          },
          onLeaveBack: function () {
            gsap.fromTo(
              ".category",
              { autoAlpha: 1 },
              { autoAlpha: 0, overwrite: "auto" }
            );
          },
        });
      }, ref);
    }
    return () => (ctx !== null ? ctx.revert() : null);
  }, [data]);

  if (loading) return <p>Loading ... </p>;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data.data.attributes) {
    const list = data.data.attributes.culture_categories.data;

    return (
      <section ref={ref} className="category-list">
        <div className="category-list__container">
          {list.map((category) => (
            <Category
              key={category.id}
              title={category.attributes.Name}
              coverImg={category.attributes.CoverImage.data}
            />
          ))}
        </div>
        <Polygon className="category-list__polygon" />
      </section>
    );
  } else {
    return <div ref={ref}>Data not returned</div>;
  }
};

export default CategoryList;
