import { Routes, Route } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import InitialLoad from "./components/initial-load/initial-load.component";

// page & layout imports
import AboutUs from "./routes/about-us/about-us.component";
import BlogPost from "./routes/blog-post/blog-post.component";
import Careers from "./routes/careers/careers.component";
import ContactUs from "./routes/contact-us/contact-us.component";
import Culture from "./routes/culture/culture.component";
import Eula from "./routes/eula/eula.component";
// import Entertainment from './routes/industries/entertainment/entertainment.component';
import Gaming from "./routes/industries/gaming/gaming.component";
import Home from "./routes/home/home.component";
import JobPost from "./routes/job-post/job-post.component";
import Lite from "./routes/products/lite/lite.component";
import Industries from "./routes/industries/industries.component";
import Media from "./routes/media/media.component";
import NoMatch from "./routes/no-match/no-match.component";
import People from "./routes/people/people.component";
import PrivacyPolicy from "./routes/privacy-policy/privacy-policy.component";
import Products from "./routes/products/products.component";
import SiteFooter from "./components/site-footer/site-footer.component";
import Verify from "./routes/products/verify/verify.component";

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_API_BASE}/graphql`,
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <main className="App">
        <InitialLoad />
        <Routes>
          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="careers" element={<Careers />} />
          <Route path="/careers/:slug" element={<JobPost />} />
          <Route path="culture" element={<Culture />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="eula" element={<Eula />} />
          <Route path="industries" element={<Industries />} />
          {/* <Route path="industries/entertainment" element={<Entertainment />} /> */}
          {/* TODO: Page developed, uncomment to include in route when client is ready */}
          <Route path="industries/gaming" element={<Gaming />} />
          <Route path="media" element={<Media />} />
          <Route path="/media/:slug" element={<BlogPost />} />
          <Route path="people" element={<People />} />
          <Route path="products" element={<Products />} />
          <Route path="products/verify" element={<Verify />} />
          <Route path="products/lite" element={<Lite />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
        <SiteFooter />
      </main>
    </ApolloProvider>
  );
}

export default App;
