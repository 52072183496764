import { isAbsoluteUrl } from "../../utils/isAbsoluteUrl";

import "./feature.styles.scss";

const Feature = (props) => {
  const img =
    props.image.data !== null ? props.image.data.attributes : undefined;
  const imgUrl = img
    ? isAbsoluteUrl(img.url)
      ? img.url
      : `${process.env.REACT_APP_API_BASE}${img.url}`
    : undefined;

  return (
    <section className="feature">
      <div className="feature__container">
        {img && (
          <img
            className="feature__image"
            alt={img.alternativeText}
            src={imgUrl}
          />
        )}
        {img === undefined && (
          <div className="feature__image-placeholder"></div>
        )}
        <h3 className="feature__title">{props.title}</h3>
        <p className="feature__desc">{props.desc}</p>
      </div>
    </section>
  );
};

export default Feature;
