import useFetch from "../../hooks/useFetch";
import InitialLoad from "../../components/initial-load/initial-load.component";
import LoadingIndicator from "../../components/loading-indicator/loading-indicator.component";
import ContactCTA from "../../components/contact-cta/contact-cta.component";
import ExploreProducts from "../../components/explore-products/explore-products.component";
import HomeHero from "../../components/home-hero/home-hero.component";
import FeaturedTitle from "../../components/featured-title/featured-title.component";
import KeyFeatures from "../../components/key-features/key-features.component";
import SiteHeader from "../../components/site-header/site-header.component";
import TrustedPartners from "../../components/trusted-partners/trusted-partners.component";

import "./home.styles.scss";

const Home = () => {
  const url = `${process.env.REACT_APP_API_BASE}/api/home-page`;
  const { loading, error, data } = useFetch(`${url}?populate=*`);

  if (loading) return <LoadingIndicator loading={true} />;

  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data.data.attributes) {
    const hero = data.data.attributes.Hero;
    const featuredTitle = data.data.attributes.FeaturedTitle;
    const keyFeatures = data.data.attributes.KeyFeatures;
    const trustedPartners = data.data.attributes.TrustedPartners;
    const exploreProducts = data.data.attributes.Products;
    const contactCTA = data.data.attributes.ContactCta;

    return (
      <section className="home">
        <InitialLoad />
        <SiteHeader />
        <HomeHero
          title={hero.Title}
          subTitle={hero.SubTitle}
          buttonText={hero.ButtonText}
          buttonLink={hero.ButtonLink}
          useVideo={hero.UseVideo}
          apiUrl={url}
        />
        <FeaturedTitle
          title={featuredTitle.Title}
          useDecodeEffect={
            featuredTitle.UseDecodeEffect !== null
              ? featuredTitle.UseDecodeEffect
              : false
          }
          useParallax={featuredTitle.UseParallaxScroll}
          apiUrl={url}
        />
        <KeyFeatures title={keyFeatures.Title} apiUrl={url} />
        <TrustedPartners title={trustedPartners.Title} apiUrl={url} />
        <ExploreProducts title={exploreProducts.Title} apiUrl={url} />
        <ContactCTA
          title={contactCTA.Title}
          link={contactCTA.LinkDestination}
          linkText={contactCTA.LinkText}
          parrallaxSpeedMultiplier={contactCTA.parrallaxSpeedMultiplier}
          useParallaxEffect={
            contactCTA.UseParallaxEffect !== null
              ? contactCTA.UseParallaxEffect
              : false
          }
          apiUrl={url}
        />
      </section>
    );
  } else {
    return <div>Data not returned</div>;
  }
};

export default Home;
