// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mask-right {
  grid-area: mask;
  position: relative;
  z-index: 1;
}
.mask-right__mask {
  position: absolute;
  top: calc((100% - 108px) / 2);
  right: -57px;
  transform: translateY(25%);
  color: #ffffff;
  z-index: 1;
}
.mask-right--dark .mask-right__mask {
  color: #131c23;
}`, "",{"version":3,"sources":["webpack://./src/components/mask-right/mask-right.styles.scss","webpack://./src/styles/core/_scss-variables.scss"],"names":[],"mappings":"AAGA;EAEE,eAAA;EACA,kBAAA;EACA,UAAA;AAHF;AAKE;EACE,kBAAA;EACA,6BAAA;EACA,YAAA;EACA,0BAAA;EACA,cCVM;EDWN,UAAA;AAHJ;AAKI;EACE,cCgCU;ADnChB","sourcesContent":["@import \"../../styles/tools/media-queries/above\";\n@import \"../../styles/core/scss-variables\";\n\n.mask-right {\n  $block: &;\n  grid-area: mask;\n  position: relative;\n  z-index: 1;\n\n  &__mask {\n    position: absolute;\n    top: calc((100% - 108px) / 2);\n    right: -57px;\n    transform: translateY(25%);\n    color: $iceberg;\n    z-index: 1;\n\n    #{$block}--dark & {\n      color: $arrow-partial;\n    }\n  }\n}\n","// Primary Colors\n$indigo: #170f24;\n$slate-blue: #2b263a;\n$silver-grey: #808080;\n$iceberg: #ffffff;\n\n// Accent Colors\n$lilac: #8400ff;\n$cyan: #00fef0;\n$chartreuse: #7fff00;\n$fuchsia: #fe01e9;\n\n// Gradients\n$gradient-dark-ln: linear-gradient(90deg, $indigo 0%, $slate-blue 110%);\n\n$gradient-grey-ln: linear-gradient(180deg, $silver-grey 0%, $iceberg 100%);\n\n$gradient-grey-ln-reverse: linear-gradient(\n  360deg,\n  $silver-grey 0%,\n  $iceberg 100%\n);\n\n// Gutters\n$mobile-gutter: 24px;\n$tablet-gutter: 36px;\n$laptop-gutter: 32px;\n$desktop-gutter: 80px;\n\n// Border radius\n$radious-default: 1.3rem;\n\n// Max width\n$maxwidth: 1440px;\n\n// Svg Colors\n$cross-vector-original: #2d2c2c;\n$cross-vector-grey: #bebdbd;\n$cross-vector-silver: #c4c3c3;\n$cross-vector-light: #f8f8f8;\n$cross-vector-dark: #302f2f;\n$topographic-vector-original: #845858;\n$topographic-square-original: #231f20;\n$watermark-mobile: #1e1e1e;\n$watermark-negative: #f3f3f3;\n$watermark-value-list: #222d35;\n$topography-value-list: #484445;\n$right-arrow: #170f24;\n$right-arrow-solid: #dbdbdb;\n$linkedin: #313131;\n$arrow-partial: #131c23;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
