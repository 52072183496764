import useFetch from "../../hooks/useFetch";
import { isAbsoluteUrl } from "../../utils/isAbsoluteUrl";

import "./diamond-image.styles.scss";

const DiamondImage = (props) => {
  const url = `${props.apiUrl}?populate[DiamondImage][populate][0]=Image`;
  const { loading, error, data } = useFetch(url);

  if (loading) return <p>Loading ... </p>;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }
  if (data.data.attributes) {
    const img = data.data.attributes.DiamondImage.Image.data.attributes;
    const imgUrl = isAbsoluteUrl(img.url)
      ? img.url
      : `${process.env.REACT_APP_API_BASE}${img.url}`;

    return (
      <section className="diamond-image">
        <div className="diamond-image__img-container">
          <div
            className="diamond-image__img"
            style={{
              backgroundImage: `url(${imgUrl})`,
            }}
          ></div>
        </div>
      </section>
    );
  } else {
    return <div>Data not returned</div>;
  }
};

export default DiamondImage;
