import { CSSTransition } from "react-transition-group";
import { useRef } from "react";

import LoadingGif from "../../img/icon/icon-loading.gif";

import "./loading-indicator.styles.scss";

const LoadingIndicator = (props) => {
  const nodeRef = useRef(null);

  return (
    props.loading && (
      <CSSTransition
        nodeRef={nodeRef}
        in={props.loading}
        classNames="loading"
        timeout={500}
      >
        <section ref={nodeRef} className="loading-indicator">
          <div className="loading-indicator__container">
            <img
              src={LoadingGif}
              className="loading-indicator__gif"
              alt="loading animation..."
            />
          </div>
        </section>
      </CSSTransition>
    )
  );
};

export default LoadingIndicator;
