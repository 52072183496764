import RteContent from "../../components/rte-content/rte-content.component";
import TitleAccentLine from "../title-accent-line/title-accent-line.component";

import "./title-with-rte.styles.scss";

const TitleWithRte = (props) => {
  return (
    <section className="title-with-rte">
      <div className="title-with-rte__title">
        <TitleAccentLine title={props.title} theme="dark" />
      </div>
      <div className="title-with-rte__container">
        <RteContent content={props.rte} />
      </div>
    </section>
  );
};

export default TitleWithRte;
