import useFetch from "../../../hooks/useFetch";
import InitialLoad from "../../../components/initial-load/initial-load.component";
import LoadingIndicator from "../../../components/loading-indicator/loading-indicator.component";
import ContentOverImage from "../../../components/content-over-image/content-over-image.component";
import FeaturesList from "../../../components/features-list/features-list.component";
import FeaturedTitle from "../../../components/featured-title/featured-title.component";
import Platforms from "../../../components/platforms/platforms.component";
import ProductHero from "../../../components/product-hero/product-hero.component";
import SiteHeader from "../../../components/site-header/site-header.component";

import "./verify.styles.scss";

const Verify = () => {
  const url = `${process.env.REACT_APP_API_BASE}/api/xpoint-verify`;
  const { loading, error, data } = useFetch(`${url}?populate=*`);

  if (loading) return <LoadingIndicator loading={true} />;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data.data.attributes) {
    const hero = data.data.attributes.Hero;
    const featuredTitle = data.data.attributes.FeaturedTitle;
    const platforms = data.data.attributes.Platforms;
    const contentOverImage = data.data.attributes.ContentOverImage;

    return (
      <section className="verify">
        <InitialLoad />
        <SiteHeader />
        <ProductHero
          product="Verify"
          title={hero.Title}
          subTitle={hero.SubTitle}
          buttonText={hero.ButtonText}
          buttonLink={hero.ButtonLink}
          useVideo={hero.UseVideo}
          apiUrl={url}
        />
        <FeaturedTitle
          title={featuredTitle.Title}
          isProductDesign={true}
          useDecodeEffect={
            featuredTitle.UseDecodeEffect !== null
              ? featuredTitle.UseDecodeEffect
              : false
          }
        />
        <FeaturesList apiUrl={url} />
        <div className="verify__info">
          <Platforms title={platforms.Title} />
          <ContentOverImage
            title={contentOverImage.Title}
            desc={contentOverImage.Description}
            apiUrl={url}
          />
        </div>
      </section>
    );
  } else {
    return <div>Data not returned</div>;
  }
};

export default Verify;
