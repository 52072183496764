import { Link } from "react-router-dom";
import { isAbsoluteUrl } from "../../utils/isAbsoluteUrl";
import { ReactComponent as CrossVector } from "../../img/background-decor/repeating-vector.svg";

import "./title-link.styles.scss";

const TitleLink = (props) => {
  const img = props.coverImg.data.attributes;
  const imgUrl = isAbsoluteUrl(img.url)
    ? img.url
    : `${process.env.REACT_APP_API_BASE}${img.url}`;

  const content = (
    <div className="title-link__wrap">
      <div
        className="title-link__coverImg"
        style={{
          backgroundImage: `url(${imgUrl})`,
        }}
      ></div>
      <CrossVector className="title-link__cross-vector" />
      <div className="title-link__container">
        <p className="title-link__subtitle">{props.subtitle}</p>
        <h2 className="title-link__title">{props.title}</h2>
      </div>
    </div>
  );

  return props.link ? (
    <Link className="title-link" to={props.link}>
      {content}
    </Link>
  ) : (
    <section className="title-link"> {content} </section>
  );
};

export default TitleLink;
