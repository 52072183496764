import { ReactComponent as Pin } from "../../img/icon/icon-pin.svg";

import "./location.styles.scss";

const Location = (props) => {
  return (
    <section className="location">
      <Pin className="location__icon" />
      <div className="location__container">
        <h3 className="location__name">{props.name}</h3>
        <p className="location__text">{props.text}</p>
        {props.fact && (
          <p className="location__text">
            <strong>Fact:</strong> {props.fact}
          </p>
        )}
      </div>
    </section>
  );
};

export default Location;
