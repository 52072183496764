import { useLayoutEffect, useRef } from "react";
import useFetch from "../../hooks/useFetch";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import MediaQuery from "react-responsive";
import { Breakpoints } from "../../utils/breakpoints";
import CultureFeature from "../culture-feature/culture-feature.component";
import TitleAccentLine from "../title-accent-line/title-accent-line.component";

import { ReactComponent as Watermark } from "../../img/background-decor/culture-features/watermark.svg";
import { ReactComponent as WatermarkTablet } from "../../img/background-decor/culture-features/watermark-tablet.svg";
import { ReactComponent as WatermarkLaptop } from "../../img/background-decor/culture-features/watermark-laptop.svg";
import { ReactComponent as WatermarkDesktop } from "../../img/background-decor/culture-features/watermark-desktop.svg";

import "./culture-features.styles.scss";

const CultureFeatures = (props) => {
  const ref = useRef();
  gsap.registerPlugin(ScrollTrigger);
  const url = `${props.apiUrl}?populate[CultureFeatures][populate][0]=culture_features.Image`;
  const { loading, error, data } = useFetch(url);

  useLayoutEffect(() => {
    let ctx = null;
    if (data !== null) {
      ctx = gsap.context(() => {
        ScrollTrigger.create({
          trigger: ".culture-features__list",
          start: "top 80%",
          onEnter: function () {
            gsap.fromTo(
              ".culture-feature",
              { scale: 0, autoAlpha: 0 },
              {
                duration: 0.7,
                scale: 1,
                autoAlpha: 1,
                stagger: 0.2,
                ease: "sine.inOut",
                overwrite: "auto",
              }
            );
          },
          onLeaveBack: function () {
            gsap.fromTo(
              ".culture-feature",
              { autoAlpha: 1 },
              { autoAlpha: 0, overwrite: "auto" }
            );
          },
        });
        gsap
          .timeline({
            scrollTrigger: {
              trigger: ref.current,
              scrub: 0.2,
              start: "top 80%",
            },
          })
          .to(".culture-features__watermark", {
            rotation: 50,
            duration: 1,
            ease: "none",
          });
      }, ref);
    }
    return () => (ctx !== null ? ctx.revert() : null);
  }, [data]);

  if (loading) return <p>Loading ... </p>;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data.data.attributes) {
    const cultureData = data.data.attributes.CultureFeatures;
    const cultureList = cultureData.culture_features.data;

    return (
      <section ref={ref} className="culture-features">
        <div className="culture-features__background">
          <MediaQuery maxWidth={Breakpoints("tablet") - 1}>
            <Watermark className="culture-features__watermark" />
          </MediaQuery>
          <MediaQuery
            minWidth={Breakpoints("tablet")}
            maxWidth={Breakpoints("laptop") - 1}
          >
            <WatermarkTablet className="culture-features__watermark" />
          </MediaQuery>
          <MediaQuery
            minWidth={Breakpoints("laptop")}
            maxWidth={Breakpoints("desktop") - 1}
          >
            <WatermarkLaptop className="culture-features__watermark" />
          </MediaQuery>
          <MediaQuery minWidth={Breakpoints("desktop")}>
            <WatermarkDesktop className="culture-features__watermark" />
          </MediaQuery>
          <div className="culture-features__container">
            <TitleAccentLine title={cultureData.Title} theme="dark" />
            <div className="culture-features__list">
              {cultureList.map((feature) => (
                <CultureFeature
                  key={feature.id}
                  title={feature.attributes.Title}
                  image={feature.attributes.Image}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return <div ref={ref}>Data not returned</div>;
  }
};

export default CultureFeatures;
