import useFetch from "../../hooks/useFetch";
import { isAbsoluteUrl } from "../../utils/isAbsoluteUrl";
import MediaQuery from "react-responsive";
import { Breakpoints } from "../../utils/breakpoints";
import TitleAccentLine from "../title-accent-line/title-accent-line.component";

import { ReactComponent as UpArrow } from "../../img/background-decor/up-arrow-partial.svg";
import { ReactComponent as WatermarkTablet } from "../../img/background-decor/insights/watermark-tablet.svg";
import { ReactComponent as WatermarkLaptop } from "../../img/background-decor/insights/watermark-laptop.svg";
import { ReactComponent as WatermarkDesktop } from "../../img/background-decor/insights/watermark-desktop.svg";

import "./insights.styles.scss";

const Insights = (props) => {
  const url = `${props.apiUrl}?populate[Insights][populate][0]=Image`;
  const { loading, error, data } = useFetch(url);

  if (loading) return <p>Loading ... </p>;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }
  if (data.data.attributes) {
    const insights = data.data.attributes.Insights;
    const img = insights.Image.data.attributes;
    const imgUrl = isAbsoluteUrl(img.url)
      ? img.url
      : `${process.env.REACT_APP_API_BASE}${img.url}`;

    return (
      <section className="insights">
        <MediaQuery maxWidth={Breakpoints("tablet") - 1}>
          <UpArrow className="insights__up-arrow" />
        </MediaQuery>
        <MediaQuery
          minWidth={Breakpoints("tablet")}
          maxWidth={Breakpoints("laptop") - 1}
        >
          <WatermarkTablet className="insights__watermark" />
        </MediaQuery>
        <MediaQuery
          minWidth={Breakpoints("laptop")}
          maxWidth={Breakpoints("desktop") - 1}
        >
          <WatermarkLaptop className="insights__watermark" />
        </MediaQuery>
        <MediaQuery minWidth={Breakpoints("desktop")}>
          <WatermarkDesktop className="insights__watermark" />
        </MediaQuery>
        <div className="insights__container">
          <TitleAccentLine title={insights.Title} theme="dark" />
          <p className="insights__desc">{insights.Description}</p>
          <div className="insights__sub-container">
            <div className="insights__sub-info">
              <h3 className="insights__subtitle">{insights.Subtitle}</h3>
              <p className="insights__secondary-desc">
                {insights.SecondaryDescription}
              </p>
            </div>
            <img
              className="insights__image"
              alt={img.alternativeText}
              src={imgUrl}
            />
          </div>
        </div>
      </section>
    );
  } else {
    return <div>Data not returned</div>;
  }
};

export default Insights;
