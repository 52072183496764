import MediaQuery from "react-responsive";
import { Breakpoints } from "../../utils/breakpoints";

import "./title-accent-line.styles.scss";

const TitleAccentLine = (props) => {
  const classes = {
    line: `title-accent-line__line ${
      props.theme === "dark"
        ? "title-accent-line__line--dark"
        : "title-accent-line__line--light"
    }`,
    title: `title-accent-line__title ${
      props.theme === "dark"
        ? "title-accent-line__title--dark"
        : "title-accent-line__title--light"
    }`,
  };

  return (
    <section className="title-accent-line">
      {props.hideLine === undefined && <span className={classes.line}></span>}
      {props.hideLine && (
        <MediaQuery maxWidth={Breakpoints("laptop") - 1}>
          <span className={classes.line}></span>
        </MediaQuery>
      )}
      <h2 className={classes.title}>{props.title}</h2>
    </section>
  );
};

export default TitleAccentLine;
