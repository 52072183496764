import { Link } from "react-router-dom";
import { isAbsoluteUrl } from "../../utils/isAbsoluteUrl";
import { format } from "date-fns";
import Tag from "../tag/tag.component";

import "./blog-thumbnail.styles.scss";

const BlogThumbnail = (props) => {
  const img = props.thumbnail ? props.thumbnail : null;
  const imgUrl = img
    ? isAbsoluteUrl(img.url)
      ? img.url
      : `${process.env.REACT_APP_API_BASE}${img.url}`
    : null;

  const date = new Date(props.date);
  const formattedDate = format(date, "d MMM yyyy");

  return (
    <section className="blog-thumbnail">
      <Link className="blog-thumbnail__link" to={`/media/${props.slug}`}>
        <div
          className="blog-thumbnail__cover-img"
          style={
            imgUrl
              ? {
                  backgroundImage: `url(${imgUrl})`,
                }
              : {}
          }
        >
          <div className="blog-thumbnail__tag-list">
            {props.categories &&
              props.categories.map((tag) => (
                <Tag
                  key={tag.id}
                  title={tag.attributes.Name}
                  theme={tag.attributes.Color}
                />
              ))}
            {props.industry && (
              <Tag title={props.industry.Name} theme={props.industry.Color} />
            )}
          </div>
        </div>
      </Link>
      <div className="blog-thumbnail__container">
        <p className="blog-thumbnail__date">{formattedDate}</p>
        <Link className="blog-thumbnail__link" to={`/media/${props.slug}`}>
          <h3 className="blog-thumbnail__title">{props.title}</h3>
        </Link>
      </div>
    </section>
  );
};

export default BlogThumbnail;
