import useFetch from "../../hooks/useFetch";
import { isAbsoluteUrl } from "../../utils/isAbsoluteUrl";

import "./social-list.styles.scss";

const SocialList = (props) => {
  const logoName = props.useDark ? "LogoDark" : "Logo";

  const url = `${props.apiUrl}?${
    props.fields ? `${props.fields}&` : ""
  }populate[${props.cmsSection}][populate][0]=${props.cmsField}.${logoName}`;
  const { loading, error, data } = useFetch(url);

  if (loading) return <p>Loading ... </p>;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data.data.attributes) {
    const socialData = data.data.attributes[props.cmsSection];
    const socialList =
      props.cmsSection !== "Social"
        ? socialData.Social.socials.data
        : socialData.socials.data;
    const showTitle =
      props.hideTitle === undefined && socialData.Title !== null;

    return (
      <section
        className={`social-list ${props.useDark ? "social-list--dark" : ""} ${
          props.noSpacing ? "social-list--no-spacing" : ""
        }`}
      >
        {showTitle && (
          <h3 className="social-list__title">{socialData.Title}</h3>
        )}
        <div className="social-list__container">
          {socialList.map((social) => {
            const img = social.attributes[logoName].data.attributes;
            const imgUrl = isAbsoluteUrl(img.url)
              ? img.url
              : `${process.env.REACT_APP_API_BASE}${img.url}`;

            return (
              <a
                key={social.id}
                className="social-list__link"
                href={social.attributes.Link}
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  className="social-list__img"
                  alt={img.alternativeText}
                  src={imgUrl}
                />
              </a>
            );
          })}
        </div>
      </section>
    );
  } else {
    return <div>Data not returned</div>;
  }
};

export default SocialList;
