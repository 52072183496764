import { isAbsoluteUrl } from "../../utils/isAbsoluteUrl";
import MediaQuery from "react-responsive";
import { Breakpoints } from "../../utils/breakpoints";
import ReactPlayer from "react-player/lazy";
// import ReactPlayer from "react-player/vimeo";
// import Carousel from "../carousel/carousel.component";

import { ReactComponent as CrossVector } from "../../img/background-decor/repeating-vector.svg";
import { ReactComponent as Watermark } from "../../img/background-decor/hero-overlay/x-watermark.svg";
import { ReactComponent as WatermarkTablet } from "../../img/background-decor/hero-overlay/x-watermark-tablet.svg";
import { ReactComponent as WatermarkLaptop } from "../../img/background-decor/hero-overlay/x-watermark-laptop.svg";
import { ReactComponent as WatermarkDesktop } from "../../img/background-decor/hero-overlay/x-watermark-desktop.svg";
import { ReactComponent as NarrowWatermark } from "../../img/background-decor/hero-overlay/narrow-watermark.svg";
import { ReactComponent as NarrowWatermarkTablet } from "../../img/background-decor/hero-overlay/narrow-watermark-tablet.svg";
import { ReactComponent as NarrowWatermarkLaptop } from "../../img/background-decor/hero-overlay/narrow-watermark-laptop.svg";
import { ReactComponent as NarrowWatermarkDesktop } from "../../img/background-decor/hero-overlay/narrow-watermark-desktop.svg";

import "./hero-overlay.styles.scss";

const HeroOverlay = (props) => {
  const imageToUse = props.useVideo
    ? props.videoLinks[0].attributes.VideoThumbnail.data.attributes.url
    : props.media;
  const imgSrc = props.useVideo
    ? imageToUse
    : Array.isArray(props.media)
    ? props.media[0].attributes.url
    : props.media.attributes.url;
  const imgUrl = isAbsoluteUrl(imgSrc)
    ? imgSrc
    : `${process.env.REACT_APP_API_BASE}${imgSrc}`;

  const svgOverlay = (
    <>
      <CrossVector className="hero-overlay__cross-vector" />

      {!props.noWatermark && (
        <>
          <MediaQuery maxWidth={Breakpoints("tablet") - 1}>
            {props.narrowWatermark ? (
              <NarrowWatermark className="hero-overlay__watermark" />
            ) : (
              <Watermark className="hero-overlay__watermark" />
            )}
          </MediaQuery>
          <MediaQuery
            minWidth={Breakpoints("tablet")}
            maxWidth={Breakpoints("laptop") - 1}
          >
            {props.narrowWatermark ? (
              <NarrowWatermarkTablet className="hero-overlay__watermark" />
            ) : (
              <WatermarkTablet className="hero-overlay__watermark" />
            )}
          </MediaQuery>
          <MediaQuery
            minWidth={Breakpoints("laptop")}
            maxWidth={Breakpoints("desktop") - 1}
          >
            {props.narrowWatermark ? (
              <NarrowWatermarkLaptop className="hero-overlay__watermark" />
            ) : (
              <WatermarkLaptop className="hero-overlay__watermark" />
            )}
          </MediaQuery>
          <MediaQuery minWidth={Breakpoints("desktop")}>
            {props.narrowWatermark ? (
              <NarrowWatermarkDesktop className="hero-overlay__watermark" />
            ) : (
              <WatermarkDesktop className="hero-overlay__watermark" />
            )}
          </MediaQuery>
        </>
      )}
      <div className="hero-overlay__gradient" />
    </>
  );

  const bgImage = (
    <div
      className="hero-overlay__image-container"
      style={{
        backgroundImage: `url(${imgUrl})`,
      }}
    ></div>
  );

  const imageOverlay = (
    <>
      {bgImage}
      {svgOverlay}
    </>
  );

  const videoOverlay = props.useVideo ? (
    <>
      {bgImage}
      <ReactPlayer
        className="hero-overlay__video"
        url={props.videoLinks[0].attributes.Link}
        volume={0}
        muted={true}
        loop={true}
        playing={true}
        width="400vw"
        height="150vh"
        config={{
          vimeo: {
            autoplay: true,
            background: true,
            byline: false,
            controls: false,
            muted: true,
            responsive: true,
          },
        }}
        fallback={bgImage}
      />
      {svgOverlay}
    </>
  ) : (
    <></>
  );

  // TODO replace image with carousel
  // If not array, then set image source
  /* const imgSrc = !Array.isArray(props.media)
    ? props.media.attributes.url
    : null;
  const imgUrl = imgSrc
    ? isAbsoluteUrl(imgSrc)
      ? imgSrc
      : `${process.env.REACT_APP_API_BASE}${imgSrc}`
    : null;

  // If array, then create array of background image sources
  const heroBackgrounds = Array.isArray(props.media)
    ? props.media.map((background) => {
        const imgSrc = background.attributes.url;
        const imgUrl = isAbsoluteUrl(imgSrc)
          ? imgSrc
          : `${process.env.REACT_APP_API_BASE}${imgSrc}`; 

        return (
          <div key={background.id} className="hero-overlay__image-container">
            <img
              className="hero-overlay__image"
              alt={background.attributes.alternativeText}
              src={imgUrl}
            />
          </div>
        );
      })
    : null;*/

  return (
    <section className="hero-overlay">
      {!props.useVideo && imageOverlay}
      {props.useVideo && videoOverlay}

      {/* {heroBackgrounds && (
        <div className="hero-overlay__carousel-container">
          <Carousel slides={heroBackgrounds} slidesPerView={1} theme="light" />
        </div>
      )} */}
    </section>
  );
};

export default HeroOverlay;
