import { useLayoutEffect, useRef } from "react";
import useFetch from "../../hooks/useFetch";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import checkImagesLoaded from "../../utils/checkImagesLoaded";
import { isAbsoluteUrl } from "../../utils/isAbsoluteUrl";
import useScreenSize from "../../hooks/useScreenSize";
import Location from "../location/location.component";

import "./our-locations.styles.scss";

const OurLocations = (props) => {
  const ref = useRef();
  gsap.registerPlugin(ScrollTrigger);
  const url = `${props.apiUrl}?populate[OurLocations][populate][0]=locations&populate[OurLocations][populate][1]=cover_image.CoverImage&populate[OurLocations][populate][2]=cover_image.CoverImageTablet&populate[OurLocations][populate][3]=cover_image.CoverImageLaptop&populate[OurLocations][populate][4]=cover_image.CoverImageDesktop`;

  const { loading, error, data } = useFetch(url);

  useLayoutEffect(() => {
    let ctx = null;
    if (data !== null) {
      ctx = gsap.context(() => {
        ScrollTrigger.create({
          trigger: ".our-locations__list",
          start: "top 80%",
          onEnter: function () {
            gsap.fromTo(
              ".location",
              { scale: 0, autoAlpha: 0 },
              {
                duration: 0.7,
                scale: 1,
                autoAlpha: 1,
                stagger: 0.2,
                ease: "sine.inOut",
                overwrite: "auto",
              }
            );
          },
          onLeaveBack: function () {
            gsap.fromTo(
              ".location",
              { autoAlpha: 1 },
              { autoAlpha: 0, overwrite: "auto" }
            );
          },
        });

        const areImagesLoaded = checkImagesLoaded();
        areImagesLoaded.finally(() => {
          ScrollTrigger.refresh();
        });
      }, ref);
    }
    return () => {
      if (ctx !== null) {
        ctx.revert();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const screenSize = useScreenSize();

  if (loading) return <p>Loading ... </p>;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }
  if (data.data.attributes) {
    const locationsData = data.data.attributes.OurLocations;

    let coverImg;
    switch (screenSize) {
      case "mobile":
        coverImg =
          locationsData.cover_image.data.attributes.CoverImage.data.attributes;
        break;
      case "tablet":
        coverImg =
          locationsData.cover_image.data.attributes.CoverImageTablet.data
            .attributes;
        break;
      case "laptop":
        coverImg =
          locationsData.cover_image.data.attributes.CoverImageLaptop.data
            .attributes;
        break;
      default:
        coverImg =
          locationsData.cover_image.data.attributes.CoverImageDesktop.data
            .attributes;
        break;
    }

    const locationList = locationsData.locations.data;
    const imgUrl = isAbsoluteUrl(coverImg.url)
      ? coverImg.url
      : `${process.env.REACT_APP_API_BASE}${coverImg.url}`;

    return (
      <section ref={ref} className="our-locations">
        <img
          className="our-locations__image"
          alt={coverImg.alternativeText}
          src={imgUrl}
        />
        <div className="our-locations__container">
          <h2 className="our-locations__title">{props.title}</h2>
          <p className="our-locations__desc">{props.desc}</p>
          <div className="our-locations__list">
            {locationList.map((location) => (
              <Location
                key={location.id}
                name={location.attributes.Name}
                text={location.attributes.Text}
                fact={location.attributes.Fact}
              />
            ))}
          </div>
        </div>
      </section>
    );
  } else {
    return <div ref={ref}>Data not returned</div>;
  }
};

export default OurLocations;
