import useFetch from "../../hooks/useFetch";
import { isAbsoluteUrl } from "../../utils/isAbsoluteUrl";

import { ReactComponent as CrossVector } from "../../img/background-decor/repeating-vector.svg";

import "./content-over-image.styles.scss";

const ContentOverImage = (props) => {
  const url = `${props.apiUrl}?populate[ContentOverImage][populate][0]=BackgroundImage`;
  const { loading, error, data } = useFetch(url);

  if (loading) return <p>Loading ... </p>;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }
  if (data.data.attributes) {
    const coverImg =
      data.data.attributes.ContentOverImage.BackgroundImage.data.attributes;
    const imgUrl = isAbsoluteUrl(coverImg.url)
      ? coverImg.url
      : `${process.env.REACT_APP_API_BASE}${coverImg.url}`;

    return (
      <section className="content-over-image">
        <div
          className="content-over-image__coverImg"
          style={{
            backgroundImage: `url(${imgUrl})`,
          }}
        ></div>
        <CrossVector className="content-over-image__cross-vector" />
        <div className="content-over-image__container">
          <h2 className="content-over-image__title">{props.title}</h2>
          <p className="content-over-image__desc">{props.desc}</p>
        </div>
      </section>
    );
  } else {
    return <div>Data not returned</div>;
  }
};

export default ContentOverImage;
