import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import debounce from "lodash/debounce";

import "./footer-cta.styles.scss";

const FooterCta = (props) => {
  const [showSticky, setShowSticky] = useState(false);
  const [lastKnownScrollPosition, setLastKnownScrollPosition] = useState(0);
  const minimumScroll = 5;
  const scrollAmount = 600;

  const url = `${props.apiUrl}?populate[Cta][populate][0]=*`;
  const { loading, error, data } = useFetch(url);

  const scrollHandler = () => {
    const newScrollPosition = window.scrollY;
    // Make scroll more than minimumScroll
    if (
      Math.abs(lastKnownScrollPosition - newScrollPosition) <= minimumScroll
    ) {
      setLastKnownScrollPosition(newScrollPosition);
      return;
    }

    // If scrolled down and past the limit, add class for sticky
    if (
      newScrollPosition > lastKnownScrollPosition &&
      newScrollPosition > scrollAmount
    ) {
      setShowSticky(true);
    } else if (newScrollPosition <= scrollAmount) {
      // If above scroll amount, remove sticky
      setShowSticky(false);
    }

    setLastKnownScrollPosition(newScrollPosition);
  };

  useEffect(() => {
    const throttledScrollHandler = debounce(scrollHandler, 100);
    window.addEventListener("scroll", throttledScrollHandler);
    return () => window.removeEventListener("scroll", throttledScrollHandler);
    // This is because it's intent is to only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <p>Loading ... </p>;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data.data.attributes) {
    const cta = data.data.attributes.Cta;

    return (
      <>
        <section
          className={`footer-cta footer-cta--sticky ${
            showSticky ? "footer-cta--show" : ""
          }`}
        >
          <p className="footer-cta__desc">{cta.Text}</p>
          <Link className="footer-cta__link button" to={cta.LinkDestination}>
            {cta.LinkText}
          </Link>
        </section>

        <section
          className={`footer-cta ${showSticky ? "footer-cta--hide" : ""}`}
        >
          <p className="footer-cta__desc">{cta.Text}</p>
          <Link className="footer-cta__link button" to={cta.LinkDestination}>
            {cta.LinkText}
          </Link>
        </section>
      </>
    );
  } else {
    return <div>Data not returned</div>;
  }
};

export default FooterCta;
