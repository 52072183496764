import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import MediaQuery from "react-responsive";
import { Breakpoints } from "../../utils/breakpoints";

import { ReactComponent as Polygon } from "../../img/background-decor/polygon.svg";
import { ReactComponent as Topography } from "../../img/background-decor/value-list/topography.svg";
import { ReactComponent as TopographyTablet } from "../../img/background-decor/value-list/topography-tablet.svg";
import { ReactComponent as TopographyLaptop } from "../../img/background-decor/value-list/topography-laptop.svg";
import { ReactComponent as TopographyDesktop } from "../../img/background-decor/value-list/topography-desktop.svg";
import { ReactComponent as Watermark } from "../../img/background-decor/value-list/watermark.svg";
import { ReactComponent as WatermarkTablet } from "../../img/background-decor/value-list/watermark-tablet.svg";
import { ReactComponent as WatermarkLaptop } from "../../img/background-decor/value-list/watermark-laptop.svg";
import { ReactComponent as WatermarkDesktop } from "../../img/background-decor/value-list/watermark-desktop.svg";

import "./value-list.styles.scss";

const ValueList = (props) => {
  const ref = useRef();
  gsap.registerPlugin(ScrollTrigger);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, 1000);
  }, []);

  useLayoutEffect(() => {
    let ctx = null;
    if (mounted) {
      ctx = gsap.context(() => {
        ScrollTrigger.create({
          trigger: ".value-list__content",
          start: "top 80%",
          onEnter: function () {
            gsap.fromTo(
              ".value-list__value",
              { scale: 0, y: 40, autoAlpha: 0 },
              {
                duration: 0.7,
                scale: 1,
                y: 0,
                autoAlpha: 1,
                stagger: {
                  each: 0.1,
                  from: "center",
                },
                ease: "sine.inOut",
                overwrite: "auto",
              }
            );
          },
          onLeaveBack: function () {
            gsap.fromTo(
              ".value-list__value",
              { autoAlpha: 1 },
              { autoAlpha: 0, overwrite: "auto" }
            );
          },
        });
      }, ref);
    }
    return () => (ctx !== null ? ctx.revert() : null);
  }, [mounted]);

  return (
    <section ref={ref} className="value-list">
      <div className="value-list__container">
        <MediaQuery maxWidth={Breakpoints("tablet") - 1}>
          <Watermark className="value-list__watermark" />
          <Topography className="value-list__topography" />
        </MediaQuery>
        <MediaQuery
          minWidth={Breakpoints("tablet")}
          maxWidth={Breakpoints("laptop") - 1}
        >
          <WatermarkTablet className="value-list__watermark" />
          <TopographyTablet className="value-list__topography" />
        </MediaQuery>
        <MediaQuery
          minWidth={Breakpoints("laptop")}
          maxWidth={Breakpoints("desktop") - 1}
        >
          <WatermarkLaptop className="value-list__watermark" />
          <TopographyLaptop className="value-list__topography" />
        </MediaQuery>
        <MediaQuery minWidth={Breakpoints("desktop")}>
          <WatermarkDesktop className="value-list__watermark" />
          <TopographyDesktop className="value-list__topography" />
        </MediaQuery>

        <div className="value-list__content">
          {props.list.map((value) => (
            <p key={value.id} className="value-list__value">
              {value.attributes.Name}
            </p>
          ))}
        </div>
      </div>
      <Polygon className="value-list__polygon" />
    </section>
  );
};

export default ValueList;
