import React, { Component } from "react";
import { Link } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import noScroll from "../../utils/noScroll";
import NavBarHamburger from "../nav-bar-hamburger/nav-bar-hamburger.component";
import NavBarLink from "../nav-bar-link/nav-bar-link.component";

import { ReactComponent as Logo } from "../../img/logo/xpoint-logo-vertical.svg";
import { ReactComponent as LogoDesktop } from "../../img/logo/xpoint-logo-horizontal.svg";
import { ReactComponent as Watermark } from "../../img/background-decor/nav-bar/x-watermark-solid.svg";

import "./nav-bar.styles.scss";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayNav: false,
      loading: true,
      error: false,
      data: false,
    };
  }

  componentDidMount() {
    const linksUrl = `${process.env.REACT_APP_API_BASE}/api/navigation-bar?populate[navigation_bar_links][populate][0]=navigation_bar_sub_links`;

    trackPromise(
      fetch(linksUrl, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({
            data: response,
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({ loading: false, error: err });
          console.log(err);
        })
    );
  }

  handleHamburgerToggle = (displayNav) => {
    if (displayNav === true) {
      noScroll.add();
    } else {
      noScroll.remove();
    }

    this.setState({
      displayNav: displayNav,
    });
  };

  render() {
    if (this.state.loading) return <p>Loading ... </p>;
    if (this.state.error) return <p>Error ⛔</p>;

    if (this.state.data) {
      const result = this.state.data.data;
      const navLinks = result.attributes.navigation_bar_links.data;

      return (
        <nav
          className={`nav-bar ${
            this.state.displayNav ? "nav-bar--open" : ""
          } nav-bar--${this.props.theme}`}
        >
          {!this.props.useDesktopNav && (
            <NavBarHamburger
              handleToggle={this.handleHamburgerToggle}
              theme={this.props.theme}
              parentDisplayState={this.state.displayNav}
            />
          )}
          <div
            className={`nav-bar__list-container ${
              this.state.displayNav ? "show" : ""
            }`}
          >
            {!this.props.useDesktopNav && (
              <Watermark className="nav-bar__mobile-overlay" />
            )}
            <ul
              className={`nav-bar__list ${
                this.props.useDesktopNav ? "nav-bar__list--desktop" : ""
              }`}
            >
              {!this.props.useDesktopNav && (
                <NavBarLink
                  linkText="Home"
                  linkDestination="/"
                  theme={this.props.theme}
                  handleToggle={this.handleHamburgerToggle}
                />
              )}
              {navLinks.map((link) => {
                const subLinks = link.attributes.navigation_bar_sub_links.data;

                return (
                  <NavBarLink
                    key={link.id}
                    linkText={link.attributes.LinkText}
                    linkDestination={link.attributes.LinkDestination}
                    isDesktopNav={this.props.useDesktopNav}
                    subLinks={subLinks}
                    theme={this.props.theme}
                    handleToggle={this.handleHamburgerToggle}
                  />
                );
              })}
              {!this.props.useDesktopNav && (
                <NavBarLink
                  desktopCta="true"
                  linkText={result.attributes.DesktopCtaText}
                  linkDestination={result.attributes.DesktopCtaDestination}
                />
              )}
            </ul>
          </div>
          <Link className="nav-bar__logo-link" to="/">
            {!this.props.useDesktopNav ? (
              <Logo className="nav-bar__logo" />
            ) : (
              <LogoDesktop className="nav-bar__logo" />
            )}
          </Link>
          {!this.props.useDesktopNav ? (
            <Link
              className="nav-bar__cta"
              to={result.attributes.MobileCtaDestination}
            >
              {result.attributes.MobileCtaText}
            </Link>
          ) : (
            <a
              className="nav-bar__cta"
              target="_blank"
              href={result.attributes.DesktopCtaDestination}
              rel="noreferrer noopener"
            >
              {result.attributes.DesktopCtaText}
            </a>
          )}
        </nav>
      );
    } else {
      return <div>Data not returned</div>;
    }
  }
}

export default NavBar;
