import { useLayoutEffect, useRef } from "react";
import useFetch from "../../hooks/useFetch";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import useScreenSize from "../../hooks/useScreenSize";
import JobThumbnail from "../job-thumbnail/job-thumbnail.component";
import TitleAccentLine from "../title-accent-line/title-accent-line.component";

import "./related-jobs.styles.scss";

const RelatedJobs = (props) => {
  const ref = useRef();
  gsap.registerPlugin(ScrollTrigger);
  const screenSize = useScreenSize();
  const url = `${props.apiUrl}?fields[0]=Slug&populate[RelatedJobs][populate][0]=job_posts.Hero.CoverImage&populate[RelatedJobs][populate][1]=job_posts.job_tags`;
  const { loading, error, data } = useFetch(url);

  useLayoutEffect(() => {
    let ctx = null;
    if (data !== null) {
      ctx = gsap.context(() => {
        ScrollTrigger.create({
          trigger: ".related-jobs__container",
          start: "top 80%",
          onEnter: function () {
            gsap.fromTo(
              ".job-thumbnail",
              { scale: 0, autoAlpha: 0 },
              {
                duration: 0.7,
                scale: 1,
                autoAlpha: 1,
                stagger: 0.2,
                ease: "sine.inOut",
                overwrite: "auto",
              }
            );
          },
          onLeaveBack: function () {
            gsap.fromTo(
              ".job-thumbnail",
              { autoAlpha: 1 },
              { autoAlpha: 0, overwrite: "auto" }
            );
          },
        });

        if (screenSize === "laptop" || screenSize === "desktop") {
          const thumbnails = gsap.utils.toArray(".job-thumbnail");
          thumbnails.forEach((thumbnail) => {
            const animation = gsap.to(thumbnail, {
              paused: true,
              top: -5,
              duration: 0.25,
            });

            thumbnail.addEventListener("mouseenter", () => animation.play());
            thumbnail.addEventListener("mouseleave", () => animation.reverse());
          });
        }
      }, ref);
    }
    return () => {
      if (ctx !== null) {
        ctx.revert();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) return <p>Loading ... </p>;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data.data.attributes) {
    const relatedJobs = data.data.attributes.RelatedJobs.job_posts.data;

    return relatedJobs.length > 0 ? (
      <section ref={ref} className="related-jobs">
        <TitleAccentLine title={props.title} theme="dark" />
        <div className="related-jobs__container">
          {relatedJobs.map((job) => {
            const tags = job.attributes.job_tags.data
              ? job.attributes.job_tags.data
              : null;
            const thumbnail =
              job.attributes.Hero.CoverImage.data &&
              job.attributes.Hero.CoverImage.data.attributes
                ? job.attributes.Hero.CoverImage.data.attributes
                : null;
            return (
              <JobThumbnail
                key={job.id}
                title={job.attributes.Hero.PageTitle}
                desc={job.attributes.ShortDescription}
                tags={tags}
                cover={thumbnail}
                slug={job.attributes.Slug}
              />
            );
          })}
        </div>
      </section>
    ) : (
      <section
        ref={ref}
        className="related-jobs related-jobs--no-posts"
      ></section>
    );
  } else {
    return <div ref={ref}>Data not returned</div>;
  }
};

export default RelatedJobs;
