import MaskBottom from "../mask-bottom/mask-bottom.component";

import { ReactComponent as CrossVector } from "../../img/background-decor/cross-vector-diamond.svg";

import "./highlighted-info.styles.scss";

const HighlightedInfo = (props) => {
  return (
    <section className="highlighted-info">
      <div className="highlighted-info__bg-container">
        <CrossVector className="highlighted-info__cross-vector highlighted-info__cross-vector--left" />
        <CrossVector className="highlighted-info__cross-vector highlighted-info__cross-vector--right" />
      </div>
      <div className="highlighted-info__container">
        <div className="highlighted-info__content-container">
          <h2 className="highlighted-info__title">{props.title}</h2>
          <p className="highlighted-info__desc">{props.desc}</p>
        </div>
      </div>
      <MaskBottom invert={true} />
    </section>
  );
};

export default HighlightedInfo;
