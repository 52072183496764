import { useLayoutEffect, useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import "./decode-title.styles.scss";

const DecodeTitle = (props) => {
  const ref = useRef();
  gsap.registerPlugin(ScrollTrigger);
  const [arrayTitle, setArrayTitle] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (props.useDecode) {
      // Only split title if decoding
      setArrayTitle(props.title.split(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let interval = null;
    if (props.useDecode && isVisible) {
      // While visible, decode on interval
      decodeText();

      interval = setInterval(() => {
        decodeText();
      }, 10000);
    } else if (interval !== null) {
      // Clear interval and transition classes
      clearInterval(interval);
      const text = ref.current;
      let i = 0,
        textLength = text.children.length;
      for (i; i < textLength; i++) {
        text.children[i].classList.remove("state-1", "state-2", "state-3");
      }
    }
    return () => (interval ? clearInterval(interval) : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useLayoutEffect(() => {
    let ctx = null;
    if (props.useDecode) {
      // Set decode while visible linked to scroll
      ctx = gsap.context(() => {
        ScrollTrigger.create({
          trigger: ref.current,
          start: "top 50%",
          end: "bottom 20%",
          onEnter: enterTitle,
          onLeave: exitTitle,
          onEnterBack: enterTitle,
          onLeaveBack: exitTitle,
        });

        setTimeout(() => {
          ScrollTrigger.refresh();
        }, 500);
      }, ref);
    }
    return () => (ctx !== null ? ctx.revert() : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.useDecode]);

  const enterTitle = () => {
    setIsVisible(true);
  };

  const exitTitle = () => {
    setIsVisible(false);
  };

  const decodeText = () => {
    const text = ref.current;

    // Assign the placeholder array its places
    const state = [];
    let i = 0,
      textLength = text.children.length;
    for (i; i < textLength; i++) {
      text.children[i].classList.remove("state-1", "state-2", "state-3");
      state[i] = i;
    }

    // Shuffle the array to get new sequences each time
    const shuffled = shuffleArray(state);
    let j = 0,
      shuffledLength = shuffled.length;

    for (j; j < shuffledLength; j++) {
      const child = text.children[shuffled[j]];
      const classes = child.classList;

      // Fire the first one at random times
      const state1Time = Math.round(Math.random() * (2000 - 300)) + 50;
      if (classes.contains("decode-title__character")) {
        setTimeout(firstStages.bind(null, child), state1Time);
      }
    }
  };

  // Send the node for later .state changes
  const firstStages = (child) => {
    if (child.classList.contains("state-2")) {
      child.classList.add("state-3");
    } else if (child.classList.contains("state-1")) {
      child.classList.add("state-2");
    } else if (!child.classList.contains("state-1")) {
      child.classList.add("state-1");
      setTimeout(secondStages.bind(null, child), 100);
    }
  };

  const secondStages = (child) => {
    if (child.classList.contains("state-1")) {
      child.classList.add("state-2");
      setTimeout(thirdStages.bind(null, child), 100);
    } else if (!child.classList.contains("state-1")) {
      child.classList.add("state-1");
    }
  };

  const thirdStages = (child) => {
    if (child.classList.contains("state-2")) {
      child.classList.add("state-3");
    }
  };

  const shuffleArray = (array) => {
    let currentIndex = array.length,
      temporaryValue = null,
      randomIndex = null;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  };

  return (
    <section
      className={`decode-title ${
        props.isAltDesign ? "decode-title--alt" : ""
      } ${props.isProductDesign ? "decode-title--product" : ""}`}
    >
      <h2 ref={ref} className="decode-title__title">
        {props.useDecode
          ? arrayTitle.map((character, index) => (
              // TODO fix wordbreak issue caused by characters being wrapped
              <div
                key={index}
                className={`${
                  character === " "
                    ? "decode-title__space"
                    : "decode-title__character state-3"
                }`}
              >
                {character}
              </div>
            ))
          : props.title}
      </h2>
    </section>
  );
};

export default DecodeTitle;
