import React from "react";

import { ReactComponent as Diamond } from "../../img/background-decor/cross-vector-diamond.svg";

import "./featured-title-diamond.styles.scss";

const FeaturedTitleDiamond = React.forwardRef((props, ref) => {
  return (
    <section
      ref={ref}
      className={`featured-title-diamond ${
        props.useParallax ? "featured-title-diamond--using-parallax" : ""
      }`}
    >
      <Diamond className="featured-title-diamond__vector featured-title-diamond__vector--left" />
      <Diamond className="featured-title-diamond__vector featured-title-diamond__vector--right" />
    </section>
  );
});

export default FeaturedTitleDiamond;
