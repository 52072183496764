import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import "./subscribe-cta.styles.scss";

const SubscribeCta = (props) => {
  const token = "recaptcha token";
  const [wasSubmit, setWasSubmit] = useState(false);
  const [error, setError] = useState(false);

  return (
    <section className="subscribe-cta">
      <div className="subscribe-cta__container">
        <h2 className="subscribe-cta__title">
          {!wasSubmit ? props.title : props.sentTitle}
        </h2>
        {!wasSubmit && <p className="subscribe-cta__desc">{props.desc}</p>}

        <Formik
          initialValues={{ email: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "An email address is required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            fetch(`${process.env.REACT_APP_API_BASE}/api/ezforms/submit`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                token: token,
                formName: "Subscribe CTA",
                formData: values,
              }),
            })
              .then((res) => {
                // Successfully posted
                console.log("Success");
              })
              .catch((error) => {
                // error.response.status Check status code
                console.error("Error:", error);
                setError(error);
              })
              .finally(() => {
                setSubmitting(false);
                setWasSubmit(true);
              });
          }}
        >
          {({ isSubmitting }) =>
            !wasSubmit ? (
              <Form className="subscribe-cta__form">
                <Field
                  type="email"
                  name="email"
                  placeholder={props.placeholder}
                />
                <ErrorMessage name="email" component="div" />
                <button
                  className="subscribe-cta__button"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {props.buttonText}
                </button>
              </Form>
            ) : (
              <div className="subscribe-cta__message">
                {error ? (
                  <p className="subscribe-cta__error">{props.errorMessage}</p>
                ) : (
                  <p className="subscribe-cta__success">{props.sentMessage}</p>
                )}
              </div>
            )
          }
        </Formik>
      </div>
    </section>
  );
};

export default SubscribeCta;
