import useFetch from "../../hooks/useFetch";
import InitialLoad from "../../components/initial-load/initial-load.component";
import LoadingIndicator from "../../components/loading-indicator/loading-indicator.component";
import AxisParallax from "../../components/axis-parallax/axis-parallax.component";
import TitleLinks from "../../components/title-links/title-links.component";
import TitleWithCTA from "../../components/title-with-cta/title-with-cta.component";
import SiteHeader from "../../components/site-header/site-header.component";

import "./industries.styles.scss";

const Industries = () => {
  const url = `${process.env.REACT_APP_API_BASE}/api/market-page`;
  const { loading, error, data } = useFetch(`${url}?populate=*`);

  if (loading) return <LoadingIndicator loading={true} />;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data.data.attributes) {
    const title = data.data.attributes.TitleWithCta;

    return (
      <section className="industries">
        <InitialLoad />
        <SiteHeader theme="dark" />
        <TitleWithCTA
          isHero={true}
          hasWatermark={true}
          title={title.Title}
          desc={title.Description}
          link={title.LinkDestination}
          linkText={title.LinkText}
        />
        <AxisParallax
          theme="dark"
          top="92px"
          topDesktop="134px"
          rightDesktop="25px"
        />

        <TitleLinks apiUrl={url} />
      </section>
    );
  } else {
    return <div>Data not returned</div>;
  }
};

export default Industries;
