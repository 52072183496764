import { useMediaQuery } from "react-responsive";
import { Breakpoints } from "../utils/breakpoints";

const useScreenSize = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints("tablet") - 1 });
  const isTablet = useMediaQuery({ minWidth: Breakpoints("tablet"), maxWidth: Breakpoints("laptop") - 1 });
  const isLaptop = useMediaQuery({ minWidth: Breakpoints("laptop"), maxWidth: Breakpoints("desktop") - 1 });
  const isDesktop = useMediaQuery({ minWidth: Breakpoints("desktop") });
  if (isMobile) return 'mobile';
  else if (isTablet) return 'tablet';
  else if (isLaptop) return 'laptop';
  else if (isDesktop) return 'desktop';
}

export default useScreenSize