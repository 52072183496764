import { isAbsoluteUrl } from "../../utils/isAbsoluteUrl";

import "./culture-feature.styles.scss";

const CultureFeature = (props) => {
  const img =
    props.image.data !== null ? props.image.data.attributes : undefined;
  const imgUrl = img
    ? isAbsoluteUrl(img.url)
      ? img.url
      : `${process.env.REACT_APP_API_BASE}${img.url}`
    : undefined;

  return (
    <section className="culture-feature">
      <div className="culture-feature__container">
        {img && (
          <img
            className="culture-feature__image"
            alt={img.alternativeText}
            src={imgUrl}
          />
        )}
        {img === undefined && (
          <div className="culture-feature__image-placeholder"></div>
        )}
        <h3 className="culture-feature__title">{props.title}</h3>
      </div>
    </section>
  );
};

export default CultureFeature;
