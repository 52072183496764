import SocialList from "../social-list/social-list.component";

import "./footer-media.styles.scss";

const FooterMedia = (props) => {
  return (
    <section className="footer-media">
      <SocialList
        apiUrl={props.apiUrl}
        cmsSection="Social"
        cmsField="socials"
      />
    </section>
  );
};

export default FooterMedia;
