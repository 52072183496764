import { isAbsoluteUrl } from "../../utils/isAbsoluteUrl";

import "./partner.styles.scss";

const Partner = (props) => {
  const img = props.logo.data.attributes;
  const imgUrl = isAbsoluteUrl(img.url)
    ? img.url
    : `${process.env.REACT_APP_API_BASE}${img.url}`;

  return (
    <section className="partner">
      <a
        className="partner__link"
        href={props.link}
        target="_blank"
        rel="noreferrer noopener"
      >
        <img className="partner__img" alt={img.alternativeText} src={imgUrl} />
      </a>
    </section>
  );
};

export default Partner;
