import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import MediaQuery from "react-responsive";
import { Breakpoints } from "../../utils/breakpoints";
import InitialLoad from "../../components/initial-load/initial-load.component";
import LoadingIndicator from "../../components/loading-indicator/loading-indicator.component";
import BlogContent from "../../components/blog-content/blog-content.component";
import Hero from "../../components/hero/hero.component";
import RelatedPosts from "../../components/related-posts/related-posts.component";
import SiteHeader from "../../components/site-header/site-header.component";
import SubscribeCta from "../../components/subscribe-cta/subscribe-cta.component";
import TableOfContents from "../../components/table-of-contents/table-of-contents.component";

import "./blog-post.styles.scss";

const BlogPost = () => {
  const { slug } = useParams();
  const initialUrl = `${process.env.REACT_APP_API_BASE}/api/blog-posts?filters[Slug][$eqi]=${slug}&populate=*`;
  const { loading, error, data } = useFetch(initialUrl);

  if (loading) return <LoadingIndicator loading={true} />;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data.data[0] && data.data[0].attributes) {
    const blogPost = data.data[0];
    const actualUrl = `${process.env.REACT_APP_API_BASE}/api/blog-posts/${blogPost.id}`;

    return (
      <section className="blog-post">
        <InitialLoad />
        <SiteHeader />
        <Hero noWatermark={true} noMask={true} apiUrl={actualUrl} />
        <div className="blog-post__container">
          <MediaQuery maxWidth={Breakpoints("desktop") - 1}>
            <div className="blog-post__content">
              <TableOfContents />
              <BlogContent
                title={blogPost.attributes.Title}
                content={blogPost.attributes.Content}
                date={blogPost.attributes.BlogDate}
                socialTitle={blogPost.attributes.Social.Title}
                apiUrl={actualUrl}
              />
            </div>
          </MediaQuery>
          <MediaQuery minWidth={Breakpoints("desktop")}>
            <div className="blog-post__toc">
              <TableOfContents />
            </div>
            <div className="blog-post__content">
              <BlogContent
                title={blogPost.attributes.Title}
                content={blogPost.attributes.Content}
                date={blogPost.attributes.BlogDate}
                socialTitle={blogPost.attributes.Social.Title}
                apiUrl={actualUrl}
              />
            </div>
          </MediaQuery>
          <div className="blog-post__cta">
            <SubscribeCta
              title={blogPost.attributes.SubscribeCta.Title}
              sentTitle={blogPost.attributes.SubscribeCta.SentTitle}
              desc={blogPost.attributes.SubscribeCta.Description}
              placeholder={blogPost.attributes.SubscribeCta.EmailPlaceholder}
              buttonText={blogPost.attributes.SubscribeCta.ButtonText}
              errorMessage={blogPost.attributes.SubscribeCta.ErrorMessage}
              sentMessage={blogPost.attributes.SubscribeCta.SentMessage}
            />
          </div>
        </div>
        <div className="blog-post__related">
          <RelatedPosts
            title={blogPost.attributes.RelatedPosts.Title}
            apiUrl={actualUrl}
          />
        </div>
      </section>
    );
  } else {
    return <div>Data not returned</div>;
  }
};

export default BlogPost;
