// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-media {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 24px;
  margin-bottom: 24px;
  border-top: 1px solid #4a4a4a;
}
@media screen and (min-width: 1024px) {
  .footer-media {
    order: 2;
    padding-top: 0;
    margin-bottom: 0;
    border-top: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/footer-media/footer-media.styles.scss","webpack://./src/styles/tools/media-queries/_above.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,iBAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,6BAAA;AAFF;ACFE;EDFF;IASI,QAAA;IACA,cAAA;IACA,gBAAA;IACA,gBAAA;EADF;AACF","sourcesContent":["@import \"../../styles/tools/media-queries/above\";\n@import \"../../styles/core/scss-variables\";\n\n.footer-media {\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  padding-top: 24px;\n  margin-bottom: 24px;\n  border-top: 1px solid #4a4a4a;\n\n  @include above(laptop) {\n    order: 2;\n    padding-top: 0;\n    margin-bottom: 0;\n    border-top: none;\n  }\n}\n","@import \"../functions/breakpoint-value\";\n\n@mixin above($key) {\n  $min-width: breakpoint-value($key);\n\n  @media screen and (min-width: #{$min-width}) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
