import { Link } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";

import "./footer-sitemap.styles.scss";

const FOOTERSITEMAP = gql`
  query FooterSiteMap {
    sitemapCategories(sort: "onDisplayOrder") {
      data {
        id
        attributes {
          Name
          Link
          onDisplayOrder
          sitemap_links(sort: "onDisplayOrder") {
            data {
              id
              attributes {
                Name
                Link
                onDisplayOrder
              }
            }
          }
        }
      }
    }
  }
`;

const FooterSitemap = (props) => {
  const { loading, error, data } = useQuery(FOOTERSITEMAP);

  if (loading) return <p>Loading ... </p>;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data) {
    const categories = data.sitemapCategories.data;

    return (
      <section className="footer-sitemap">
        <div className="footer-sitemap__container">
          {categories.map((category) => {
            const categoryBlock = category.attributes.Link ? (
              <Link
                className="footer-sitemap__link footer-sitemap__link--category"
                to={category.attributes.Link}
              >
                <span className="footer-sitemap__title">
                  {category.attributes.Name}
                </span>
              </Link>
            ) : (
              <span className="footer-sitemap__title">
                {category.attributes.Name}
              </span>
            );
            const sitemapLinks = category.attributes.sitemap_links.data;

            return (
              <div key={category.id} className="footer-sitemap__category">
                {categoryBlock}
                <ul className="footer-sitemap__list">
                  {sitemapLinks.map((link) => (
                    <li key={link.id} className="footer-sitemap__list-item">
                      <Link
                        className="footer-sitemap__link"
                        to={link.attributes.Link}
                      >
                        {link.attributes.Name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
      </section>
    );
  } else {
    return <div>Data not returned</div>;
  }
};

export default FooterSitemap;
