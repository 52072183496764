import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";

import LoadingGif from "../../img/icon/icon-loading.gif";

import "./initial-load.styles.scss";

class InitialLoad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      start: true,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1500);
  }

  render() {
    return (
      this.state.loading && (
        <CSSTransition in={this.state.start} classNames="loading" timeout={500}>
          <section className="initial-load">
            <div className="initial-load__container">
              <img
                src={LoadingGif}
                className="initial-load__gif"
                alt="loading animation..."
              />
            </div>
          </section>
        </CSSTransition>
      )
    );
  }
}

export default InitialLoad;
