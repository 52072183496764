import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { ReactComponent as Chevron } from "../../img/background-decor/nav-bar-link/chevron.svg";

import "./nav-bar-link.styles.scss";

const NavBarLink = (props) => {
  const [showChild, setShowChild] = useState(false);
  const hasChildren = props.subLinks && props.subLinks.length >= 1;
  const currentPathname = useLocation().pathname;
  const isActivePage = props.linkDestination === currentPathname;

  const listClass = `nav-bar-link ${
    props.desktopCta ? "nav-bar-link--cta" : ""
  } ${hasChildren ? "nav-bar-link--has-children" : ""}`;
  const linkClass = `nav-bar-link__url ${
    isActivePage ? "nav-bar-link__url--active" : ""
  } ${
    hasChildren ? "nav-bar-link__url--has-children" : ""
  } nav-bar-link__url--${props.theme}`;

  const toggleMenu = () => {
    props.handleToggle(false);
  };

  const handleMenuClick = () => {
    setShowChild(!showChild);
  };

  return (
    <li className={listClass}>
      {props.desktopCta === undefined && (
        <>
          {/* The dummy is used to replicate the space, without the absolute position so that hover change of font does not shift everything */}
          {props.isDesktopNav && (
            <div className="nav-bar-link__dummy">{props.linkText}</div>
          )}
          {isActivePage && !props.isDesktopNav ? (
            <button className={linkClass} onClick={toggleMenu}>
              {props.linkText}
            </button>
          ) : (
            <Link className={linkClass} to={props.linkDestination}>
              {props.linkText}
            </Link>
          )}
          {hasChildren && (
            <>
              <div
                className={`nav-bar-link__chevron-container ${
                  showChild
                    ? "nav-bar-link__chevron-container--mobile-hide"
                    : ""
                }`}
                onClick={handleMenuClick}
              >
                <Chevron
                  className={`nav-bar-link__chevron nav-bar-link__chevron--${props.theme}`}
                />
              </div>
              <div
                className={`nav-bar-link__sub-container ${
                  showChild ? "nav-bar-link__sub-container--show" : ""
                } nav-bar-link__sub-container--${props.theme}`}
                onMouseLeave={() => setShowChild(false)}
              >
                <ul className="nav-bar-link__sub-list">
                  {props.subLinks.map((child) => (
                    <li key={child.id} className="nav-bar-link__sub-link">
                      {props.isDesktopNav && (
                        <div className="nav-bar-link__dummy">
                          {child.attributes.LinkText}
                        </div>
                      )}
                      <Link
                        className={`nav-bar-link__url nav-bar-link__url--sub nav-bar-link__url--${props.theme}`}
                        to={child.attributes.LinkDestination}
                      >
                        {child.attributes.LinkText}
                      </Link>
                    </li>
                  ))}
                </ul>
                {!props.isDesktopNav && (
                  <div
                    className="nav-bar-link__chevron-container nav-bar-link__chevron-container--invert"
                    onClick={handleMenuClick}
                  >
                    <Chevron
                      className={`nav-bar-link__chevron nav-bar-link__chevron--invert nav-bar-link__chevron--${props.theme}`}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
      {props.desktopCta && (
        <a
          className="nav-bar-link__url"
          href={props.linkDestination}
          target="_blank"
          rel="noreferrer noopener"
        >
          {props.linkText}
        </a>
      )}
    </li>
  );
};

export default NavBarLink;
