const add = () => {
  document.body.classList.add('no-scroll');
}

const remove = () => {
  document.body.classList.remove('no-scroll');
}

const toggle = () => {
  document.body.classList.toggle('no-scroll');
}

const noScroll = {
  add,
  remove,
  toggle
}

export default noScroll;
