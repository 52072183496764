import useFetch from "../../hooks/useFetch";
import InitialLoad from "../../components/initial-load/initial-load.component";
import LoadingIndicator from "../../components/loading-indicator/loading-indicator.component";
import ContactCTA from "../../components/contact-cta/contact-cta.component";
import FeaturedPages from "../../components/featured-pages/featured-pages.component";
import Hero from "../../components/hero/hero.component";
import InfoPanelGroup from "../../components/info-panel-group/info-panel-group.component";
import SiteHeader from "../../components/site-header/site-header.component";
import TitleWithCTA from "../../components/title-with-cta/title-with-cta.component";

import "./about-us.styles.scss";
const AboutUs = () => {
  const url = `${process.env.REACT_APP_API_BASE}/api/about-us`;
  const { loading, error, data } = useFetch(`${url}?populate=*`);

  if (loading) return <LoadingIndicator loading={true} />;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data.data.attributes) {
    const hero = data.data.attributes.Hero;
    const title = data.data.attributes.Title;
    const contactCTA = data.data.attributes.ContactCta;

    return (
      <section className="about-us">
        <InitialLoad />
        <SiteHeader />
        <Hero title={hero.PageTitle} useVideo={hero.UseVideo} apiUrl={url} />
        <TitleWithCTA
          title={title.Title}
          desc={title.Description}
          link={title.LinkDestination}
          linkText={title.LinkText}
        />
        <FeaturedPages apiUrl={url} />
        <InfoPanelGroup apiUrl={url} />
        <ContactCTA
          title={contactCTA.Title}
          link={contactCTA.LinkDestination}
          linkText={contactCTA.LinkText}
          useParallaxEffect={
            contactCTA.UseParallaxEffect !== null
              ? contactCTA.UseParallaxEffect
              : false
          }
          apiUrl={url}
        />
      </section>
    );
  } else {
    return <div>Data not returned</div>;
  }
};

export default AboutUs;
