import { Link } from "react-router-dom";
import { isAbsoluteUrl } from "../../utils/isAbsoluteUrl";
import MediaQuery from "react-responsive";
import { Breakpoints } from "../../utils/breakpoints";
import RteContent from "../rte-content/rte-content.component";

import "./product.styles.scss";

const Product = (props) => {
  const img = props.logo.data.attributes;
  const imgUrl = isAbsoluteUrl(img.url)
    ? img.url
    : `${process.env.REACT_APP_API_BASE}${img.url}`;

  return (
    <section
      className={`product ${
        props.theme !== undefined ? `product--${props.theme}` : ""
      }`}
    >
      <div className="product__top-section">
        <img className="product__logo" alt={img.alternativeText} src={imgUrl} />
        <p className="product__desc">{props.desc}</p>
        {props.additionalInfo && (
          <MediaQuery minWidth={Breakpoints("laptop")}>
            <RteContent content={props.additionalInfo} noSpacing={true} />
          </MediaQuery>
        )}
      </div>
      <Link
        className={`product__link button ${
          props.theme !== undefined ? `button--${props.theme}` : ""
        }`}
        to={props.link}
      >
        {props.linkText}
      </Link>
    </section>
  );
};

export default Product;
