import { useLayoutEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import MediaQuery from "react-responsive";
import { Breakpoints } from "../../utils/breakpoints";

import { ReactComponent as WatermarkNegative } from "../../img/background-decor/title-with-cta/x-negative-watermark.svg";
import { ReactComponent as WatermarkNegativeTablet } from "../../img/background-decor/title-with-cta/x-negative-watermark-tablet.svg";
import { ReactComponent as WatermarkNegativeLaptop } from "../../img/background-decor/title-with-cta/x-negative-watermark-laptop.svg";
import { ReactComponent as WatermarkNegativeDesktop } from "../../img/background-decor/title-with-cta/x-negative-watermark-desktop.svg";
import { ReactComponent as Diamond } from "../../img/background-decor/title-with-cta/diamond-gradient.svg";

import "./title-with-cta.styles.scss";

const TitleWithCTA = (props) => {
  const ref = useRef();
  gsap.registerPlugin(ScrollTrigger);

  useLayoutEffect(() => {
    let ctx = null;
    if (props.isHero) {
      ctx = gsap.context(() => {
        gsap.fromTo(
          ".title-with-cta__container",
          { y: 100, autoAlpha: 0 },
          {
            delay: 1.5,
            duration: 1.25,
            y: 0,
            autoAlpha: 1,
            ease: "back",
            overwrite: "auto",
            scrollTrigger: {
              trigger: ".title-with-cta__container",
              start: "top 80%",
              end: "bottom 20%",
              onLeave: function () {
                gsap.fromTo(
                  ".title-with-cta__container",
                  { autoAlpha: 1 },
                  { autoAlpha: 0, overwrite: "auto" }
                );
              },
              onEnterBack: function () {
                gsap.fromTo(
                  ".title-with-cta__container",
                  { autoAlpha: 0 },
                  {
                    duration: 1.25,
                    autoAlpha: 1,
                    ease: "back",
                    overwrite: "auto",
                  }
                );
              },
              onLeaveBack: function () {
                gsap.fromTo(
                  ".title-with-cta__container",
                  { autoAlpha: 1 },
                  { autoAlpha: 0, overwrite: "auto" }
                );
              },
            },
          }
        );
      }, ref);
    }
    return () => (ctx !== null ? ctx.revert() : null);
  }, [props.isHero]);

  return (
    <section
      ref={ref}
      className={`title-with-cta ${
        props.isHero ? "title-with-cta--hero" : ""
      } ${props.hasWatermark ? "title-with-cta--watermark" : ""}`}
    >
      {props.hasWatermark && (
        <div className="title-with-cta__watermark-container">
          <MediaQuery maxWidth={Breakpoints("tablet") - 1}>
            <WatermarkNegative className="title-with-cta__watermark" />
          </MediaQuery>
          <MediaQuery
            minWidth={Breakpoints("tablet")}
            maxWidth={Breakpoints("laptop") - 1}
          >
            <WatermarkNegativeTablet className="title-with-cta__watermark" />
          </MediaQuery>
          <MediaQuery
            minWidth={Breakpoints("laptop")}
            maxWidth={Breakpoints("desktop") - 1}
          >
            <WatermarkNegativeLaptop className="title-with-cta__watermark" />
          </MediaQuery>
          <MediaQuery minWidth={Breakpoints("desktop")}>
            <WatermarkNegativeDesktop className="title-with-cta__watermark" />
          </MediaQuery>
        </div>
      )}
      <div className="title-with-cta__container">
        {props.isHero && (
          <h1 className="title-with-cta__title title-with-cta__title--hero">
            {props.title}
          </h1>
        )}
        {!props.isHero && (
          <h2 className="title-with-cta__title">{props.title}</h2>
        )}
        <p className="title-with-cta__desc">{props.desc}</p>
        <Link
          className="title-with-cta__link button button--dark"
          to={props.link}
        >
          {props.linkText}
        </Link>
      </div>
      {props.hasWatermark && (
        <MediaQuery minWidth={Breakpoints("laptop")}>
          <Diamond className="title-with-cta__diamond" />
        </MediaQuery>
      )}
    </section>
  );
};

export default TitleWithCTA;
