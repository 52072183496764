import useFetch from "../../hooks/useFetch";
import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import AxisParallax from "../axis-parallax/axis-parallax.component";
import HeroMask from "../hero-mask/hero-mask.component";
import HeroOverlay from "../hero-overlay/hero-overlay.component";

import "./hero.styles.scss";

const Hero = (props) => {
  const ref = useRef();
  gsap.registerPlugin(ScrollTrigger);
  const url = `${props.apiUrl}?populate[Hero][populate][0]=CoverImage&populate[Hero][populate][1]=video_links.VideoThumbnail&populate[Hero][populate][2]=video_link.VideoThumbnail`;
  const { loading, error, data } = useFetch(url);

  useLayoutEffect(() => {
    let ctx = null;
    if (data !== null) {
      ctx = gsap.context(() => {
        const headline = ref.current.querySelector(".hero__headline");
        if (headline) {
          gsap.fromTo(
            headline,
            { y: 100, autoAlpha: 0 },
            {
              delay: 1.5,
              duration: 1.25,
              y: 0,
              autoAlpha: 1,
              ease: "back",
              overwrite: "auto",
              scrollTrigger: {
                trigger: headline,
                start: "top 80%",
                end: "bottom 20%",
                onLeave: function () {
                  gsap.fromTo(
                    headline,
                    { autoAlpha: 1 },
                    { autoAlpha: 0, overwrite: "auto" }
                  );
                },
                onEnterBack: function () {
                  gsap.fromTo(
                    headline,
                    { autoAlpha: 0 },
                    {
                      duration: 1.25,
                      autoAlpha: 1,
                      ease: "back",
                      overwrite: "auto",
                    }
                  );
                },
                onLeaveBack: function () {
                  gsap.fromTo(
                    headline,
                    { autoAlpha: 1 },
                    { autoAlpha: 0, overwrite: "auto" }
                  );
                },
              },
            }
          );
        }
      }, ref);
    }
    return () => (ctx !== null ? ctx.revert() : null);
  }, [data]);

  if (loading) return <p>Loading ... </p>;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data.data.attributes) {
    const coverImage = data.data.attributes.Hero.CoverImage.data;
    const videoLinks = data.data.attributes.Hero.video_links
      ? data.data.attributes.Hero.video_links.data
      : data.data.attributes.Hero.video_link
      ? data.data.attributes.Hero.video_link.data
      : null;

    return (
      <section ref={ref} className="hero">
        <div
          className={`hero__container ${
            props.useVideo ? "hero__container--video" : ""
          }`}
        >
          <HeroOverlay
            className="hero__overlay"
            media={coverImage}
            videoLinks={videoLinks}
            useVideo={props.useVideo}
            narrowWatermark={true}
            noWatermark={props.noWatermark !== undefined ? true : false}
          />
          <AxisParallax />

          {props.title && (
            <div className="hero__headline">
              <h1 className="hero__title">{props.title}</h1>
            </div>
          )}

          {!props.noMask && <HeroMask />}
        </div>
      </section>
    );
  } else {
    return <div ref={ref}>Data not returned</div>;
  }
};

export default Hero;
