import "./job-apply.styles.scss";

const JobApply = (props) => {
  if (props.application) {
    return (
      <section className="job-apply">
        {/* TODO change to use form */}
        <a
          className="job-apply__link button--dark"
          href={`mailto:${props.application.MailTo}?subject=Application for: ${props.jobName}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          {props.application.ButtonText}
        </a>
      </section>
    );
  } else {
    return <></>;
  }
};

export default JobApply;
