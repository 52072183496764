import { useLayoutEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import useFetch from "../../hooks/useFetch";
import { isAbsoluteUrl } from "../../utils/isAbsoluteUrl";

import { ReactComponent as CrossVector } from "../../img/background-decor/cross-vector-diamond.svg";

import "./featured-pages.styles.scss";

const FeaturedPages = (props) => {
  const ref = useRef();
  gsap.registerPlugin(ScrollTrigger);
  const url = `${props.apiUrl}?populate[FeaturedPages][populate][0]=page_links.CoverImage`;
  const { loading, error, data } = useFetch(url);

  useLayoutEffect(() => {
    let ctx = null;
    if (data !== null) {
      ctx = gsap.context(() => {
        ScrollTrigger.create({
          trigger: ref.current,
          start: "top 80%",
          onEnter: function () {
            gsap.fromTo(
              ".featured-pages__box",
              { scale: 0, autoAlpha: 0 },
              {
                duration: 0.7,
                scale: 1,
                autoAlpha: 1,
                stagger: {
                  each: 0.1,
                  from: "center",
                },
                ease: "sine.inOut",
                overwrite: "auto",
              }
            );
          },
          onLeaveBack: function () {
            gsap.fromTo(
              ".featured-pages__box",
              { autoAlpha: 1 },
              { autoAlpha: 0, overwrite: "auto" }
            );
          },
        });
      }, ref);
    }
    return () => (ctx !== null ? ctx.revert() : null);
  }, [data]);

  if (loading) return <p>Loading ... </p>;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data.data.attributes) {
    const pageLinks = data.data.attributes.FeaturedPages.page_links.data;

    return (
      <section ref={ref} className="featured-pages">
        <div className="featured-pages__container">
          <div className="featured-pages__box-list">
            {pageLinks.map((page) => {
              const img = page.attributes.CoverImage.data;
              const imgUrl = img
                ? isAbsoluteUrl(img.attributes.url)
                  ? img.attributes.url
                  : `${process.env.REACT_APP_API_BASE}${img.attributes.url}`
                : null;

              return (
                <div
                  key={page.id}
                  className={`featured-pages__box ${
                    imgUrl ? "featured-pages__box--has-image" : ""
                  }`}
                >
                  {imgUrl && (
                    <div
                      className="featured-pages__image"
                      style={
                        imgUrl
                          ? {
                              backgroundImage: `url(${imgUrl})`,
                            }
                          : {}
                      }
                    />
                  )}
                  <Link
                    className="featured-pages__link"
                    to={page.attributes.Link}
                  >
                    {page.attributes.Name}
                  </Link>
                </div>
              );
            })}
          </div>
          <CrossVector className="featured-pages__cross-vector featured-pages__cross-vector--left" />
          <CrossVector className="featured-pages__cross-vector featured-pages__cross-vector--right" />
        </div>
      </section>
    );
  } else {
    return <div ref={ref}>Data not returned</div>;
  }
};

export default FeaturedPages;
