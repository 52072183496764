// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.featured-title-triangle__vector {
  display: block;
  position: absolute;
  bottom: -50px;
  width: 50vw;
}
.featured-title-triangle__vector--right {
  right: 0;
  transform: scaleX(-1);
}
@media screen and (min-width: 1024px) {
  .featured-title-triangle__vector {
    width: 30vw;
  }
}
@media screen and (min-width: 1440px) {
  .featured-title-triangle__vector {
    width: 25vw;
  }
}
@media screen and (min-width: 1440px) {
  .featured-title-triangle--alt .featured-title-triangle__vector {
    bottom: 0;
  }
}
@media screen and (min-width: 1024px) {
  .featured-title-triangle--product .featured-title-triangle__vector {
    width: 47vw;
  }
}
@media screen and (min-width: 1440px) {
  .featured-title-triangle--product .featured-title-triangle__vector {
    width: 30vw;
  }
}
@media screen and (min-width: 1024px) {
  .featured-title-triangle--using-parallax .featured-title-triangle__vector {
    top: 100px;
  }
}
@media screen and (min-width: 1440px) {
  .featured-title-triangle--using-parallax .featured-title-triangle__vector {
    top: 150px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/featured-title-triangle/featured-title-triangle.styles.scss","webpack://./src/styles/tools/media-queries/_above.scss"],"names":[],"mappings":"AAKE;EACE,cAAA;EACA,kBAAA;EACA,aAAA;EACA,WAAA;AAJJ;AAMI;EACE,QAAA;EACA,qBAAA;AAJN;ACJE;EDAA;IAYI,WAAA;EAJJ;AACF;ACTE;EDAA;IAgBI,WAAA;EAHJ;AACF;ACdE;EDmBE;IAEI,SAAA;EAHN;AACF;ACnBE;EDyBE;IAEI,WAAA;EAJN;AACF;ACxBE;EDyBE;IAKI,WAAA;EAFN;AACF;AC7BE;EDkCE;IAEI,UAAA;EAHN;AACF;AClCE;EDkCE;IAKI,UAAA;EADN;AACF","sourcesContent":["@import \"../../styles/tools/media-queries/above\";\n\n.featured-title-triangle {\n  $block: &;\n\n  &__vector {\n    display: block;\n    position: absolute;\n    bottom: -50px;\n    width: 50vw;\n\n    &--right {\n      right: 0;\n      transform: scaleX(-1);\n    }\n\n    @include above(laptop) {\n      width: 30vw;\n    }\n\n    @include above(desktop) {\n      width: 25vw;\n    }\n\n    #{$block}--alt & {\n      @include above(desktop) {\n        bottom: 0;\n      }\n    }\n\n    #{$block}--product & {\n      @include above(laptop) {\n        width: 47vw;\n      }\n      @include above(desktop) {\n        width: 30vw;\n      }\n    }\n\n    #{$block}--using-parallax & {\n      @include above(laptop) {\n        top: 100px;\n      }\n      @include above(desktop) {\n        top: 150px;\n      }\n    }\n  }\n}\n","@import \"../functions/breakpoint-value\";\n\n@mixin above($key) {\n  $min-width: breakpoint-value($key);\n\n  @media screen and (min-width: #{$min-width}) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
