// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width: 768px) {
  .title-links--multiple {
    display: flex;
    flex-flow: row wrap;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/tools/media-queries/_above.scss","webpack://./src/components/title-links/title-links.styles.scss"],"names":[],"mappings":"AAKE;ECDA;IAEI,aAAA;IACA,mBAAA;EAHJ;AACF","sourcesContent":["@import \"../functions/breakpoint-value\";\n\n@mixin above($key) {\n  $min-width: breakpoint-value($key);\n\n  @media screen and (min-width: #{$min-width}) {\n    @content;\n  }\n}\n","@import \"../../styles/tools/media-queries/above\";\n@import \"../../styles/core/scss-variables\";\n\n.title-links {\n  &--multiple {\n    @include above(tablet) {\n      display: flex;\n      flex-flow: row wrap;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
