import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import useScreenSize from "../../hooks/useScreenSize";

import { ReactComponent as Axis } from "../../img/background-decor/hero-overlay/axis.svg";

import "./axis-parallax.styles.scss";

const AxisParallax = (props) => {
  const ref = useRef();
  gsap.registerPlugin(ScrollTrigger);
  const screenSize = useScreenSize();
  const display = props.onlyDesktop
    ? screenSize === "desktop"
    : screenSize === "laptop" || screenSize === "desktop";
  const scrollStart =
    screenSize === "laptop"
      ? props.top
        ? props.top
        : "114px"
      : props.topDesktop
      ? props.topDesktop
      : "64px";

  useLayoutEffect(() => {
    let ctx = null;
    if (display) {
      ctx = gsap.context(() => {
        if (props.isNotHero) {
          gsap.fromTo(
            ".axis-parallax__layer",
            { y: -100 },
            {
              y: 100,
              ease: "none",
              force3D: false,
              scrollTrigger: {
                trigger: ref.current.parentElement,
                scrub: props.offset ? props.offset : true,
                invalidateOnRefresh: true,
              },
            }
          );
        } else {
          gsap.to(".axis-parallax__layer", {
            y: 100,
            ease: "none",
            force3D: false,
            scrollTrigger: {
              trigger: ref.current.parentElement,
              start: `top ${scrollStart}`,
              scrub: props.offset ? props.offset : true,
              invalidateOnRefresh: true,
            },
          });
        }
      }, ref);
    }
    return () => (ctx !== null ? ctx.revert() : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [display]);

  return (
    <section ref={ref} className="axis-parallax">
      {display ? (
        <div
          className="axis-parallax__container"
          style={{
            "--topPos": props.top ? props.top : "114px",
            "--topDeskPos": props.topDesktop ? props.topDesktop : "64px",
            "--rightDeskPos": props.rightDesktop ? props.rightDesktop : "45px",
          }}
        >
          <Axis
            className={`axis-parallax__layer axis-parallax__layer--${
              props.theme ? props.theme : "light"
            }`}
          />
        </div>
      ) : (
        <div className="axis-parllax__only-on-desktop"></div>
      )}
    </section>
  );
};

export default AxisParallax;
