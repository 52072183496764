import useFetch from "../../hooks/useFetch";
import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import AxisParallax from "../axis-parallax/axis-parallax.component";
import HeroOverlay from "../hero-overlay/hero-overlay.component";
import Tag from "../tag/tag.component";

import "./job-hero.styles.scss";

const JobHero = (props) => {
  const ref = useRef();
  gsap.registerPlugin(ScrollTrigger);
  const url = `${props.apiUrl}?populate[Hero][populate][0]=CoverImage&populate[Hero][populate][1]=video_links.VideoThumbnail&populate[Hero][populate][2]=video_link.VideoThumbnail`;
  const { loading, error, data } = useFetch(url);

  useLayoutEffect(() => {
    let ctx = null;
    if (data !== null) {
      ctx = gsap.context(() => {
        gsap.fromTo(
          ".job-hero__headline",
          { y: 100, autoAlpha: 0 },
          {
            delay: 1.5,
            duration: 1.25,
            y: 0,
            autoAlpha: 1,
            ease: "back",
            overwrite: "auto",
            scrollTrigger: {
              trigger: ".job-hero__headline",
              start: "top 80%",
              end: "bottom 20%",
              onLeave: function () {
                gsap.fromTo(
                  ".job-hero__headline",
                  { autoAlpha: 1 },
                  { autoAlpha: 0, overwrite: "auto" }
                );
              },
              onEnterBack: function () {
                gsap.fromTo(
                  ".job-hero__headline",
                  { autoAlpha: 0 },
                  {
                    duration: 1.25,
                    autoAlpha: 1,
                    ease: "back",
                    overwrite: "auto",
                  }
                );
              },
              onLeaveBack: function () {
                gsap.fromTo(
                  ".job-hero__headline",
                  { autoAlpha: 1 },
                  { autoAlpha: 0, overwrite: "auto" }
                );
              },
            },
          }
        );
      }, ref);
    }
    return () => (ctx !== null ? ctx.revert() : null);
  }, [data]);

  if (loading) return <p>Loading ... </p>;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data.data.attributes) {
    const coverImage = data.data.attributes.Hero.CoverImage.data;
    const videoLinks = data.data.attributes.Hero.video_links
      ? data.data.attributes.Hero.video_links.data
      : data.data.attributes.Hero.video_link
      ? data.data.attributes.Hero.video_link.data
      : null;

    return (
      <section ref={ref} className="job-hero">
        <div
          className={`job-hero__container ${
            props.useVideo ? "job-hero__container--video" : ""
          }`}
        >
          <HeroOverlay
            className="job-hero__overlay"
            media={coverImage}
            videoLinks={videoLinks}
            noWatermark={true}
            useVideo={props.useVideo}
          />
          <AxisParallax />

          {props.title && (
            <div className="job-hero__headline">
              {props.tags && (
                <div className="job-hero__tag-list">
                  {props.tags.map((tag) => (
                    <Tag
                      key={tag.id}
                      title={tag.attributes.Name}
                      theme={tag.attributes.Color}
                    />
                  ))}
                </div>
              )}
              <h1 className="job-hero__title">{props.title}</h1>
            </div>
          )}
        </div>
      </section>
    );
  } else {
    return <div ref={ref}>Data not returned</div>;
  }
};

export default JobHero;
