import useFetch from "../../hooks/useFetch";
import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import InfoPanel from "../info-panel/info-panel.component";

import "./info-panel-group.styles.scss";

const InfoPanelGroup = (props) => {
  const ref = useRef();
  gsap.registerPlugin(ScrollTrigger);
  const url = `${props.apiUrl}?populate[ProductFeatures][populate][0]=CoverImage`;
  const { loading, error, data } = useFetch(url);

  useLayoutEffect(() => {
    let ctx = null;
    if (data !== null) {
      ctx = gsap.context(() => {
        const panels = gsap.utils.toArray(".info-panel");

        panels.forEach((panel) => {
          const isEven = panel.classList.contains("info-panel--even");

          ScrollTrigger.create({
            trigger: panel,
            start: "top 80%",
            onEnter: function () {
              gsap.fromTo(
                panel,
                { x: () => (isEven ? 200 : -200), autoAlpha: 0 },
                {
                  duration: 0.7,
                  x: 0,
                  autoAlpha: 1,
                  ease: "none",
                  overwrite: "auto",
                }
              );
            },
            onLeaveBack: function () {
              gsap.fromTo(
                panel,
                { x: 0, autoAlpha: 1 },
                {
                  duration: 0.7,
                  x: () => (isEven ? 200 : -200),
                  autoAlpha: 0,
                  ease: "none",
                  overwrite: "auto",
                }
              );
            },
          });
        });
      }, ref);
    }
    return () => (ctx !== null ? ctx.revert() : null);
  }, [data]);

  if (loading) return <p>Loading ... </p>;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data.data.attributes) {
    const prodFeatures = data.data.attributes.ProductFeatures;

    return (
      <section ref={ref} className="info-panel-group">
        <div className="info-panel-group__container">
          {prodFeatures.map((feature, index) => (
            <InfoPanel
              key={feature.id}
              index={index}
              title={feature.Title}
              desc={feature.Description}
              coverImg={feature.CoverImage}
              theme="alternate"
            />
          ))}
        </div>
      </section>
    );
  } else {
    return <div ref={ref}>Data not returned</div>;
  }
};

export default InfoPanelGroup;
