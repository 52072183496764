// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.partner__link {
  display: block;
}
.partner__img {
  display: block;
  max-height: 78px;
  max-width: calc(50vw - 30px);
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .partner__img {
    max-height: 125px;
  }
}
@media screen and (min-width: 1024px) {
  .partner__img {
    max-width: 16vw;
    transition: transform 0.2s linear;
  }
  .partner__img:hover, .partner__img:focus {
    transform: scale(1.1);
  }
}
@media screen and (min-width: 1440px) {
  .partner__img {
    max-width: 20vw;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/partner/partner.styles.scss","webpack://./src/styles/tools/media-queries/_above.scss"],"names":[],"mappings":"AAIE;EACE,cAAA;AAHJ;AAME;EACE,cAAA;EACA,gBAAA;EACA,4BAAA;EACA,iBAAA;EACA,kBAAA;AAJJ;ACJE;EDGA;IAQI,iBAAA;EAHJ;AACF;ACTE;EDGA;IAYI,eAAA;IACA,iCAAA;EAFJ;EAII;IAEE,qBAAA;EAHN;AACF;AClBE;EDGA;IAsBI,eAAA;EAHJ;AACF","sourcesContent":["@import \"../../styles/tools/media-queries/above\";\n@import \"../../styles/core/scss-variables\";\n\n.partner {\n  &__link {\n    display: block;\n  }\n\n  &__img {\n    display: block;\n    max-height: 78px;\n    max-width: calc(50vw - 30px);\n    margin-left: auto;\n    margin-right: auto;\n\n    @include above(tablet) {\n      max-height: 125px;\n    }\n\n    @include above(laptop) {\n      max-width: 16vw;\n      transition: transform 0.2s linear;\n\n      &:hover,\n      &:focus {\n        transform: scale(1.1);\n      }\n    }\n\n    @include above(desktop) {\n      max-width: 20vw;\n    }\n  }\n}\n","@import \"../functions/breakpoint-value\";\n\n@mixin above($key) {\n  $min-width: breakpoint-value($key);\n\n  @media screen and (min-width: #{$min-width}) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
