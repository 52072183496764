import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import checkImagesLoaded from "../../utils/checkImagesLoaded";

// Import Swiper React components and required modules
import { Keyboard, Pagination, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/scss";
import "swiper/scss/keyboard";
import "swiper/scss/pagination";
import "swiper/scss/scrollbar";

import "./carousel.styles.scss";

const Carousel = (props) => {
  const ref = useRef();
  gsap.registerPlugin(ScrollTrigger);
  const modules = [Keyboard];

  useLayoutEffect(() => {
    let ctx = null;
    if (props.useAnimation) {
      ctx = gsap.context(() => {
        ScrollTrigger.create({
          trigger: ".swiper-wrapper",
          start: "top 80%",
          onEnter: function () {
            gsap.fromTo(
              ".swiper-slide",
              { x: 200, autoAlpha: 0 },
              {
                duration: 0.7,
                x: 0,
                autoAlpha: 1,
                stagger: 0.2,
                ease: "sine.inOut",
                overwrite: "auto",
              }
            );
          },
          onLeaveBack: function () {
            gsap.fromTo(
              ".swiper-slide",
              { autoAlpha: 1 },
              { autoAlpha: 0, overwrite: "auto" }
            );
          },
        });

        if (props.checkImages) {
          const areImagesLoaded = checkImagesLoaded();
          areImagesLoaded.finally(() => {
            ScrollTrigger.refresh();
          });
        }
      }, ref);
    }
    return () => (ctx !== null ? ctx.revert() : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.useAnimation]);

  let swiperProps = {
    modules: modules,
    spaceBetween: props.spaceBetween !== undefined ? props.spaceBetween : 11,
    slidesPerView: props.slidesPerView !== undefined ? props.slidesPerView : 2,
    keyboard: { enabled: true },
    className: props.alignTop ? "swiper--align-top" : "",
  };

  if (props.useScrollBar) {
    modules.push(Scrollbar);
    swiperProps["scrollbar"] = { hide: false, draggable: true };
  } else {
    modules.push(Pagination);
    swiperProps["pagination"] = { clickable: true };
  }

  return (
    <section
      ref={ref}
      className={`carousel ${
        props.theme === "dark" ? "carousel--dark" : "carousel--light"
      } ${props.useAnimation ? "carousel--using-animation" : ""}`}
    >
      <Swiper {...swiperProps}>
        {props.slides.map((slide) => (
          <SwiperSlide key={slide.key}>{slide}</SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Carousel;
