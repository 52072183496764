import TitleWithCTA from "../../components/title-with-cta/title-with-cta.component";
import SiteHeader from "../../components/site-header/site-header.component";

import "./no-match.styles.scss";

const NoMatch = () => {
  return (
    <section className="no-match">
      <SiteHeader theme="dark" />
      <TitleWithCTA
        isHero={true}
        title="Whoops!"
        desc="We're not sure how you got here... But you may be lost in cyberspace."
        link="/"
        linkText="Take me home"
      />
    </section>
  );
};

export default NoMatch;
