const breakpoints = {
  mobile: 320,
  tablet: 768,
  laptop: 1024,
  desktop: 1440,
  maxwidth: 1600
};

export const Breakpoints = (size) => {
   return breakpoints[size];
};
