import useFetch from "../../hooks/useFetch";
import MediaQuery from "react-responsive";
import { Breakpoints } from "../../utils/breakpoints";
import AxisParallax from "../axis-parallax/axis-parallax.component";
import Product from "../product/product.component";
import TitleAccentLine from "../title-accent-line/title-accent-line.component";

import { ReactComponent as Watermark } from "../../img/background-decor/explore-products/x-watermark-laptop.svg";
import { ReactComponent as WatermarkDesktop } from "../../img/background-decor/explore-products/x-watermark-desktop.svg";
import { ReactComponent as ArrowWatermark } from "../../img/background-decor/explore-products/arrow-watermark-laptop.svg";
import { ReactComponent as ArrowWatermarkDesktop } from "../../img/background-decor/explore-products/arrow-watermark-desktop.svg";

import "./explore-products.styles.scss";

const ExploreProducts = (props) => {
  const theme = props.theme === undefined ? "dark" : "light";
  const useDarkWatermark = theme === "dark";
  const url = `${props.apiUrl}?populate[Products][populate][0]=products.${
    theme === "light" ? "LogoDark" : "LogoLight"
  }`;
  const { loading, error, data } = useFetch(url);

  if (loading) return <p>Loading ... </p>;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }
  if (data.data.attributes) {
    const products = data.data.attributes.Products.products.data;

    return (
      <section className={`explore-products explore-products--${theme}`}>
        {useDarkWatermark && (
          <>
            <MediaQuery
              minWidth={Breakpoints("tablet")}
              maxWidth={Breakpoints("desktop") - 1}
            >
              <Watermark className="explore-products__watermark" />
            </MediaQuery>
            <MediaQuery minWidth={Breakpoints("desktop")}>
              <WatermarkDesktop className="explore-products__watermark" />
            </MediaQuery>
          </>
        )}
        {!useDarkWatermark && (
          <>
            <MediaQuery
              minWidth={Breakpoints("laptop")}
              maxWidth={Breakpoints("desktop") - 1}
            >
              <ArrowWatermark className="explore-products__arrow explore-products__arrow--left" />
              <ArrowWatermark className="explore-products__arrow explore-products__arrow--right" />
            </MediaQuery>
            <MediaQuery minWidth={Breakpoints("desktop")}>
              <ArrowWatermarkDesktop className="explore-products__arrow explore-products__arrow--left" />
              <ArrowWatermarkDesktop className="explore-products__arrow explore-products__arrow--right" />
            </MediaQuery>
          </>
        )}
        <AxisParallax
          onlyDesktop={true}
          topDesktop="0px"
          rightDesktop="25px"
          isNotHero={true}
        />
        <div className="explore-products__container">
          {theme === "dark" && (
            <TitleAccentLine title={props.title} theme="light" />
          )}
          {theme === "light" && (
            <TitleAccentLine title={props.title} theme="dark" hideLine={true} />
          )}
          <div className="explore-products__products">
            {products.map((product) => (
              <Product
                key={product.id}
                desc={product.attributes.Description}
                logo={
                  theme === "light"
                    ? product.attributes.LogoDark
                    : product.attributes.LogoLight
                }
                linkText={product.attributes.LinkText}
                link={product.attributes.LinkDestination}
                additionalInfo={
                  props.useAdditionalInfo
                    ? product.attributes.AdditionalProductInfo
                    : undefined
                }
                theme={theme === "light" ? "dark" : "light"}
              />
            ))}
          </div>
        </div>
      </section>
    );
  } else {
    return <div>Data not returned</div>;
  }
};

export default ExploreProducts;
