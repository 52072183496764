// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.diamond-image {
  margin-top: 64px;
  margin-bottom: 64px;
}
@media screen and (min-width: 768px) {
  .diamond-image {
    margin-top: 11px;
    margin-bottom: 48px;
  }
}
@media screen and (min-width: 1024px) {
  .diamond-image {
    margin-top: 59px;
  }
}
@media screen and (min-width: 1440px) {
  .diamond-image {
    margin-top: 20px;
  }
}
.diamond-image__img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.diamond-image__img {
  width: 280px;
  height: 280px;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  background-size: cover;
  background-position: center center;
}
@media screen and (min-width: 1024px) {
  .diamond-image__img {
    width: 360px;
    height: 360px;
  }
}
@media screen and (min-width: 1024px) {
  .diamond-image__img {
    width: 414px;
    height: 414px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/diamond-image/diamond-image.styles.scss","webpack://./src/styles/tools/media-queries/_above.scss"],"names":[],"mappings":"AAGA;EACE,gBAAA;EACA,mBAAA;AAFF;ACEE;EDFF;IAKI,gBAAA;IACA,mBAAA;EADF;AACF;ACJE;EDFF;IAUI,gBAAA;EAAF;AACF;ACTE;EDFF;IAcI,gBAAA;EACF;AACF;AACE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AAEE;EACE,YAAA;EACA,aAAA;EACA,sDAAA;EACA,sBAAA;EACA,kCAAA;AAAJ;AC1BE;EDqBA;IAQI,YAAA;IACA,aAAA;EACJ;AACF;AChCE;EDqBA;IAaI,YAAA;IACA,aAAA;EAEJ;AACF","sourcesContent":["@import \"../../styles/tools/media-queries/above\";\n@import \"../../styles/core/scss-variables\";\n\n.diamond-image {\n  margin-top: 64px;\n  margin-bottom: 64px;\n\n  @include above(tablet) {\n    margin-top: 11px;\n    margin-bottom: 48px;\n  }\n\n  @include above(laptop) {\n    margin-top: 59px;\n  }\n\n  @include above(desktop) {\n    margin-top: 20px;\n  }\n\n  &__img-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  &__img {\n    width: 280px;\n    height: 280px;\n    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);\n    background-size: cover;\n    background-position: center center;\n\n    @include above(laptop) {\n      width: 360px;\n      height: 360px;\n    }\n\n    @include above(laptop) {\n      width: 414px;\n      height: 414px;\n    }\n  }\n}\n","@import \"../functions/breakpoint-value\";\n\n@mixin above($key) {\n  $min-width: breakpoint-value($key);\n\n  @media screen and (min-width: #{$min-width}) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
