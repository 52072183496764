import { useLayoutEffect, useRef } from "react";
import { Link } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import MediaQuery from "react-responsive";
import { Breakpoints } from "../../utils/breakpoints";
import Feature from "../feature/feature.component";

import { ReactComponent as DownArrow } from "../../img/background-decor/features-list/down-arrow-partial.svg";
import { ReactComponent as UpArrow } from "../../img/background-decor/up-arrow-partial.svg";
import { ReactComponent as WatermarkTablet } from "../../img/background-decor/features-list/watermark-tablet.svg";
import { ReactComponent as WatermarkLaptop } from "../../img/background-decor/features-list/watermark-laptop.svg";
import { ReactComponent as WatermarkDesktop } from "../../img/background-decor/features-list/watermark-desktop.svg";

import "./features-list.styles.scss";

const FeaturesList = (props) => {
  const ref = useRef();
  gsap.registerPlugin(ScrollTrigger);
  const url = `${props.apiUrl}?populate[FeatureList][populate][0]=OptionalButton.*&populate[FeatureList][populate][1]=features.Image`;
  const { loading, error, data } = useFetch(url);

  useLayoutEffect(() => {
    let ctx = null;
    if (data !== null) {
      ctx = gsap.context(() => {
        ScrollTrigger.create({
          trigger: ".features-list__list",
          start: "top 80%",
          onEnter: function () {
            gsap.fromTo(
              ".feature",
              { scale: 0, autoAlpha: 0 },
              {
                duration: 0.7,
                scale: 1,
                autoAlpha: 1,
                stagger: {
                  each: 0.1,
                  from: "center",
                },
                ease: "sine.inOut",
                overwrite: "auto",
              }
            );
          },
          onLeaveBack: function () {
            gsap.fromTo(
              ".feature",
              { autoAlpha: 1 },
              { autoAlpha: 0, overwrite: "auto" }
            );
          },
        });
      }, ref);
    }
    return () => (ctx !== null ? ctx.revert() : null);
  }, [data]);

  if (loading) return <p>Loading ... </p>;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data.data.attributes) {
    const featureData = data.data.attributes.FeatureList;
    const featureList = featureData.features.data;
    const useAltGrid = featureList.length > 4;
    const hasIntro =
      featureData.Title || featureData.IntroText || featureData.OptionalButton;

    return (
      <section ref={ref} className="features-list">
        <div className="features-list__background">
          <MediaQuery maxWidth={Breakpoints("tablet") - 1}>
            <DownArrow className="features-list__down-arrow" />
            <UpArrow className="features-list__up-arrow" />
          </MediaQuery>
          <MediaQuery
            minWidth={Breakpoints("tablet")}
            maxWidth={Breakpoints("laptop") - 1}
          >
            <WatermarkTablet className="features-list__watermark" />
          </MediaQuery>
          <MediaQuery
            minWidth={Breakpoints("laptop")}
            maxWidth={Breakpoints("desktop") - 1}
          >
            <WatermarkLaptop className="features-list__watermark" />
          </MediaQuery>
          <MediaQuery minWidth={Breakpoints("desktop")}>
            <WatermarkDesktop className="features-list__watermark" />
          </MediaQuery>
          <div
            className={`features-list__container ${
              hasIntro ? "features-list__container--max-width" : ""
            }`}
          >
            {hasIntro && (
              <div className="features-list__content">
                {featureData.Title && (
                  <h2 className="features-list__title">{featureData.Title}</h2>
                )}
                {featureData.IntroText && (
                  <p className="features-list__desc">{featureData.IntroText}</p>
                )}
                {featureData.OptionalButton && (
                  <Link
                    className="features-list__link button"
                    to={featureData.OptionalButton.ButtonDestination}
                  >
                    {featureData.OptionalButton.ButtonText}
                  </Link>
                )}
              </div>
            )}
            <div
              className={`features-list__list ${
                !hasIntro ? "features-list__list--no-spacing" : ""
              } ${useAltGrid ? "features-list__list--alt-grid" : ""}`}
            >
              {featureList.map((feature) => (
                <Feature
                  key={feature.id}
                  title={feature.attributes.Name}
                  desc={feature.attributes.Description}
                  image={feature.attributes.Image}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return <div ref={ref}>Data not returned</div>;
  }
};

export default FeaturesList;
