import useFetch from "../../hooks/useFetch";
import MediaQuery from "react-responsive";
import { Breakpoints } from "../../utils/breakpoints";
import Carousel from "../carousel/carousel.component";
import TeamProfile from "../team-profile/team-profile.component";
import TopographyBackground from "../topography-background/topography-background.component";
import TitleAccentLine from "../title-accent-line/title-accent-line.component";

import "./leadership-team.styles.scss";

const slidesPerScreenDefault = {
  desktop: 5.75,
  laptop: 4.5,
  tablet: 3.5,
  mobile: 1.75,
};

const LeadershipTeam = (props) => {
  const url = `${props.apiUrl}?populate[LeadershipTeam][populate][0]=team_members.Profile&populate[LeadershipTeam][populate][1]=slidesPerScreen`;
  const { loading, error, data } = useFetch(url);

  if (loading) return <p>Loading ... </p>;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (data.data.attributes) {
    const team = data.data.attributes.LeadershipTeam.team_members.data;
    const teamMembers = team.map((member) => (
      <TeamProfile
        key={member.id}
        name={member.attributes.Name}
        text={member.attributes.Text}
        link={member.attributes.LinkedIn}
        profile={member.attributes.Profile}
      />
    ));

    const { desktop, mobile, tablet, laptop } = data.data.attributes
      .LeadershipTeam.slidesPerScreen
      ? data.data.attributes.LeadershipTeam.slidesPerScreen
      : slidesPerScreenDefault;

    return (
      <section className="leadership-team">
        <div className="leadership-team__background">
          <TopographyBackground />
          <div className="leadership-team__container">
            <TitleAccentLine title={props.title} theme="light" />
            <div className="leadership-team__member-list">
              <MediaQuery maxWidth={Breakpoints("tablet") - 1}>
                <Carousel
                  slides={teamMembers}
                  useScrollBar={true}
                  slidesPerView={mobile}
                  spaceBetween={16}
                  theme="light"
                  alignTop={true}
                  useAnimation={true}
                />
              </MediaQuery>
              <MediaQuery
                minWidth={Breakpoints("tablet")}
                maxWidth={Breakpoints("laptop") - 1}
              >
                <Carousel
                  slides={teamMembers}
                  useScrollBar={true}
                  slidesPerView={tablet}
                  spaceBetween={16}
                  theme="light"
                  alignTop={true}
                  useAnimation={true}
                />
              </MediaQuery>
              <MediaQuery
                minWidth={Breakpoints("laptop")}
                maxWidth={Breakpoints("desktop") - 1}
              >
                <Carousel
                  slides={teamMembers}
                  useScrollBar={true}
                  slidesPerView={laptop}
                  spaceBetween={24}
                  theme="light"
                  alignTop={true}
                  useAnimation={true}
                />
              </MediaQuery>
              <MediaQuery minWidth={Breakpoints("desktop")}>
                <Carousel
                  slides={teamMembers}
                  useScrollBar={true}
                  slidesPerView={desktop}
                  spaceBetween={32}
                  theme="light"
                  alignTop={true}
                  useAnimation={true}
                />
              </MediaQuery>
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return <div>Data not returned</div>;
  }
};

export default LeadershipTeam;
