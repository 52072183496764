import MediaQuery from "react-responsive";
import { Breakpoints } from "../../utils/breakpoints";

import { ReactComponent as DownArrowVector } from "../../img/background-decor/down-arrow-vector.svg";
import { ReactComponent as CrossVectorDiamond } from "../../img/background-decor/cross-vector-diamond.svg";
import { ReactComponent as WatermarkTablet } from "../../img/background-decor/culture-title/watermark.svg";
import { ReactComponent as WatermarkDesktop } from "../../img/background-decor/culture-title/watermark-desktop.svg";

import "./culture-title.styles.scss";

const CultureTitle = (props) => {
  return (
    <section className="culture-title">
      <div className="culture-title__background">
        <div className="culture-title__container">
          <h2 className="culture-title__title">{props.title}</h2>
        </div>
        <div className="culture-title__arrow">
          <DownArrowVector className="culture-title__down-vector" />
        </div>
        <MediaQuery maxWidth={Breakpoints("tablet") - 1}>
          <CrossVectorDiamond className="culture-title__diamond culture-title__diamond--left" />
          <CrossVectorDiamond className="culture-title__diamond culture-title__diamond--right" />
        </MediaQuery>
        <MediaQuery
          minWidth={Breakpoints("tablet")}
          maxWidth={Breakpoints("desktop") - 1}
        >
          <WatermarkTablet className="culture-title__diamond culture-title__diamond--left" />
          <WatermarkTablet className="culture-title__diamond culture-title__diamond--right" />
        </MediaQuery>
        <MediaQuery minWidth={Breakpoints("desktop")}>
          <WatermarkDesktop className="culture-title__diamond culture-title__diamond--left" />
          <WatermarkDesktop className="culture-title__diamond culture-title__diamond--right" />
        </MediaQuery>
      </div>
    </section>
  );
};

export default CultureTitle;
