import { useState, useEffect } from 'react'

const useIntersection = (elementRef, rootMargin, threshold = 0, runOnce = false) => {
  const [isVisible, setIsVible] = useState(false);

  useEffect(() => {
    const element = elementRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (runOnce && entry.isIntersecting) {
          setIsVible(entry.isIntersecting);
          observer.unobserve(element);
        } else {
          setIsVible(entry.isIntersecting);
        }
      }, { rootMargin, threshold }
    );

    element && observer.observe(element);

    return () => observer.unobserve(element);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isVisible;
};

export default useIntersection

// Example usage:
// Trigger as soon as the element becomes visible
  // const inViewport = useIntersection(ref, '0px');
// Trigger if 200px is visible from the element 
  // const inViewport = useIntersection(ref, '-200px'); 
