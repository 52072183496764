import { useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { CSSTransition } from "react-transition-group";
import { Breakpoints } from "../../utils/breakpoints";
import ChevronParallax from "../chevron-parallax/chevron-parallax.component";
import DecodeTitle from "../decode-title/decode-title.component";
import FeaturedTitleDiamond from "../featured-title-diamond/featured-title-diamond.component";
import FeaturedTitleTriangle from "../featured-title-triangle/featured-title-triangle.component";

import { ReactComponent as CrossVector } from "../../img/background-decor/repeating-vector.svg";
import { ReactComponent as Polygon } from "../../img/background-decor/polygon.svg";

import "./featured-title.styles.scss";

const FeaturedTitle = (props) => {
  const nodeRef = useRef(null);
  const ref = useRef();
  const [displayParallax, setDisplayParallax] = useState(false);
  const isAltDesign = props.desc !== undefined;

  const isDesktop = useMediaQuery({ minWidth: Breakpoints("desktop") });
  const isBelowDesktop = useMediaQuery({
    maxWidth: Breakpoints("desktop") - 1,
  });
  const isAboveLaptop = useMediaQuery({
    minWidth: Breakpoints("laptop"),
  });
  const isBelowLaptop = useMediaQuery({
    maxWidth: Breakpoints("laptop") - 1,
  });
  const isLaptop = useMediaQuery({
    minWidth: Breakpoints("laptop"),
    maxWidth: Breakpoints("desktop") - 1,
  });
  const isTablet = useMediaQuery({
    minWidth: Breakpoints("tablet"),
    maxWidth: Breakpoints("laptop") - 1,
  });
  const isMobile = useMediaQuery({ maxWidth: Breakpoints("tablet") - 1 });

  const handleDisplayParallax = (isVisible) => {
    setDisplayParallax(isVisible);
  };

  return (
    <section
      ref={ref}
      className={`featured-title ${isAltDesign ? "featured-title--alt" : ""} ${
        props.isProductDesign ? "featured-title--product" : ""
      } ${props.useParallax ? "featured-title--using-parallax" : ""} ${
        displayParallax ? "featured-title--active-parallax" : ""
      }`}
    >
      <div className="featured-title__background">
        <div className="featured-title__container">
          <DecodeTitle
            title={props.title}
            isAltDesign={isAltDesign}
            isProductDesign={props.isProductDesign}
            useDecode={props.useDecodeEffect}
          />
          {props.desc && <p className="featured-title__desc">{props.desc}</p>}
        </div>
        {isBelowLaptop && (
          <div className="featured-title__arrow">
            <ChevronParallax
              isAltDesign={isAltDesign}
              useParallax={props.useParallax}
              handleDisplayParallax={handleDisplayParallax}
              apiUrl={props.apiUrl}
            />
          </div>
        )}
        {!props.useParallax && (
          <>
            {isAboveLaptop && !props.isProductDesign ? (
              <div className="featured-title__arrow">
                <ChevronParallax
                  isAltDesign={isAltDesign}
                  apiUrl={props.apiUrl}
                />
              </div>
            ) : (
              <>
                {isAboveLaptop && isBelowDesktop && (
                  <div className="featured-title__arrow">
                    <ChevronParallax
                      isAltDesign={isAltDesign}
                      apiUrl={props.apiUrl}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
        {isMobile && <CrossVector className="featured-title__cross-vector" />}
        {isTablet && (
          <FeaturedTitleTriangle
            isAltDesign={isAltDesign}
            isProductDesign={props.isProductDesign}
          />
        )}
        {isAltDesign ? (
          <>
            {isLaptop && (
              <CSSTransition
                in={
                  props.useParallax
                    ? displayParallax === false || window.scrollY === 0
                    : true
                }
                nodeRef={nodeRef}
                timeout={500}
                classNames="fade"
              >
                <FeaturedTitleDiamond
                  ref={nodeRef}
                  useParallax={props.useParallax}
                />
              </CSSTransition>
            )}
            {isDesktop && (
              <CSSTransition
                in={
                  props.useParallax
                    ? displayParallax === false || window.scrollY === 0
                    : true
                }
                nodeRef={nodeRef}
                timeout={500}
                classNames="fade"
              >
                <FeaturedTitleTriangle
                  ref={nodeRef}
                  isAltDesign={isAltDesign}
                  isProductDesign={props.isProductDesign}
                  useParallax={props.useParallax}
                />
              </CSSTransition>
            )}
          </>
        ) : (
          <>
            {isAboveLaptop && (
              <CSSTransition
                in={
                  props.useParallax
                    ? displayParallax === false || window.scrollY === 0
                    : true
                }
                nodeRef={nodeRef}
                timeout={500}
                classNames="fade"
              >
                <FeaturedTitleTriangle
                  ref={nodeRef}
                  isAltDesign={isAltDesign}
                  isProductDesign={props.isProductDesign}
                  useParallax={props.useParallax}
                />
              </CSSTransition>
            )}
          </>
        )}
      </div>
      {props.useParallax && (
        <>
          {isAboveLaptop && !props.isProductDesign ? (
            <div className="featured-title__arrow">
              <ChevronParallax
                isAltDesign={isAltDesign}
                useParallax={props.useParallax}
                handleDisplayParallax={handleDisplayParallax}
                apiUrl={props.apiUrl}
              />
            </div>
          ) : (
            <>
              {isAboveLaptop && isBelowDesktop && (
                <div className="featured-title__arrow">
                  <ChevronParallax
                    isAltDesign={isAltDesign}
                    useParallax={props.useParallax}
                    handleDisplayParallax={handleDisplayParallax}
                    apiUrl={props.apiUrl}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
      {isTablet && <Polygon className="featured-title__polygon" />}
      {!isAltDesign && !props.useParallax && isAboveLaptop && (
        <Polygon className="featured-title__polygon" />
      )}
    </section>
  );
};

export default FeaturedTitle;
