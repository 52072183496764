import useScreenSize from "../../hooks/useScreenSize";
import NavBar from "../nav-bar/nav-bar.component";

import "./site-header.styles.scss";

const SiteHeader = (props) => {
  const theme = props.theme !== undefined ? props.theme : "light";

  const screenSize = useScreenSize();
  const useDesktopNav = screenSize === "laptop" || screenSize === "desktop";

  return (
    <header className="site-header">
      <NavBar theme={theme} useDesktopNav={useDesktopNav} />
    </header>
  );
};

export default SiteHeader;
