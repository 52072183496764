import useFetch from "../../hooks/useFetch";
import { useEffect, useRef, useState } from "react";
import { isAbsoluteUrl } from "../../utils/isAbsoluteUrl";
import useIntersection from "../../hooks/useIntersection";
import useScreenSize from "../../hooks/useScreenSize";

import { ReactComponent as Vector } from "../../img/background-decor/down-arrow-vector.svg";

import "./chevron-parallax.styles.scss";

const ChevronParallax = (props) => {
  const ref = useRef();
  const requestRef = useRef();
  const screenSize = useScreenSize();
  const display = screenSize === "laptop" || screenSize === "desktop";
  const isVisible = useIntersection(
    ref,
    screenSize === "laptop" ? "0px 0px -180px 0px" : "0px 0px -250px 0px"
  );
  const [shouldCentralise, setShouldCentralise] = useState(false);
  const scrollEl = document.documentElement;
  const root = document.documentElement;
  let scrollPos;

  let componentContent = <Vector className="chevron-parallax__vector" />;

  const animation = () => {
    // Only run if scroll position has changed
    if (scrollPos !== scrollEl.scrollTop) {
      scrollPos = scrollEl.scrollTop;
      root.style.setProperty("--scrollPosChevron", scrollPos + "px");

      // Set css variables based on scroll amount
      if (scrollPos >= 1125) {
        root.style.setProperty("--scrollPosModifier", 2.6);
      } else if (scrollPos >= 1070) {
        root.style.setProperty("--scrollPosModifier", 2.5);
      } else if (scrollPos >= 1015) {
        root.style.setProperty("--scrollPosModifier", 2.4);
      } else if (scrollPos >= 960) {
        root.style.setProperty("--scrollPosModifier", 2.3);
      } else if (scrollPos >= 905) {
        root.style.setProperty("--scrollPosModifier", 2.2);
      } else if (scrollPos >= 850) {
        root.style.setProperty("--scrollPosModifier", 2.1);
      } else if (scrollPos >= 795) {
        root.style.setProperty("--scrollPosModifier", 2);
      } else if (scrollPos >= 740) {
        root.style.setProperty("--scrollPosModifier", 1.9);
      } else if (scrollPos >= 685) {
        root.style.setProperty("--scrollPosModifier", 1.8);
      } else if (scrollPos >= 630) {
        root.style.setProperty("--scrollPosModifier", 1.7);
      } else if (scrollPos >= 575) {
        root.style.setProperty("--scrollPosModifier", 1.6);
      } else if (scrollPos >= 520) {
        root.style.setProperty("--scrollPosModifier", 1.5);
      } else if (scrollPos >= 465) {
        root.style.setProperty("--scrollPosModifier", 1.4);
      } else if (scrollPos >= 410) {
        root.style.setProperty("--scrollPosModifier", 1.3);
      } else if (scrollPos >= 355) {
        root.style.setProperty("--scrollPosModifier", 1.2);
      } else if (scrollPos < 300) {
        root.style.setProperty("--scrollPosModifier", 1.1);
      }

      const bounds = ref.current.getBoundingClientRect();

      setShouldCentralise(scrollPos > bounds.bottom);
    }

    // Call animation again
    requestRef.current = requestAnimationFrame(animation);
  };

  useEffect(() => {
    // Only run animation if above laptop size and in viewport
    if (display && isVisible) {
      requestRef.current = requestAnimationFrame(animation);
    }
    return () => cancelAnimationFrame(requestRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [display, isVisible]);

  useEffect(() => {
    if (props.useParallax) {
      props.handleDisplayParallax(isVisible);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.useParallax, isVisible]);

  const url =
    props.apiUrl && props.useParallax
      ? `${props.apiUrl}?populate[FeaturedTitle][populate][0]=OptionalParallaxContent&populate[FeaturedTitle][populate][1]=mask_image.MaskPng`
      : null;
  const { loading, error, data } = useFetch(url);

  if (url && loading) {
    componentContent = <p>Loading ... </p>;
  }
  if (url && error) {
    console.log(error);
    componentContent = (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }

  if (url && data !== null && data.data.attributes) {
    const parallaxData = data.data.attributes.FeaturedTitle;

    const img =
      parallaxData.OptionalParallaxContent.data !== null
        ? parallaxData.OptionalParallaxContent.data.attributes
        : null;
    const imgUrl = img
      ? isAbsoluteUrl(img.url)
        ? img.url
        : `${process.env.REACT_APP_API_BASE}${img.url}`
      : null;

    const mask =
      parallaxData.mask_image.data !== null
        ? parallaxData.mask_image.data.attributes.MaskPng.data.attributes
        : null;
    const maskUrl = mask
      ? isAbsoluteUrl(mask.url)
        ? mask.url
        : `${process.env.REACT_APP_API_BASE}${mask.url}`
      : null;

    componentContent =
      props.useParallax && imgUrl ? (
        <img
          className={`chevron-parallax__content ${
            isVisible ? "chevron-parallax__content--active" : ""
          } ${shouldCentralise ? "chevron-parallax__content--centralise" : ""}`}
          src={imgUrl}
          alt={img.alternativeText}
          style={{
            "--maskUrl": `url("${maskUrl}")`,
          }}
        />
      ) : (
        <Vector className="chevron-parallax__vector" />
      );
  }

  return (
    <section
      ref={ref}
      className={`chevron-parallax ${
        props.isAltDesign ? "chevron-parallax--alt" : ""
      } ${props.useParallax ? "chevron-parallax--using-parallax" : ""}`}
    >
      {componentContent}
    </section>
  );
};

export default ChevronParallax;
