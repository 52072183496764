import MediaQuery from "react-responsive";
import { Breakpoints } from "../../utils/breakpoints";
import FooterCta from "../footer-cta/footer-cta.component";
import FooterLegal from "../footer-legal/footer-legal.component";
import FooterMedia from "../footer-media/footer-media.component";
import FooterSitemap from "../footer-sitemap/footer-sitemap.component";

import { ReactComponent as Watermark } from "../../img/background-decor/footer/x-watermark-mobile.svg";
import { ReactComponent as WatermarkTablet } from "../../img/background-decor/footer/x-watermark-tablet.svg";
import { ReactComponent as WatermarkLaptop } from "../../img/background-decor/footer/x-watermark-laptop.svg";
import { ReactComponent as WatermarkDesktop } from "../../img/background-decor/footer/x-watermark-desktop.svg";

import "./site-footer.styles.scss";

const SiteFooter = () => {
  const url = `${process.env.REACT_APP_API_BASE}/api/footer`;

  return (
    <footer className="site-footer">
      <MediaQuery maxWidth={Breakpoints("tablet") - 1}>
        <Watermark className="site-footer__watermark" />
      </MediaQuery>
      <MediaQuery
        minWidth={Breakpoints("tablet")}
        maxWidth={Breakpoints("laptop") - 1}
      >
        <WatermarkTablet className="site-footer__watermark" />
      </MediaQuery>
      <MediaQuery
        minWidth={Breakpoints("laptop")}
        maxWidth={Breakpoints("desktop") - 1}
      >
        <WatermarkLaptop className="site-footer__watermark" />
      </MediaQuery>
      <MediaQuery minWidth={Breakpoints("desktop")}>
        <WatermarkDesktop className="site-footer__watermark" />
      </MediaQuery>
      <div className="site-footer__inner">
        <FooterSitemap apiUrl={url} />
        <FooterMedia apiUrl={url} />
        <FooterLegal apiUrl={url} />
      </div>
      <FooterCta apiUrl={url} />
    </footer>
  );
};

export default SiteFooter;
