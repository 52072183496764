import { Link } from "react-router-dom";
import { useLayoutEffect, useRef } from "react";
import useFetch from "../../hooks/useFetch";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { isAbsoluteUrl } from "../../utils/isAbsoluteUrl";
import useScreenSize from "../../hooks/useScreenSize";

import { ReactComponent as CrossVector } from "../../img/background-decor/repeating-vector.svg";

import "./contact-cta.styles.scss";

const ContactCTA = (props) => {
  const ref = useRef();
  gsap.registerPlugin(ScrollTrigger);
  const url = `${props.apiUrl}?populate[ContactCta][populate][0]=cover_image.CoverImage&populate[ContactCta][populate][1]=cover_image.CoverImageTablet&populate[ContactCta][populate][2]=cover_image.CoverImageLaptop&populate[ContactCta][populate][3]=cover_image.CoverImageDesktop`;

  const { loading, error, data } = useFetch(url);
  const screenSize = useScreenSize();

  const getRatioFrom = (el) => {
    return el.offsetHeight * 0.4;
  };

  const effectSpeedMultiplier = props.parrallaxSpeedMultiplier
    ? props.parrallaxSpeedMultiplier
    : 1;

  useLayoutEffect(() => {
    let ctx = null;
    if (data !== null && props.useParallaxEffect) {
      ctx = gsap.context(() => {
        const bgImage = ref.current.querySelector(".contact-cta__coverImg");

        gsap.fromTo(
          bgImage,
          {
            backgroundPosition: () =>
              `50% -${getRatioFrom(bgImage) * effectSpeedMultiplier}px`,
          },
          {
            backgroundPosition: () => "50% -100px",
            ease: "none",
            scrollTrigger: {
              trigger: ref.current,
              start: () => "top bottom",
              end: "bottom top",
              scrub: true,
              invalidateOnRefresh: true,
            },
          }
        );
      }, ref);
    }
    return () => (ctx !== null ? ctx.revert() : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) return <p>Loading ... </p>;
  if (error) {
    console.log(error);
    return (
      <section className="error">
        <p>Error ⛔.</p>
      </section>
    );
  }
  if (data.data.attributes) {
    let coverImg;
    switch (screenSize) {
      case "mobile":
        coverImg =
          data.data.attributes.ContactCta.cover_image.data.attributes.CoverImage
            .data.attributes;
        break;
      case "tablet":
        coverImg =
          data.data.attributes.ContactCta.cover_image.data.attributes
            .CoverImageTablet.data.attributes;
        break;
      case "laptop":
        coverImg =
          data.data.attributes.ContactCta.cover_image.data.attributes
            .CoverImageLaptop.data.attributes;
        break;
      default:
        coverImg =
          data.data.attributes.ContactCta.cover_image.data.attributes
            .CoverImageDesktop.data.attributes;
        break;
    }
    const imgUrl = isAbsoluteUrl(coverImg.url)
      ? coverImg.url
      : `${process.env.REACT_APP_API_BASE}${coverImg.url}`;

    return (
      <section ref={ref} className="contact-cta">
        <div
          className="contact-cta__coverImg"
          style={{
            backgroundImage: `url(${imgUrl})`,
          }}
        ></div>
        <CrossVector className="contact-cta__cross-vector" />
        <div className="contact-cta__container">
          <h2
            className={`contact-cta__title ${
              props.largerText ? "contact-cta__title--large" : ""
            }`}
          >
            {props.title}
          </h2>
          <Link
            className="contact-cta__link button button--solid"
            to={props.link}
          >
            {props.linkText}
          </Link>
        </div>
      </section>
    );
  } else {
    return <div ref={ref}>Data not returned</div>;
  }
};

export default ContactCTA;
